/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { Link } from "react-router-dom";
import { updatePassword } from "../../../redux/doctorRedux/doctorApiCalls";
import Meta from "../../../Components/Meta";
import { Link } from "react-router-dom";
import Message from "../../../Components/Message";
import Loader from "../../../Components/Loader";
import { UpdatePasswordReset } from "../../../redux/doctorRedux/MyProfile_Management/updatePassword";
import {
  allMessagesStart,
  allMessagesSuccess,
  allMessagesFailure,
  allMessagesReset,
} from "../../../redux/allMessages";
import { useParams } from "react-router-dom";

const UpdatePasswordDoctorScreen = () => {
  const { clinicName } = useParams();
  const history = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [passwordConfirm, setpasswordConfirm] = useState("");
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [show1, setShow1] = useState(false);
  const handleClick1 = () => setShow1(!show1);
  // const userLogin = useSelector((state) => state.userRegister);
  const { loading, error, userInfo, success } = useSelector(
    (state) => state.updateDoctorPassword
  );
  const { doctorInfo } = useSelector((state) => state.doctorLogin);
  useEffect(() => {
    if (doctorInfo === undefined) {
      console.log("111");
      history(`/clinic/${clinicName}`);
    }
  }, [doctorInfo]);
  const specialCharsRegex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

  const validatePassword = (password) => {
    return "";
  };
  const submitHandler = (e) => {
    e.preventDefault();
    // history("/")
    // setPassword("")
    // setpasswordConfirm("");
    console.log("submitted");
    if (password.length < 8 || password.length > 15) {
      alert("Password must be between 8 and 15 characters.");
    } else if (!specialCharsRegex.test(password)) {
      alert("Password must contain at least one special character.");
    } else if (password != passwordConfirm) {
      alert("Passwords did not match");
    } else {
      alert("Password Changed successfully");
      dispatch(updatePassword(password, clinicName));
    }
  };
  /////////////////////////////////Alert handling////////////////////////////////////////////////////
  const [timerRunning, setTimerRunning] = useState(false);
  useEffect(() => {
    dispatch(allMessagesStart());
  }, []);
  const [showPopup, setShowPopup] = React.useState(false);

  const toggleShowInfoPopup = (e) => {
    setShowPopup(!showPopup);
    e.preventDefault();
    console.log("error");
    dispatch(UpdatePasswordReset());
  };
  return (
    <div className="pageSize">
      <Meta />

      {/* <main className="main">
				<div className="login-form">
					<h2 className="heading-secondary ma-bt-lg">Update Password</h2>
					<form className="form form--login">
						<div className="form__group ma-bt-md">
							<label className="form__label" htmlFor="password">
								Password
							</label>
							<input
								className="form__input"
								id="password"
								type="password"
								placeholder="••••••••"
								required="required"
								minLength={8}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
						<div className="form__group ma-bt-md">
							<label className="form__label" htmlFor="password">
								Password Confirm
							</label>
							<input
								className="form__input"
								id="password"
								type="password"
								placeholder="••••••••"
								required="required"
								minLength={8}
								onChange={(e) => setpasswordConfirm(e.target.value)}
							/>
						</div>
						<div className="form__group">
							<Link to="/login">
								<button
									onClick={submitHandler}
									type="submit"
									className="btn btn-success"
								>
									Update
								</button>
							</Link>
						</div>
					</form>
				</div>
			</main> */}
      {error && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {error}
        </Message>
      )}
      {loading && <Loader />}

      <div className="row row-content">
        <div className="col-12 col-md-4 offset-md-4 loginForm">
          <h2 className="loginHeader">Update Password</h2>
          <form className="form-group" novalidate id="user-form">
            <div className="form-group">
              <label for="username" className="col-md-12 col-form-label">
                <b>Password:</b>
              </label>
              <div className="col-md-12">
                <input
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="••••••••"
                  required="required"
                  minLength="8"
                  onChange={(e) => setPassword(e.target.value)}
                  type={show ? "text" : "password"}
                />
                <button type="button" className="close" onClick={handleClick}>
                  {show == 1 ? (
                    <i class="fa fa-eye pppp2" aria-hidden="true"></i>
                  ) : (
                    <i class="fa fa-eye-slash pppp2" aria-hidden="true"></i>
                  )}
                </button>
              </div>
            </div>
            <div className="form-group">
              <label for="password" className="col-md-12 col-form-label">
                <b>Confirm Password:</b>
              </label>
              <div className="col-md-12">
                <input
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="••••••••"
                  required="required"
                  minLength="8"
                  onChange={(e) => setpasswordConfirm(e.target.value)}
                  type={show1 ? "text" : "password"}
                />
                <button type="button" className="close" onClick={handleClick1}>
                  {show1 == 1 ? (
                    <i class="fa fa-eye pppp2" aria-hidden="true"></i>
                  ) : (
                    <i class="fa fa-eye-slash pppp2" aria-hidden="true"></i>
                  )}
                </button>
              </div>
            </div>
            <div className="form-group">
              <button
                onClick={submitHandler}
                type="submit"
                className="btn btn-success mt-5 offset-2 col-8"
              >
                <b>Update</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdatePasswordDoctorScreen;
