import { createSlice } from "@reduxjs/toolkit";

const getTemplateReducer = createSlice({
	name: "getTemplate",
	initialState: {},
	reducers: {
		getTemplateRequest: (state) => {
			state.loading = true;
		},
		getTemplateSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getTemplate = action.payload;
			state.error=false;
			state.success = true;
		},
		getTemplateFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getTemplateReset: (state) => {
			return { error:false,success:false };
		},
	},
});

export const {
	getTemplateRequest,
	getTemplateSuccess,
	getTemplateFailure,
	getTemplateReset,
} = getTemplateReducer.actions;
export default getTemplateReducer.reducer;
