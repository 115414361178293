/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Errortemplate from "../../Components/Errortemplate";
// import { Col, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import {
	updateUserProfileByAdmin, //
	getTimeslots, //
} from "../../redux/userApiCalls";
import { Col, Row, Container } from "react-bootstrap";
import {
	getUserDataByCategory, //
	adminCreateBooking, //
} from "../../redux/adminRedux/adminApiCalls";
import { createBooking } from "../../redux/userApiCalls";
import Message from "../../Components/Message";
// import {bookingListScreen} from "../redux/userApiCalls";
import { getUserDataByCategoryReset } from "../../redux/adminRedux/User_Management/getUserDataByCategory";
import { userUpdateProfileReset } from "../../redux/userUpdate";
import { adminCreateBookingReset } from "../../redux/adminRedux/Booking_Management/adminCreateBooking";
import { createBookingReset } from "../../redux/createBooking";
import { TimeSlotGetReset } from "../../redux/getTimeSlots";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";
import { useParams } from "react-router-dom";

const OnSpotBookingScreens = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	//success and failure messages
	const data1 = useSelector((state) => state.getUserdataByCategory);
	const userData = data1.userData;
	const { error, loading, success } = data1;
	const [dateOption, setDateOption] = useState("Choose Date");
	const [dayIdx, setdayIdx] = useState(-1);
	const adduserinfo = useSelector((state) => state.userUpdate);
	const adduserinfoerror = adduserinfo.error;
	const adduserinfosuccess = adduserinfo.success;
	const adduserinfoloading = adduserinfo.loading;
	const [dateOptionNumeric, setDateOptionNumeric] = useState("Choose Date");
	const bookingallinfo = useSelector((state) => state.createBooking);
	const bookingerror = bookingallinfo.error;
	const createdbookingloading = bookingallinfo.loading;
	const bookinguserInfo = bookingallinfo.userInfo;
	const bookingsuccess = bookingallinfo.success;
	const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
	const getTimeSlotssuccess = getTimeSlotsData.success;
	const getTimeSlotserror = getTimeSlotsData.error;
	const getTimeSlotsloading = getTimeSlotsData.loading;

	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const doctorAppointmentDates = doctorInfo?.user?.appointmentDates;

	const [bloodGroup, setBloodGroup] = useState("Select a blood group");
	const handleBloodGroup = (eventKey) => {
		setBloodGroup(eventKey);
	};
	const [lgShow, setLgShow] = useState(false);
	const [mno, setMno] = useState("");
	const handleSearchPatient = (e) => {
		e.preventDefault();
		setUserOption("Select a user");

		setDateOption("Choose Date");
		setDateSelected(false);
		setTextVis("d-none text-danger");
		dispatch(getUserDataByCategory(mno, clinicName));
	};

	const [click, setClick] = useState(0);
	console.log(userData);
	const blood_groups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-", "NA"];
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [dob, setdob] = useState(new Date());
	var days = [];

	// Extracting and formatting dates from the array
	const formattedDates = doctorAppointmentDates.map((entry) => {
		const dateString = entry.date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = ("0" + dateString.slice(4, 6)).slice(-2);
		const day = ("0" + dateString.slice(6, 8)).slice(-2);

		// Create the formatted date string

		days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
	});
	console.log(days);
	console.log(formattedDates);

	const addUserOnSubmit = (e) => {
		e.preventDefault();
		console.log(name + " " + gender + " " + dob + " " + mno);
		var letters = /^[A-Za-z\s]+$/;
		if (name === "") {
			alert("Username cannot be empty");
		} else if (!name.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (gender === "") {
			alert("Please select gender, gender cannot be empty");
		} else if (dob === "") {
			alert("Please select dob, dob cannot be empty");
		} else if (new Date(dob) >= new Date(new Date())) {
			alert("Please select proper dob, dob should not exceed todays date");
		} else {
			if (bloodGroup == "Select a blood group") {
				dispatch(
					updateUserProfileByAdmin(
						name.trim(),
						gender,
						dob,
						mno,
						"NA",
						clinicName
					)
				);
			} else {
				dispatch(
					updateUserProfileByAdmin(
						name.trim(),
						gender,
						dob,
						mno,
						bloodGroup,
						clinicName
					)
				);
			}
			setName("");
			setGender("");
			setdob("");
			setClick(1);
			setBloodGroup("Select a blood group");
		}
	};
	const users_array = [];
	users_array.push({
		name: userData?.data?.data?.name,
		id: userData?.data?.data?.bookingId,
	});
	for (
		let i = 0;
		i < userData?.data?.data?.registered_patients_array?.length;
		i++
	) {
		users_array.push({
			name: userData?.data?.data?.registered_patients_array[i]?.name,
			id: userData?.data?.data?.registered_patients_array[i]?.bookingId,
		});
	}
	console.log(users_array);
	const [userOption, setUserOption] = useState("Select a user");

	let flag = false;
	const [textVis, setTextVis] = useState("d-none text-danger");
	const handleUserSelect = (eventKey) => {
		debugger;
		setTextVis("d-none text-danger");
		setUserOption(eventKey);
		flag = false;
		for (let i = 0; i < users_array.length; i++) {
			if (eventKey === users_array[i].name) {
				debugger;
				flag = true;
				break;
			}
		}
		if (flag) {
			setTextVis("d-block text-danger");
		}

		setDateOption("Choose Date");
		setDateSelected(false);
		setSlotOption();
		setSlotCount(0);
	};

	// const currDate = new Date(); // Get current date
	// const date = currDate.getDate();
	// const month = currDate.getMonth() + 1; // Month index starts from 0
	// const year = currDate.getFullYear();
	// const dateOption = date + " / " + month + " / " + year;

	const [slotOption, setSlotOption] = useState();
	const [slotCount, setSlotCount] = useState(0);
	const handleSlotSelect = (slot, slotcount) => {
		setSlotOption(slot);
		setSlotCount(slotcount + 1);
	};

	const submitHandler = async () => {
		if (userOption === "Select a user") {
			alert("User needs to be selected first, Please select user");
		} else if (dateOption == "Choose Date") {
			alert("Date needs to be selected first, Please select Date");
		} else if (slotOption === undefined) {
			alert("Please select a vaild slot");
		} else {
			debugger;
			dispatch(
				createBooking(
					userOption,
					dateOptionNumeric,
					slotOption,
					slotCount,
					userData?.data?.data?.mobilenumber,
					doctorInfo?.user?.name,
					doctorInfo?.user?._id,
					doctorInfo?.user?.speciality,
					doctorInfo?.user?.opConsultationFee,
					userData?.data?.data?._id,
					dayIdx,
					clinicName
				)
			);

			setUserOption("Select a user");

			setSlotOption();
			setSlotCount(0);
			setDateOptionNumeric(0);
			setdayIdx(-1);
			setDateSelected(false);
			setDateOption("Choose Date");
		}
	};
	console.log(userOption, dateOption, slotOption, slotCount, mno);

	const [dateSelected, setDateSelected] = useState(false);

	const handleDateSelect = (eventKey) => {
		if (userOption == "Select a user") {
			alert("User needs to be selected first, Please select user");
		} else {
			const dateString = doctorAppointmentDates[eventKey].date.toString();

			// Extract year, month, and day
			const year = dateString.slice(0, 4);
			const month = ("0" + dateString.slice(4, 6)).slice(-2);
			const day = ("0" + dateString.slice(6, 8)).slice(-2);
			setdayIdx(eventKey);
			setSlotOption("");
			// Create the formatted date string
			setDateOptionNumeric(doctorAppointmentDates[eventKey].date);
			//days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
			setDateOption(`${day}-${month}-${year}`);
			setDateSelected(true);
			dispatch(getTimeslots(doctorInfo?.user?._id, eventKey, clinicName));
			//settimeSlots(doctorAppointmentDates[eventKey].timeSlots);
		}
	};

	useEffect(() => {
		//dispatch(getSpecialities());
		if (mno !== "") dispatch(getUserDataByCategory(mno, clinicName));
		// if(mno !== "") {
		//   dispatch(getUserDataByCategory(mno));
		// }
	}, [
		history,
		dispatch,
		click,
		adduserinfosuccess,
		adduserinfoerror,
		bookingsuccess,
		bookingerror,
	]);

	/////////////////////////////////Alert handling////////////////////////////////////////////////////
	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const [showPopup, setShowPopup] = React.useState(false);

	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log("error");
		dispatch(getUserDataByCategoryReset());
	};
	const [showPopup1, setShowPopup1] = React.useState(false);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log("error");
		dispatch(userUpdateProfileReset());
	};

	useEffect(() => {
		if (adduserinfosuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(userUpdateProfileReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [adduserinfosuccess, dispatch]);

	const [showPopup2, setShowPopup2] = React.useState(false);

	const toggleShowInfoPopup2 = (e) => {
		setShowPopup2(!showPopup2);
		e.preventDefault();
		console.log("error");
		dispatch(createBookingReset());
	};

	useEffect(() => {
		if (bookingsuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(createBookingReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [bookingsuccess, dispatch]);

	const [showPopup6, setShowPopup6] = React.useState(false);
	const toggleShowInfoPopup6 = (e) => {
		setShowPopup6(!showPopup6);
		e.preventDefault();
		console.log("error");
		dispatch(TimeSlotGetReset());
	};

	const ISTOffset = 330; // 5 hours and 30 minutes in minutes
	const todayInIST = new Date(new Date().getTime() + ISTOffset * 60000)
		.toISOString()
		.split("T")[0];
	return (
		<div className="body pageSize">
			<Meta />
			{error && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}

			{bookingerror && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup2}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{bookingerror}
				</Message>
			)}
			{createdbookingloading && <Loader />}
			{adduserinfoerror && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup1}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{adduserinfoerror}
				</Message>
			)}
			{adduserinfoloading && <Loader />}
			{getTimeSlotserror && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup6}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{getTimeSlotserror}
				</Message>
			)}
			{getTimeSlotsloading && <Loader />}
			<h1 className="offset-md-5 offset-2">Book Appointment</h1>
			<form className="form-group" onSubmit={handleSearchPatient}>
				<Container className="header-center mt-5">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center mt-1">
							<h6>
								Enter Patient's Mobile Number{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<input
								className="form-control"
								type="text"
								placeholder="9999999999"
								id="mobilenumber"
								required="required"
								onChange={(e) => setMno(e.target.value)}
								minlength="10"
								maxlength="10"
								pattern="^[0-9]*$"
							/>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<Button type="submit" id="search" className="btn btn-primary">
								Search
							</Button>
						</Col>
					</Row>
				</Container>
			</form>
			<Container className="header-center mt-5 mb-5 card col-md-9">
				<Row className="mt-4 mb-4">
					<Col md="auto" className="text-center mt-1"></Col>
					<Col md="auto" className="text-center mt-1">
						<h6>
							Select a user{" "}
							<span className="text-danger">
								<b>*</b>
							</span>
						</h6>
					</Col>
					<Col md="auto" className="text-center mt-1">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={userOption}
							onSelect={handleUserSelect}
						>
							{users_array.map((users, index) => (
								<Dropdown.Item eventKey={users.name}>
									{users.name}
								</Dropdown.Item>
							))}
						</DropdownButton>
					</Col>
					<Col md="auto" className="text-center mt-1">
						{users_array.length >= 6 ? (
							<Button disabled className="btn btn-danger">
								Users limit reached
							</Button>
						) : (
							<Button
								className="btn btn-secondary"
								onClick={() => setLgShow(true)}
							>
								Add User
							</Button>
						)}
					</Col>
				</Row>
				<Modal
					size="lg"
					show={lgShow}
					onHide={() => setLgShow(false)}
					aria-labelledby="example-modal-sizes-title-lg"
				>
					<Modal.Header closeButton>
						<Modal.Title id="example-modal-sizes-title-lg">
							Add New User
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<form className="form-group" onSubmit={addUserOnSubmit}>
							<div className="row mt-3">
								<label className="col-3 col-md-3" htmlFor="name">
									<b>Name </b>{" "}
									<span className="text-danger">
										<b>*</b>
									</span>
								</label>
								<input
									className="form-control col-4"
									id="name"
									type="name"
									required="required"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div className="row mt-3">
								<label htmlFor="gender" className="col-4 col-md-3">
									<b>Gender </b>{" "}
									<span className="text-danger">
										<b>*</b>
									</span>
								</label>
								<div className="row">
									<b>
										<Form.Check
											onChange={() => {
												setGender("Male");
											}}
											checked={gender === "Male"}
											className="form-check col-5 col-md-4"
											inline
											label="Male"
											type="radio"
											id={`inline-radio-1`}
										/>
										<Form.Check
											onChange={() => {
												setGender("Female");
											}}
											checked={gender === "Female"}
											inline
											label="Female"
											className="form-check col-5 col-md-4"
											type="radio"
											id={`inline-radio-2`}
										/>
									</b>
								</div>
							</div>
							<div className="row mt-3">
								<label htmlFor="date" className="col-md-3">
									<b>Date of Birth </b>{" "}
									<span className="text-danger">
										<b>*</b>
									</span>
								</label>
								<input
									type="date"
									className="col-md-4 form-control"
									selected={dob}
									value={dob}
									onChange={(e) => setdob(e.target.value)}
									max={todayInIST}
								/>
							</div>
							<div className="row mt-3">
								<label for="bloodgroup" className="col-md-3">
									<b>Blood Group:</b>
								</label>
								<DropdownButton
									className="col-md-1 btn"
									key="down-centered"
									id={`dropdown-button-drop-down-centered`}
									drop="down-centered"
									variant="success"
									title={bloodGroup}
									onSelect={handleBloodGroup}
								>
									{blood_groups.map((blood) => (
										<Dropdown.Item eventKey={blood}>{blood}</Dropdown.Item>
									))}
								</DropdownButton>
							</div>
							<div>
								<Button
									className="offset-5 offset-md-6 mt-5 btn"
									type="submit"
									onClick={() => setLgShow(false)}
								>
									Add
								</Button>
							</div>
						</form>
					</Modal.Body>
				</Modal>
			</Container>
			<Container className="header-center mt-5 mb-5 card col-md-9">
				<Row className="mt-4 mb-4">
					<Col md="auto" className="text-center mt-1">
						<h6>
							Select a date{" "}
							<span className="text-danger">
								<b>*</b>
							</span>
						</h6>
					</Col>
					<Col md="auto" className="text-center mt-1">
						<DropdownButton
							key="down-centered"
							id={`dropdown-button-drop-down-centered`}
							drop="down-centered"
							variant="success"
							title={dateOption}
							onSelect={handleDateSelect}
						>
							<div className="dropScroll">
								{days.map((day, index) => (
									<Dropdown.Item eventKey={index}>{day.currDay}</Dropdown.Item>
								))}
							</div>
						</DropdownButton>
					</Col>
				</Row>
				<Row className="mt-4">
					<Col md="auto" className="text-center mt-1">
						<h6>
							Select a slot{" "}
							<span className="text-danger">
								<b>*</b>
							</span>
						</h6>
					</Col>
				</Row>
				<Row className="mb-4">
					<Col md="auto" className="text-center mt-1">
						{dateSelected &&
							getTimeSlotsData?.allTimeSlots?.map((timeslots, index) =>
								!timeslots.isEmpty || timeslots.isBlocked ? (
									<Button className="btn btn-danger m-1" disabled>
										<b>{timeslots.timeSlot} No slots avaliable</b>
									</Button>
								) : (
									<button
										className={
											slotOption === timeslots.timeSlot
												? "btn btn-success m-1"
												: "btn btn-outline-success m-1"
										}
										onClick={() =>
											handleSlotSelect(timeslots.timeSlot, timeslots.curCount)
										}
									>
										<b>{`${timeslots.timeSlot} available slots: ${
											timeslots.maxAppointmentsperhour - timeslots.curCount
										} `}</b>
									</button>
								)
							)}
					</Col>
				</Row>
			</Container>
			<Container className="header-center mt-3">
				<Row className="justify-content-center">
					<Col md="auto" className="text-center mt-1">
						{textVis === "d-none text-danger" ? (
							<Button disabled className="btn-danger" onClick={submitHandler}>
								Book Appointment
							</Button>
						) : (
							<Button className="btn-secondary" onClick={submitHandler}>
								Book Appointment
							</Button>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default OnSpotBookingScreens;
