/** @format */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Loader from "../../../Components/Loader";
import { register } from "../../../redux/doctorRedux/doctorApiCalls";
import Form from "react-bootstrap/Form";

import Message from "../../../Components/Message";
import { registerReset } from "../../../redux/userRegister";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { allMessagesStart } from "../../../redux/allMessages";
import Meta from "../../../Components/Meta";
import { useParams } from "react-router-dom";

const RegisterScreen = () => {
	const { clinicName } = useParams();
	const history = useNavigate();
	const dispatch = useDispatch();
	const [name, setName] = useState("");
	const [mobilenumber, setmobilenumber] = useState("");
	const [address, setAddress] = useState("");
	const [gender, setGender] = useState("");

	const [dob, setdob] = useState("");
	const userLogin = useSelector((state) => state.userRegister);
	const [show, setShow] = useState(false);
	const handleClick = () => setShow(!show);
	const [show1, setShow1] = useState(false);
	const handleClick1 = () => setShow1(!show1);
	const [bloodGroup, setBloodGroup] = useState("Select a blood group");
	console.log(userLogin + "jdkjs");
	const { loading, error, userInfo, success } = useSelector(
		(state) => state.userRegister
	);
	useEffect(() => {
		if (userInfo) {
			console.log("111");
			localStorage.removeItem("userRegister");
		}
	}, [history, userInfo, success]);
	const submitHandler = () => {
		// e.preventDefault();

		var letters = /^[A-Za-z\s]+$/;
		if (name === "") {
			alert("Username cannot be empty");
		} else if (!name.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (gender === "") {
			alert("Please select gender, gender cannot be empty");
		} else if (dob === "") {
			alert("Please select dob, dob cannot be empty");
		} else if (address === "") {
			alert("Address cannot be empty");
		} else {
			// alert("User created successfully");
			if (bloodGroup === "Select a blood group") {
				dispatch(
					register(
						name.trim(),
						mobilenumber,
						gender,
						dob,
						address.trim(),
						"NA",
						clinicName
					)
				);
			} else {
				dispatch(
					register(
						name.trim(),
						mobilenumber,
						gender,
						dob,
						address.trim(),
						bloodGroup,
						clinicName
					)
				);
			}
			setName("");
			setmobilenumber("");
			setAddress("");
			setGender("");
			setBloodGroup("Select a blood group");
		}
	};

	/////////////////////////////////Alert handling////////////////////////////////////////////////////
	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const [showPopup, setShowPopup] = React.useState(false);
	const handleBloodGroup = (eventKey) => {
		setBloodGroup(eventKey);
	};

	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log("error");
		dispatch(registerReset());
	};
	const blood_groups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-", "NA"];

	const ISTOffset = 330; // 5 hours and 30 minutes in minutes
	const todayInIST = new Date(new Date().getTime() + ISTOffset * 60000)
		.toISOString()
		.split("T")[0];

	return (
		<div className="pageSize">
			<Meta />
			{error && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}

			<div className="row row-content">
				<div className="col-12 col-md-4 offset-md-4 registerForm">
					<h2 className="loginHeader">Register</h2>
					<form className="form-group" id="user-form">
						<div className="form-group">
							<label for="name" className="col-md-12 col-form-label">
								<b>Name:</b>
							</label>
							<div className="col-md-12">
								<input
									type="text"
									className="form-control"
									id="name"
									name="name"
									placeholder="Enter Your Name"
									required="required"
									minLength="4"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
						</div>
						<div className="form-group">
							<label for="mobileno" className="col-md-12 col-form-label">
								<b>Mobile Number:</b>
							</label>
							<div className="col-md-12">
								<input
									type="tel"
									className="form-control"
									id="mobileno"
									name="mobileno"
									placeholder="Enter Your Mobile Number"
									required="required"
									onChange={(e) => setmobilenumber(e.target.value)}
									minlength="10"
									maxlength="10"
									pattern="^[0-9]*$"
									value={mobilenumber}
								/>
							</div>
						</div>
						<div className="form-group">
							<label for="bloodgroup" className="col-md-12 col-form-label">
								<b>Blood Group:</b>
							</label>
							<DropdownButton
								className="col-md-1 btn"
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={bloodGroup}
								onSelect={handleBloodGroup}
							>
								{blood_groups.map((blood) => (
									<Dropdown.Item eventKey={blood}>{blood}</Dropdown.Item>
								))}
							</DropdownButton>
						</div>
						<div className="form-group">
							<label for="mobileno" className="col-md-12 col-form-label">
								<b>Gender:</b>
							</label>
							<div className="col-md-12">
								<div className="row">
									<div className="col-6">
										<Form.Check
											onChange={() => {
												setGender("Male");
											}}
											checked={gender === "Male"}
											className="form-control"
											inline
											label="Male"
											type="radio"
											id={`inline-radio-1`}
										/>
									</div>
									<div className="col-6">
										<Form.Check
											onChange={() => {
												setGender("Female");
											}}
											checked={gender === "Female"}
											inline
											label="Female"
											className="form-control"
											type="radio"
											id={`inline-radio-2`}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="form-group">
							<label for="dob" className="col-md-12 col-form-label">
								<b>Date Of Birth:</b>
							</label>
							<div className="col-md-12">
								<input
									type="date"
									className="form-control"
									id="dob"
									name="dob"
									onChange={(e) => setdob(e.target.value)}
									max={todayInIST}
								/>
							</div>
						</div>
						<div className="form-group">
							<label for="name" className="col-md-12 col-form-label">
								<b>Address:</b>
							</label>
							<div className="col-md-12">
								<textarea
									type="text"
									className="form-control"
									id="address"
									name="address"
									placeholder="Enter Your Address"
									required="required"
									minLength="4"
									onChange={(e) => setAddress(e.target.value)}
									value={address}
								/>
							</div>
						</div>

						<div className="form-group">
							<button
								type="button"
								className="btn btn-success mt-5 offset-2 col-8"
								onClick={submitHandler}
							>
								<b>Register</b>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default RegisterScreen;
