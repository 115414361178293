import { createSlice } from "@reduxjs/toolkit";

const getDoctorVisitedPatientsforParticularDateReducer = createSlice({
  name: "getDoctorVisitedPatientsforParticularDate",
  initialState: {},
  reducers: {
    getDoctorVisitedPatientsforParticularDateRequest: (state) => {
      state.loading = true;
    },
    getDoctorVisitedPatientsforParticularDateSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.getDoctorVisitedPatientsforParticularDate = action.payload;
      state.error = false;
      state.success = true;
    },
    getDoctorVisitedPatientsforParticularDateFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getDoctorVisitedPatientsforParticularDateReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  getDoctorVisitedPatientsforParticularDateRequest,
  getDoctorVisitedPatientsforParticularDateSuccess,
  getDoctorVisitedPatientsforParticularDateFailure,
  getDoctorVisitedPatientsforParticularDateReset,
} = getDoctorVisitedPatientsforParticularDateReducer.actions;
export default getDoctorVisitedPatientsforParticularDateReducer.reducer;
