/** @format */
import {
	getUserDataByCategoryRequest,
	getUserDataByCategorySuccess,
	getUserDataByCategoryFailure,
} from "./User_Management/getUserDataByCategory";

import {
	getUserDataInSearchPatientsRequest,
	getUserDataInSearchPatientsSuccess,
	getUserDataInSearchPatientsFailure,
} from "./User_Management/getUserDataInSearchPatients";
import { SERVER_URL } from "../../App";
import axios from "axios";

import { persistor } from "../store";
import {
	createDoctorStart,
	createDoctorSuccess,
	createDoctorFailure,
} from "./Doctor_Management/createDoctor";

import {
	doctorBookingGetFailure,
	doctorBookingGetRequest,
	doctorBookingGetSuccess,
} from "./Booking_Management/getBooking";

import {
	changeOnHoldStart,
	changeOnHoldFailure,
	changeOnHoldSuccess,
} from "./Booking_Management/changeOnHold";
import {
	changeSlotSuccess,
	changeSlotFailure,
	changeSlotStart,
} from "./Booking_Management/changeSlot";

import {
	adminCreateBookingStart,
	adminCreateBookingFailure,
	adminCreateBookingSuccess,
} from "./Booking_Management/adminCreateBooking";

import {
	getLeavesRequest,
	getLeavesFailure,
	getLeavesSuccess,
} from "../doctorRedux/Leave_Management/getLeaves";

import {
	getDoctorVisitedPatientsforParticularDateFailure,
	getDoctorVisitedPatientsforParticularDateRequest,
	getDoctorVisitedPatientsforParticularDateSuccess,
} from "./Doctor_Management/getDoctorVisitedPatientsforParticularDate";

import {
	createReceptionistStart,
	createReceptionistSuccess,
	createReceptionistFailure,
} from "./Reception_Management/addReceptionist";

import {
	deleteReceptionFailure,
	deleteReceptionRequest,
	deleteReceptionSuccess,
} from "./Reception_Management/deleteReception";

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";

import {
	getAllReceptionFailure,
	getAllReceptionRequest,
	getAllReceptionSuccess,
} from "./Reception_Management/getAllReceptions";

import { logout } from "../doctorRedux/MyProfile_Management/VerifyCode.js";

const username = localStorage.getItem("username");

export const logout3 = () => (dispatch) => {
	dispatch(logout());
	// dispatch(userUpdateProfileReset());
	// dispatch(bookingListMyReset());
	// dispatch(tourReviewCreateReset());
	localStorage.removeItem("userInfo");
	localStorage.removeItem("username");
	localStorage.removeItem("adminInfo");
	localStorage.removeItem("doctorInfo");
	localStorage.removeItem("token-user");
	localStorage.removeItem("date");
	localStorage.removeItem("persist:root");
	persistor.pause();
	persistor.flush().then(() => {
		return persistor.purge();
	});
};

axios.interceptors.response.use(
	(response) => {
		console.log(response);
		// Do something with the response data
		return response;
	},
	(error) => {
		// Handle API errors, including JWT expiration
		console.log(error.response.data.data.message);
		if (
			error.response &&
			error.response.data.data.message === "JWT expired. Please login again"
		) {
			// Logout the user when the token has expired
			logout3();
		}

		return Promise.reject(error);
	}
);

export const createReception =
	(name, mobilenumber, email, gender, dob, address, clinicname) =>
	async (dispatch) => {
		try {
			dispatch(createReceptionistStart());
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/doctor/addReception`,
				{
					name,
					mobilenumber,
					email,
					gender,
					dob,
					address,
				},
				config
			);

			console.log(data);
			dispatch(createReceptionistSuccess(data));
			dispatch(allMessagesSuccess("Reception created successfully"));
		} catch (error) {
			console.log(error);
			dispatch(
				createReceptionistFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const createDoctor =
	(
		name,
		mobilenumber,
		speciality,
		maxAppointmentsperhour,
		startTime,
		endTime,
		breakTime,
		specialityId,
		designation,
		education,
		experience,
		personality,
		email,
		address,
		dob,
		gender,
		clinicname
	) =>
	async (dispatch) => {
		try {
			console.log(breakTime);
			dispatch(createDoctorStart());
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/admin/adddoctor`,
				{
					name,
					mobilenumber,
					specialityId,
					maxAppointmentsperhour,
					startTime,
					endTime,
					breakTime,
					speciality,
					designation,
					education,
					experience,
					personality,
					email,
					address,
					dob,
					gender,
				},
				config
			);

			console.log(data);
			dispatch(createDoctorSuccess(data));
			dispatch(allMessagesSuccess("Doctor Succesfully created"));
		} catch (error) {
			console.log(error);
			dispatch(
				createDoctorFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const changeToOnHold =
	(id, dayIdx, timeSlotIdx, clinicname) => async (dispatch) => {
		try {
			console.log("11223344" + id);
			dispatch(changeOnHoldStart());

			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/reception/changeOnHold/${username}`,
				{ id, dayIdx, timeSlotIdx },
				config
			);
			console.log(data);
			dispatch(changeOnHoldSuccess(data));
			dispatch(allMessagesSuccess("Appointment moved to onhold"));
		} catch (error) {
			dispatch(
				changeOnHoldFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const changeSlot =
	(id, slot, dayIdx, slotIdx, clinicname) => async (dispatch) => {
		try {
			console.log("11223344" + id);
			dispatch(changeSlotStart());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/reception/changeSlot`,
				{ id, slot, username, dayIdx, slotIdx },
				config
			);
			console.log(data);
			dispatch(changeSlotSuccess(data));
			dispatch(allMessagesSuccess("Slot changed successfully"));
		} catch (error) {
			dispatch(
				changeSlotFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getUserDataByCategory =
	(mobileno, clinicname) => async (dispatch) => {
		try {
			console.log("11223344" + mobileno);
			dispatch(getUserDataByCategoryRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/users/getuserByCategory/${username}`,
				{ mobileno },
				config
			);

			console.log(data);
			dispatch(getUserDataByCategorySuccess(data));
		} catch (error) {
			dispatch(
				getUserDataByCategoryFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getUserDataInSearchPatient =
	(mobileno, clinicname) => async (dispatch) => {
		try {
			console.log("11223344" + mobileno);
			dispatch(getUserDataInSearchPatientsRequest());

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/users/getuserByCategory/${username}`,
				{ mobileno },
				config
			);

			console.log(data);
			dispatch(getUserDataInSearchPatientsSuccess(data));
		} catch (error) {
			dispatch(
				getUserDataInSearchPatientsFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getUserDataByCategoryForUser =
	(mobileno, clinicname) => async (dispatch) => {
		try {
			console.log("11223344" + mobileno);
			dispatch(getUserDataByCategoryRequest());

			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/users/getuserByCategoryForUser`,
				{ mobileno },
				config
			);

			console.log(data);
			dispatch(getUserDataByCategorySuccess(data));
		} catch (error) {
			dispatch(
				getUserDataByCategoryFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const adminCreateBooking =
	(
		name,
		doctorName,
		specialityName,
		speciality,
		doctor,
		selectedDate,
		slot,
		slotCount,
		userPhone,
		user1,
		clinicname
	) =>
	async (dispatch) => {
		try {
			const currDate = new Date(); // Get current date
			const date = currDate.getDate();
			const month = currDate.getMonth() + 1; // Month index starts from 0
			const year = currDate.getFullYear();
			var scheduledDate = date + " / " + month + " / " + year;
			var day_num = currDate.getDay(currDate);
			console.log(day_num);
			const weekday = [
				"sunday",
				"monday",
				"tuesday",
				"wednesday",
				"thursday",
				"friday",
				"saturday",
			];
			console.log(weekday[day_num]);
			const day = weekday[day_num];
			console.log(day);
			console.log(user1.data.data);
			dispatch(adminCreateBookingStart());
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};

			const data = await axios.post(
				`${SERVER_URL}/api/v1/admin/adminCreateBooking`,
				{
					name: name,
					specialityName: specialityName,
					doctorName: doctorName,
					speciality: speciality,
					doctor: doctor,
					scheduledDate: selectedDate,
					slot: slot,
					slotCount: slotCount,
					day: day,
					userPhone: userPhone,
					user1: user1.data.data,
				},
				config
			);
			console.log(data + "review123");
			dispatch(adminCreateBookingSuccess(data));
			dispatch(allMessagesSuccess("Booking created successfully"));
		} catch (error) {
			const message =
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message;
			if (message === "Not authorized, token failed") {
				localStorage.removeItem("persist:root");
			}
			dispatch(adminCreateBookingFailure(message));
		}
	};

export const getLeaves = (id, clinicname) => async (dispatch) => {
	try {
		console.log("11223344" + id);
		dispatch(getLeavesRequest());

		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				clinicname: clinicname,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/admin/getLeaves/${id}`,
			config
		);
		console.log(data);
		dispatch(getLeavesSuccess(data));
	} catch (error) {
		dispatch(
			getLeavesFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const getDoctorVisitedPatientsforParticularDate =
	(id, date, clinicname) => async (dispatch) => {
		try {
			console.log("11223344" + id);
			dispatch(getDoctorVisitedPatientsforParticularDateRequest());
			//date yyyy-mm-dd
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/doctor/getPatientsforDate/${id}/${date}/${username}`,
				config
			);
			console.log(data);
			dispatch(getDoctorVisitedPatientsforParticularDateSuccess(data));
		} catch (error) {
			dispatch(
				getDoctorVisitedPatientsforParticularDateFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const deleteReception = (id, clinicname) => async (dispatch) => {
	try {
		console.log("11223344" + id);
		dispatch(deleteReceptionRequest());

		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				clinicname: clinicname,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/doctor/deleteReception/${id}`,
			{
				id,
			},
			config
		);
		console.log(data);
		dispatch(deleteReceptionSuccess(data));
		dispatch(allMessagesSuccess("Receptionist Succesfully deleted"));
	} catch (error) {
		dispatch(
			deleteReceptionFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const getAllReception = (clinicname) => async (dispatch) => {
	try {
		dispatch(getAllReceptionRequest());

		const token = localStorage.getItem("token-user");
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				clinicname: clinicname,
			},
		};
		const { data } = await axios.get(
			`${SERVER_URL}/api/v1/doctor/getAllReceptions`,

			config
		);
		console.log(data);
		dispatch(getAllReceptionSuccess(data));
	} catch (error) {
		dispatch(
			getAllReceptionFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};
