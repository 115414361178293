import { createSlice } from "@reduxjs/toolkit";

const getPrescriptionByDateReducer = createSlice({
  name: "getPrescriptionByDate",
  initialState: {},
  reducers: {
    getPrescriptionByDateRequest: (state) => {
      state.loading = true;
    },
    getPrescriptionByDateSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.getPrescriptionByDate = action.payload;
      state.error = false;
      state.success = true;
    },
    getPrescriptionByDateFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getPrescriptionByDateReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  getPrescriptionByDateRequest,
  getPrescriptionByDateSuccess,
  getPrescriptionByDateFailure,
  getPrescriptionByDateReset,
} = getPrescriptionByDateReducer.actions;
export default getPrescriptionByDateReducer.reducer;
