import { createSlice } from "@reduxjs/toolkit";

const getPrescriptionByCategoryReducer = createSlice({
	name: "getPrescriptionByCategory",
	initialState: {},
	reducers: {
		getPrescriptionByCategoryRequest: (state) => {
			state.loading = true;
		},
		getPrescriptionByCategorySuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getPrescriptionByCategory = action.payload;
			state.error=false;
			state.success = true;
		},
		getPrescriptionByCategoryFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getPrescriptionByCategoryReset: (state) => {
			return { error:false,success:false };
		},
	},
});

export const {
	getPrescriptionByCategoryRequest,
	getPrescriptionByCategorySuccess,
	getPrescriptionByCategoryFailure,
	getPrescriptionByCategoryReset,
} = getPrescriptionByCategoryReducer.actions;
export default getPrescriptionByCategoryReducer.reducer;
