/** @format */
import Message from "../../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, useLocation } from "react-router-dom";
import Loader from "../../../Components/Loader";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import "./addTemplate.css";
//API calls
import { createTemplate } from "../../../redux/doctorRedux/doctorApiCalls";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { createTemplateReset } from "../../../redux/doctorRedux/Template_Management/createTemplate";

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../../redux/allMessages";
import { useParams } from "react-router-dom";

//Here we create child for Templates management

const DynamicTemplateCreateRow = ({
	index,
	values,
	handleChange,
	handleDelete,
}) => {
	const [activeBox, setActiveBox] = useState(true);
	const [Time, setTime] = useState("");
	const [M, setM] = useState("");
	const [A, setA] = useState("");
	const [N, setN] = useState("");
	const handleFreq = (value) => {
		if (value === "M") {
			if (M === "M") setM("");
			else setM("M");
		} else if (value === "A") {
			if (A === "A") setA("");
			else setA("A");
		} else if (value === "N") {
			if (N === "N") setN("");
			else setN("N");
		}
	};
	useEffect(() => {
		if (Time !== "" && (M === "M" || A === "A" || N === "N")) {
			handleChange(`dosage-${index}`, Time + "," + M + "," + A + "," + N);
		}
	}, [Time, M, A, N]);

	useEffect(() => {
		handleChange(`dosage-${index}`, "");
		setTime("");
		setM("");
		setA("");
		setN("");
	}, [activeBox]);

	useEffect(() => {
		handleChange(`duration-${index}`, "1" + " " + duration);
	}, []);

	const [duration, setDuration] = useState("Days");
	const changeDur = (eventKey) => {
		setDuration(eventKey);
		handleChange(`duration-${index}`, "1 " + eventKey);
	};
	const incr = (quan) => {
		let quan1 = Number(quan);
		quan1++;
		console.log(quan, "hi");
		handleChange(`duration-${index}`, quan1.toString() + " " + duration);
	};
	const decr = (quan) => {
		quan = Number(quan);
		quan--;
		handleChange(`duration-${index}`, quan.toString() + " " + duration);
	};

	return (
		<div id="medication" className="row mt-4 mb-2 section">
			<div className="col-md-3">
				<div className="col-md-6">Medicine:</div>
				<textarea
					rows={1}
					cols={33}
					name={`name-${index}`}
					value={values[`name-${index}`]}
					onChange={(e) => handleChange(`name-${index}`, e.target.value)}
				></textarea>
			</div>
			<div className="col-md-4 offset-md-1">
				<div className="col-md-6">Frequency:</div>
				<button
					className="btn btn-primary mr-2"
					onClick={() => setActiveBox(!activeBox)}
				>
					<i class="fas fa-sync-alt"></i>
				</button>
				{activeBox ? (
					<>
						<ButtonGroup className="col-md-2 mr-3" aria-label="Basic example">
							<Button
								onClick={() => setTime("B/F")}
								// variant="secondary"
								variant={Time === "B/F" ? "success" : "secondary"}
							>
								B/F
							</Button>
							<Button
								onClick={() => setTime("A/F")}
								variant={Time === "A/F" ? "success" : "secondary"}
							>
								A/F
							</Button>
						</ButtonGroup>
						<Button
							onClick={() => handleFreq("M")}
							className="offset-md-2 mr-1"
							variant={M === "M" ? "success" : "outline-success"}
						>
							M
						</Button>
						<Button
							onClick={() => handleFreq("A")}
							className="mr-1"
							variant={A === "A" ? "success" : "outline-success"}
						>
							A
						</Button>
						<Button
							onClick={() => handleFreq("N")}
							className="mr-1"
							variant={N === "N" ? "success" : "outline-success"}
						>
							N
						</Button>
					</>
				) : (
					<>
						<textarea
							rows={1}
							cols={33}
							type="text"
							name={`dosage-${index}`}
							value={values[`dosage-${index}`].split("@@")[1]}
							onChange={(e) =>
								handleChange(`dosage-${index}`, "O@@" + e.target.value)
							}
						></textarea>
					</>
				)}
			</div>
			<div className="col-md-3 ">
				<div className="col-md-6">Duration:</div>
				<>
					<button
						className="btn btn-primary ml-1"
						onClick={() => decr(values[`duration-${index}`]?.split(" ")[0])}
					>
						<i className="fas fa-minus"></i>
					</button>

					<input
						type="text"
						className="ml-1 mr-1 col-md-4"
						name={`duration-${index}`}
						value={values[`duration-${index}`]?.split(" ")[0]}
						onChange={(e) => handleChange(`duration-${index}`, e.target.value)}
					/>
					<button
						className="btn btn-primary mr-1"
						onClick={() => incr(values[`duration-${index}`]?.split(" ")[0])}
					>
						<i className="fas fa-plus"></i>
					</button>
					<DropdownButton
						className="col-md-1 btn"
						key="down-centered"
						id={`dropdown-button-drop-down-centered`}
						drop="down-centered"
						variant="success"
						title={duration}
						onSelect={changeDur}
					>
						<Dropdown.Item eventKey={"Days"}>{"Days"}</Dropdown.Item>
						<Dropdown.Item eventKey={"Weeks"}>{"Weeks"}</Dropdown.Item>
						<Dropdown.Item eventKey={"Months"}>{"Months"}</Dropdown.Item>
						<Dropdown.Item eventKey={"Years"}>{"Years"}</Dropdown.Item>
					</DropdownButton>
				</>
				{/* <textarea
					rows={1}
					cols={33}
					type="text"
					name={`duration-${index}`}
					value={values[`duration-${index}`]}
					onChange={(e) => handleChange(`duration-${index}`, e.target.value)}
				></textarea> */}
			</div>
			<div className="col-md-1">
				<button
					className="m-2 btn btn-danger"
					onClick={() => handleDelete(index)}
				>
					<i className="fa fa-trash fa-2x"></i>
				</button>
			</div>
		</div>
	);
};

const AddTemplateScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	// success and failure Message

	const createTemplateData = useSelector((state) => state.createTemplate);
	const createTemplatesuccess = createTemplateData.success;
	const createTemplateerror = createTemplateData.error;
	const createTemplateloading = createTemplateData.loading;

	/////////////////////////////////////////////////////

	const doctorLogin = useSelector((state) => state.doctorLogin);
	const { doctorInfo } = doctorLogin;

	console.log(doctorInfo?.user?._id);

	// Create Template

	const [templateName, setTemplateName] = useState("");
	const [rows, setRows] = useState([]);
	const [values, setValues] = useState({});

	const handleChange = (name, value) => {
		// const { name, value } = event.target;
		setValues((prevValues) => ({ ...prevValues, [name]: value }));
	};

	const handleAddRow = () => {
		setRows((prevRows) => [...prevRows, Date.now()]);
	};

	const handleDeleteRow = (index) => {
		const updatedValues = { ...values };
		Object.keys(updatedValues)
			.filter((key) => key.includes(index))
			.forEach((key) => delete updatedValues[key]);

		setValues(updatedValues);

		setRows((prevRows) => prevRows.filter((rowIndex) => rowIndex !== index));
	};
	useEffect(() => {
		handleAddRow();
	}, []);

	console.log(rows);
	console.log(values);

	const createSubmitHandler = () => {
		debugger;
		if (templateName === "") {
			alert("Template name cannot be empty");
		} else if (
			Object.keys(values).length % 3 !== 0 ||
			Object.keys(values).length / 3 !== rows.length
		) {
			alert("Enter all the required medication fields");
		} else {
			// console.log(Object.keys(valuesTest).length % 3);
			// console.log(Object.keys(valuesTest).length / 3);

			let extractedValues = [];
			let flag = false;
			Object.keys(values).forEach((key) => {
				let id = key.replace(/-\d+$/, "");

				if (key.includes("dosage")) {
					let index = key.split("-")[1];
					let dosage = values[`dosage-${index}`];
					if (
						!dosage?.includes("A/F") &&
						!dosage?.includes("B/F") &&
						!dosage?.includes("O@@")
					) {
						flag = true;
					}
				}
			});

			if (flag) {
				alert("Please Select B/F or A/F for the entered medicines.");
			} else {
				Object.keys(values).forEach((key) => {
					let id = key.replace(/-\d+$/, "");

					if (key.includes("name")) {
						let index = key.split("-")[1];
						let name = values[`name-${index}`];
						let dosage = values[`dosage-${index}`];
						let duration = values[`duration-${index}`];

						extractedValues?.push({
							name: name,
							dosage: dosage,
							duration: duration,
						});
					}
				});
				const medicines = extractedValues;

				dispatch(
					createTemplate(
						doctorInfo?.user?._id,
						templateName,
						medicines,
						clinicName
					)
				);
				setTemplateName("");
				setRows([]);
				handleAddRow();
				setValues([]);
			}
		}
	};

	// const createSubmitHandler = () => {
	//   if (templateName === "") {
	//     alert("Template name cannot be empty");
	//   } else {
	//     const medicinesData = JSON.parse(JSON.stringify(values));
	//     let flag = 1;
	//     const extractedValues = [];
	//     const keys = Object.keys(medicinesData);
	//     if (keys.length == 0) {
	//       alert("Please add medicines");
	//       flag = 0;
	//     } else {
	//       for (let i = 0; i < keys.length; i += 3) {
	//         const nameKey = keys[i];
	//         const dosageKey = keys[i + 1];
	//         const durationKey = keys[i + 2];

	//         const nameValue = medicinesData[nameKey];
	//         const dosageValue = medicinesData[dosageKey];
	//         const durationValue = medicinesData[durationKey];

	//         if (nameValue == "") {
	//           flag = 0;
	//           alert("Medicine name cannot be empty");
	//           break;
	//         } else if (dosageValue == "") {
	//           flag = 0;
	//           alert("Dosage cannot be empty");
	//           break;
	//         } else if (durationValue == "") {
	//           flag = 0;
	//           alert("Duration cannot be empty");
	//           break;
	//         } else if (
	//           Object.keys(values).length % 3 !== 0 ||
	//           Object.keys(values).length / 3 !== rows.length
	//         ) {
	//           alert("Enter all the required medication fields");
	//         } else {
	//           extractedValues.push({
	//             name: nameValue,
	//             dosage: dosageValue,
	//             duration: durationValue,
	//           });
	//         }
	//       }
	//     }
	//     console.log(extractedValues);
	//     const medicines = extractedValues;
	//     console.log(flag);

	//     // console.log(medicines);
	//     // console.log(templateName);
	//     if (flag == 1) {
	//       dispatch(
	//         createTemplate(doctorInfo?.user?._id, templateName, medicines)
	//       );
	//       setTemplateName("");
	//       setRows([]);
	//       handleAddRow();
	//       setValues([]);
	//     }
	//   }
	// };

	//ALerts//////////////////////////////////////////////////////////////////

	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const alertmsg = useSelector((state) => state.allMessages);

	const [showPopup8, setShowPopup8] = React.useState(false);
	const toggleShowInfoPopup8 = (e) => {
		setShowPopup8(!showPopup8);
		e.preventDefault();
		console.log("error");
		dispatch(createTemplateReset());
	};

	useEffect(() => {
		if (createTemplatesuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(createTemplateReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [createTemplatesuccess, dispatch]);
	const handleClear = () => {
		setTemplateName("");
		setValues({});
		setRows([]);
	};

	return (
		<div className="pageSize">
			<Meta />

			<h2 className="header-center mt-3 mb-3">Add Template</h2>
			{createTemplateerror && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup8}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{createTemplateerror}
				</Message>
			)}
			{createTemplateloading && <Loader />}

			<div>
				<Container>
					<div className="row mt-4 mb-2 section">
						<div className="col-md-2">Template Name: </div>
						<input
							type="text"
							className="col-md-4"
							value={templateName}
							onChange={(e) => setTemplateName(e.target.value)}
						/>
					</div>
					{rows.map((rowIndex) => (
						<DynamicTemplateCreateRow
							key={rowIndex}
							index={rowIndex}
							values={values}
							handleChange={handleChange}
							handleDelete={handleDeleteRow}
						/>
					))}
					<div className="row mt-4 mb-2 section">
						<button
							className="btn btn-secondary offset-md-5"
							onClick={handleAddRow}
						>
							+ Add more medicines
						</button>
					</div>
				</Container>
				<Container className="mt-4">
					<div className="row mt-4 mb-2 offset-md-10 section">
						<button className="btn btn-success" onClick={createSubmitHandler}>
							Submit
						</button>
						<button
							className="offset-md-1 btn btn-danger"
							onClick={handleClear}
						>
							Clear
						</button>
					</div>
				</Container>
			</div>
		</div>
	);
};

export default AddTemplateScreen;
