/** @format */

import { Container, Row, Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function Header() {
	return (
		<>
			<Navbar expand='lg' className='bg-body-tertiary'>
				<Container className='header'>
					<Navbar.Brand href='/'>
						<img src='/hextGen_logo.png' height='70' alt='' loading='lazy' />
					</Navbar.Brand>
					<Navbar.Toggle aria-controls='basic-navbar-nav' />
					<Navbar.Collapse
						id='basic-navbar-nav'
						className='justify-content-end'>
						<Nav className='me-auto'>
							<Nav.Link href='#aboutUs'>ABOUT</Nav.Link>
							<Nav.Link href='#ourServices'>OUR SERVICES</Nav.Link>
							<Nav.Link href='#products'>PRODUCTS</Nav.Link>
							{/* <Nav.Link href='#contactUs'>CONTACT US</Nav.Link> */}
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>

			<div class='bg-color' id='aboutUs'>
				<Row className='align-items-center'>
					<Col md='6'>
						<img
							src='/main.jpg'
							alt='jumbotron image'
							className='img-fluid m-3'
						/>
					</Col>
					<Col className='justify-content-center' md='6'>
						<h1 className='mb-3 main-text'>
							Revolutionize Your Hospital with HextGen
						</h1>
						<p className='mb-3'>
							HextGen offers a robust SaaS web application designed to elevate
							healthcare management. Our platform goes beyond electronic health
							records, enabling hospitals to efficiently manage patient data,
							streamline appointment scheduling, track revenue generation, and
							even facilitate administrative tasks
						</p>
						<a
							className='btn btn-outline-primary'
							href='#contactUs'
							role='button'
							aria-pressed='true'>
							Contact Us
						</a>
					</Col>
				</Row>
			</div>
		</>
	);
}
