/** @format */

import { createSlice } from "@reduxjs/toolkit";
const receptionItemsFromStorage = JSON.parse(
	localStorage.getItem("persist:root")
)
	? JSON.parse(localStorage.getItem("persist:root"))?.receptionLogin
	: {};

const reception = createSlice({
	name: "reception",
	initialState: receptionItemsFromStorage,
	reducers: {
		verifyStart: (state) => {
			state.loading = true;
		},
		verifySuccess: (state, action) => {
			state.loading = false;
			state.receptionInfo = action.payload;
			state.error = false;
			state.success = true;
			console.log(state.receptionInfo);
		},
		verifyFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		verifyCodeReceptionReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
		logout: (state) => {
			state.receptionInfo = {};
		},
	},
});

export const {
	verifyStart,
	verifySuccess,
	verifyFailure,
	logout,
	verifyCodeReceptionReset,
} = reception.actions;
export default reception.reducer;
