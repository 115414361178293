/** @format */

//HextGen
import MainComponent from "./HextGenApp/MainComponent.js";

//Clinic
// import 'react-toastify/dist/ReactToastify.css';
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Switch,
	useParams,
} from "react-router-dom";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import React from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import HomeScreens from "./Screens/HomeScreen";

//import TourScreen from "./screens/TourScreen";

import RegisterScreen from "./Screens/doctorScreens/PatientManagement/RegisterPatientScreen.js";
//import AccountScreen from "./screens/AccountScreen";

import AdminLoginScreens from "./Screens/adminScreens/AdminLoginScreen";

import AddReceptionScreens from "./Screens/doctorScreens/ReceptionManagement/AddReception";
import DeleteReceptionScreens from "./Screens/doctorScreens/ReceptionManagement/DeleteReception";

import "../node_modules/font-awesome/css/font-awesome.min.css";

import EditPrescriptionScreen from "./Screens/doctorScreens/editPrescription";
import GetMyAppointmentsScreen from "./Screens/doctorScreens/getMyAppointments";
import GetUserPrescriptionsScreen from "./Screens/doctorScreens/GetUserPrescriptions";
import DoctorCreateLeavesScreen from "./Screens/doctorScreens/LeavesManagement/DoctorCreateLeaves.js";
import DoctorDeleteLeavesScreen from "./Screens/doctorScreens/LeavesManagement/DoctorDeleteLeaves.js";
import DoctorBlockSlotsScreen from "./Screens/doctorScreens/SlotManagement/DoctorBlockSlots.js";
import DoctorUnblockSlotsScreen from "./Screens/doctorScreens/SlotManagement/DoctorUnblockSlots.js";
import EditBookingPeriodScreen from "./Screens/doctorScreens/MyDoctor/editBookingPeriod.js";
// import DoctorLeavesScreen from "./Screens/doctorScreens/DoctorLeavesSlots";
import UpdatePasswordDoctorScreen from "./Screens/doctorScreens/MyDoctor/UpdatePasswordDoctorScreen.js";
import ViewPrescriptionDoctorScreen from "./Screens/doctorScreens/viewPrescriptionDoctor";
import PatientsVisitedScreen from "./Components/VisitedPatients";
// import DoctorLoginScreen from "./Screens/doctorScreens/DoctorLogin";
import AddTemplateScreen from "./Screens/doctorScreens/TemplateManagement/AddTemplate";
import EditTemplateScreen from "./Screens/doctorScreens/TemplateManagement/EditTemplate";
import DeleteTemplateScreen from "./Screens/doctorScreens/TemplateManagement/DeleteTemplate";
import SearchPatientScreens from "./Screens/doctorScreens/SearchPatients";
import Home from "./Screens/Revenue Screen/Home.js";

import { useSelector } from "react-redux";

import OnSpotBookingScreens from "./Screens/doctorScreens/OnSpotBooking.js";
import SearchPatientScreensREC from "./Screens/receptionScreens/SearchPatients";
import UpdatePasswordRECScreen from "./Screens/receptionScreens/updatePasswordReception";
import ViewPrescriptionReceptionScreen from "./Screens/receptionScreens/viewPrescriptionReception";
import BlockedSlots from "./Screens/receptionScreens/BlockedSlots";
import AppointmentListScreenREC from "./Screens/receptionScreens/AppointmentsList";
import ScheduledAppointments from "./Screens/receptionScreens/ScheduledAppointments";
// import MedicineManagement from "./Screens/doctorScreens/MedicineManagement";
import NextAppointmentScreen from "./Screens/doctorScreens/nextAppointment";
import MyProfileScreen from "./Screens/doctorScreens/MyDoctor/MyProfile.js";

import AlertMessageBar from "./Components/AlertMessage";
import ForgotPassowrd from "./Components/ForgotPassword";
import VerifyEmail from "./Components/VerifyEmail";

import SideBar from "./Components/SideBar.js";
import AboutUs from "./Screens/AboutUs";
import EditMySlots from "./Screens/doctorScreens/SlotManagement/EditMySlots.js";

import OnSpotBookingScreenReception from "./Screens/receptionScreens/OnSpotBookingReception";

import EditPatientScreen from "./Screens/doctorScreens/PatientManagement/EditPatientInfo.js";
import PatientsToBeVisitedScreen from "./Components/PatientsToBeVisited.js";
import PatientCountStatistics from "./Screens/Revenue Screen/PatientCountStatistics.js";
import PatientStatisticsScreens from "./Screens/Revenue Screen/PatientStatistics.js";
import HospitalStatistics from "./Screens/Revenue Screen/HospitalStatistics.js";
import AddUser from "./Components/AddUser";

import MyProfileScreenReception from "./Screens/receptionScreens/EditReception.js";

import MyClinicScreen from "./Screens/doctorScreens/MyClinic.js";
export const SERVER_URL = "https://backend.hextgen.com";

//export const SERVER_URL = "http://localhost:5000";
function App() {
	const { doctorInfo } = useSelector((state) => state.doctorLogin);

	const { receptionInfo } = useSelector((state) => state.receptionLogin);

	// useParams hook to get the postId
	const { clinicName } = useParams();

	// Use the retrieved postId as needed
	console.log("postId in App.js:", clinicName);
	console.log(receptionInfo?.user?.name);

	return (
		<div className="app-container">
			<Router>
				<div>
					<div className="content-wrapper">
						{(doctorInfo?.user?.name || receptionInfo?.user?.name) &&
						window?.location?.href !== "https://www.hextgen.com/" &&
						window?.location?.href !== "https://www.hextgen.com" &&
						window?.location?.href !== "http://localhost:3000/" &&
						window?.location?.href !== "http://localhost:3000" &&
						!window?.location?.href?.includes("/home") &&
						!window?.location?.href?.includes("/#aboutUs") &&
						!window?.location?.href?.includes("/#ourServices") &&
						!window?.location?.href?.includes("/#products") &&
						!window?.location?.href?.includes("/#contactUs") ? (
							<div className="sidebar-content d-none d-md-block">
								<SideBar clinicName={clinicName} />
							</div>
						) : (
							<div></div>
						)}
						<div className="main-content">
							{!window?.location?.href?.includes("/home") &&
								window?.location?.href !== "https://www.hextgen.com/" &&
								window?.location?.href !== "https://www.hextgen.com" &&
								window?.location?.href !== "http://localhost:3000/" &&
								window?.location?.href !== "http://localhost:3000" &&
								!window?.location?.href?.includes("/#aboutUs") &&
								!window?.location?.href?.includes("/#ourServices") &&
								!window?.location?.href?.includes("/#products") &&
								!window?.location?.href?.includes("/#contactUs") && <Header />}
							<AlertMessageBar />
							<div className="main1">
								<Routes>
									<Route path="/" element={<MainComponent />} />
									<Route
										path="/clinic/:clinicName/home"
										element={<HomeScreens />}
									/>
									<Route
										path="/clinic/:clinicName/doctor/myclinic"
										element={
											doctorInfo ? <MyClinicScreen /> : <AdminLoginScreens />
										}
									/>
									<Route
										path="/clinic/:clinicName/reception/booking"
										element={
											receptionInfo ? (
												<OnSpotBookingScreenReception />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/revenue"
										element={doctorInfo ? <Home /> : <AdminLoginScreens />}
									/>
									<Route
										path="/clinic/:clinicName/doctor/patientStatistics"
										element={
											doctorInfo ? (
												<PatientCountStatistics />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/patientStatistic"
										element={
											doctorInfo ? (
												<PatientStatisticsScreens />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/hospitalStatistic"
										element={
											doctorInfo ? (
												<HospitalStatistics />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/booking"
										element={
											doctorInfo ? (
												<OnSpotBookingScreens />
											) : (
												<AdminLoginScreens />
											)
										}
									/>

									<Route
										path="/clinic/:clinicName/doctor/addReception"
										element={
											doctorInfo ? (
												<AddReceptionScreens />
											) : (
												<AdminLoginScreens />
											)
										}
									/>

									<Route
										path="/clinic/:clinicName/doctor/deleteReception"
										element={
											doctorInfo ? (
												<DeleteReceptionScreens />
											) : (
												<AdminLoginScreens />
											)
										}
									/>

									<Route
										path="/clinic/:clinicName/doctor/patientstobevisited/userappointment/editpres"
										element={
											doctorInfo ? (
												<EditPrescriptionScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/viewpres"
										element={
											doctorInfo ? (
												<ViewPrescriptionDoctorScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									{/* <Route
										path="/doctor/myappointments"
										element={
											doctorInfo ? (
												<GetMyAppointmentsScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/> */}
									<Route
										path="/clinic/:clinicName/doctor/patientstobevisited"
										element={
											doctorInfo ? (
												<PatientsToBeVisitedScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/patientsvisited"
										element={
											doctorInfo ? (
												<PatientsVisitedScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/EditMySlots"
										element={
											doctorInfo ? <EditMySlots /> : <AdminLoginScreens />
										}
									/>

									<Route
										path="/clinic/:clinicName/doctor/EditBookingPeriod"
										element={
											doctorInfo ? (
												<EditBookingPeriodScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/searchPatients"
										element={
											doctorInfo ? (
												<SearchPatientScreens />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/updatepassword"
										element={
											doctorInfo ? (
												<UpdatePasswordDoctorScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/patientstobevisited/userappointment"
										element={
											doctorInfo ? (
												<GetUserPrescriptionsScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/login"
										element={<AdminLoginScreens />}
									/>
									{/* <Route path="/doctor/login" element={<DoctorLoginScreen />} /> */}
									<Route
										path="/clinic/:clinicName/doctor/createleaves"
										element={
											doctorInfo ? (
												<DoctorCreateLeavesScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/register"
										element={
											doctorInfo || receptionInfo ? (
												<RegisterScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/deleteleaves"
										element={
											doctorInfo ? (
												<DoctorDeleteLeavesScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/blockslots"
										element={
											doctorInfo ? (
												<DoctorBlockSlotsScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/unblockslots"
										element={
											doctorInfo ? (
												<DoctorUnblockSlotsScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/addtemplates"
										element={
											doctorInfo ? <AddTemplateScreen /> : <AdminLoginScreens />
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/edittemplates"
										element={
											doctorInfo ? (
												<EditTemplateScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/deletetemplates"
										element={
											doctorInfo ? (
												<DeleteTemplateScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/nextappointment"
										element={
											doctorInfo ? (
												<NextAppointmentScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/myprofile"
										element={
											doctorInfo ? <MyProfileScreen /> : <AdminLoginScreens />
										}
									/>
									<Route
										path="/clinic/:clinicName/reception/myprofile"
										element={
											receptionInfo ? (
												<MyProfileScreenReception />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/doctor/editPatient"
										element={
											doctorInfo ? <EditPatientScreen /> : <AdminLoginScreens />
										}
									/>
									<Route
										path="/clinic/:clinicName/reception/booking"
										element={
											receptionInfo ? (
												<OnSpotBookingScreens />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/reception/searchPatient"
										element={
											receptionInfo ? (
												<SearchPatientScreensREC />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/reception/viewpres"
										element={
											receptionInfo ? (
												<ViewPrescriptionReceptionScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>

									{/* <Route
										path="/reception/appointments"
										element={
											receptionInfo ? (
												<AppointmentListScreenREC />
											) : (
												<AdminLoginScreens />
											)
										}
									/> */}
									<Route
										path="/clinic/:clinicName/reception/patientstobevisited"
										element={
											receptionInfo ? (
												<PatientsToBeVisitedScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/reception/patientsvisited"
										element={
											receptionInfo ? (
												<PatientsVisitedScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/reception/scheduledAppointments"
										element={
											receptionInfo ? (
												<ScheduledAppointments />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/reception/BlockedSlots"
										element={
											receptionInfo ? <BlockedSlots /> : <AdminLoginScreens />
										}
									/>
									<Route
										path="/clinic/:clinicName/reception/updatepassword"
										element={
											receptionInfo ? (
												<UpdatePasswordRECScreen />
											) : (
												<AdminLoginScreens />
											)
										}
									/>
									<Route
										path="/clinic/:clinicName/reception/resetPassword/:token"
										element={<ForgotPassowrd />}
									/>
									<Route
										path="/clinic/:clinicName/doctor/resetPassword/:token"
										element={<ForgotPassowrd />}
									/>
									<Route
										path="/clinic/:clinicName/verifymail"
										element={<VerifyEmail />}
									/>

									<Route
										path="/clinic/:clinicName/aboutus"
										element={<AboutUs />}
									/>
									<Route
										path="/clinic/:clinicName/doctor/Adduser"
										element={<AddUser />}
									/>
								</Routes>
							</div>
							{!window?.location?.href?.includes("/#aboutUs") &&
								window?.location?.href !== "https://www.hextgen.com/" &&
								window?.location?.href !== "https://www.hextgen.com" &&
								window?.location?.href !== "http://localhost:3000/" &&
								window?.location?.href !== "http://localhost:3000" &&
								!window?.location?.href?.includes("/#ourServices") &&
								!window?.location?.href?.includes("/#products") &&
								!window?.location?.href?.includes("/#contactUs") &&
								!window?.location?.href?.includes("/home") && <Footer />}
						</div>
					</div>
				</div>
			</Router>
		</div>
	);
}

export default App;
