import { createSlice } from "@reduxjs/toolkit";

const paymentStatusvisitedPatientsReducer = createSlice({
	name: "paymentStatusvisitedPatients",
	initialState: {},
	reducers: {
		paymentStatusvisitedPatientsRequest: (state) => {
			state.loading = true;
		},
		paymentStatusvisitedPatientsSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.error = false;
			state.success = true;
		},
		paymentStatusvisitedPatientsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		paymentStatusvisitedPatientsReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	paymentStatusvisitedPatientsRequest,
	paymentStatusvisitedPatientsSuccess,
	paymentStatusvisitedPatientsFailure,
	paymentStatusvisitedPatientsReset,
} = paymentStatusvisitedPatientsReducer.actions;
export default paymentStatusvisitedPatientsReducer.reducer;
