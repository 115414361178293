import { createSlice } from "@reduxjs/toolkit";

const getRevenueForYearReducer = createSlice({
	name: "getrevenueforyear",
	initialState: {},
	reducers: {
		getRevenueForYearRequest: (state) => {
			state.loading = true;
		},
		getRevenueForYearSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.yearlyCounter = action.payload;
			state.error = false;
			state.success = true;
		},
		getRevenueForYearFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getRevenueForYearReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getRevenueForYearRequest,
	getRevenueForYearSuccess,
	getRevenueForYearFailure,
	getRevenueForYearReset,
} = getRevenueForYearReducer.actions;
export default getRevenueForYearReducer.reducer;
