import { createSlice } from "@reduxjs/toolkit";

const getRegisteredPatientsReducer = createSlice({
	name: "getRegisteredPatients",
	initialState: {},
	reducers: {
		getRegisteredPatientsRequest: (state) => {
			state.loading = true;
		},
		getRegisteredPatientsSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getRegisteredPatients = action.payload;
			state.error = false;
			state.success = true;
		},
		getRegisteredPatientsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getRegisteredPatientsReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getRegisteredPatientsRequest,
	getRegisteredPatientsSuccess,
	getRegisteredPatientsFailure,
	getRegisteredPatientsReset,
} = getRegisteredPatientsReducer.actions;
export default getRegisteredPatientsReducer.reducer;
