/** @format */

import { createSlice } from "@reduxjs/toolkit";

const scheduleAppointmentDateByDoctor = createSlice({
	name: "prescriptionInfo",
	initialState: {},
	reducers: {
		scheduleAppointmentDateByDoctorStart: (state) => {
			state.loading = true;
		},
		scheduleAppointmentDateByDoctorSuccess: (state, action) => {
			state.loading = false;
			state.scheduleAppointmentDateByDoctor = action.payload;
			state.error = false;
			state.success = true;
		},
		scheduleAppointmentDateByDoctorFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		scheduleAppointmentDateByDoctorReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	scheduleAppointmentDateByDoctorStart,
	scheduleAppointmentDateByDoctorSuccess,
	scheduleAppointmentDateByDoctorFailure,
	scheduleAppointmentDateByDoctorReset,
} = scheduleAppointmentDateByDoctor.actions;
export default scheduleAppointmentDateByDoctor.reducer;
