/** @format */

import { createSlice } from "@reduxjs/toolkit";

const get_scheduleAppointments_To_Doctor_Reception = createSlice({
  name: "prescriptionInfo",
  initialState: {},
  reducers: {
    get_scheduleAppointments_To_Doctor_ReceptionStart: (state) => {
      state.loading = true;
    },
    get_scheduleAppointments_To_Doctor_ReceptionSuccess: (state, action) => {
      state.loading = false;
      state.get_scheduleAppointments_To_Doctor_Reception1 = action.payload;
      state.error = false;
      state.success = true;
    },
    get_scheduleAppointments_To_Doctor_ReceptionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    get_scheduleAppointments_To_Doctor_ReceptionReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  get_scheduleAppointments_To_Doctor_ReceptionStart,
  get_scheduleAppointments_To_Doctor_ReceptionSuccess,
  get_scheduleAppointments_To_Doctor_ReceptionFailure,
  get_scheduleAppointments_To_Doctor_ReceptionReset,
} = get_scheduleAppointments_To_Doctor_Reception.actions;
export default get_scheduleAppointments_To_Doctor_Reception.reducer;
