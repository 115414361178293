/** @format */

import { createSlice } from "@reduxjs/toolkit";
const doctorItemsFromStorage = JSON.parse(localStorage.getItem("persist:root"))
	? JSON.parse(localStorage.getItem("persist:root"))?.doctorLogin
	: {};

const doctor = createSlice({
	name: "doctor",
	initialState: doctorItemsFromStorage,
	reducers: {
		loginStart: (state) => {
			state.loading = true;
		},
		loginSuccess: (state, action) => {
			state.loading = false;
			state.doctorInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		loginFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		loginDoctorReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
		logout: (state) => {
			state.doctorInfo = {};
		},
	},
});

export const {
	loginStart,
	loginSuccess,
	loginFailure,
	logout,
	loginDoctorReset,
} = doctor.actions;
export default doctor.reducer;
