/** @format */
import Message from "../../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, useLocation } from "react-router-dom";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";

// import Accordion from 'react-bootstrap/Accordion';

import Form from "react-bootstrap/Form";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Table from "react-bootstrap/Table";
//API calls
import {
	deleteTemplate,
	getTemplateNames,
} from "../../../redux/doctorRedux/doctorApiCalls";
//Reset calls
//Template reset calls
import { getTemplateReset } from "../../../redux/doctorRedux/Template_Management/getTemplate";
import { deleteTemplateReset } from "../../../redux/doctorRedux/Template_Management/deleteTemplate";
import { getTemplateNamesReset } from "../../../redux/doctorRedux/Template_Management/getTemplateNames";

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../../redux/allMessages";
import { useParams } from "react-router-dom";

//Here we create child for Templates management

const DeleteTemplateScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	// success and failure Message
	const getTemplateNamesData = useSelector((state) => state.getTemplateNames);
	const templatenames = getTemplateNamesData.templatenames;
	const templatenamessuccess = getTemplateNamesData.success;
	const templatenameserror = getTemplateNamesData.error;
	const templatenamesloading = getTemplateNamesData.loading;

	const gettemplatesData = useSelector((state) => state.getTemplate);
	const templates = gettemplatesData;
	const getTemplatessuccess = gettemplatesData.success;
	const getTemplateserror = gettemplatesData.error;
	const getTemplatesloading = gettemplatesData.loading;

	const deleteTemplateData = useSelector((state) => state.deleteTemplate);
	const deleteTemplatesuccess = deleteTemplateData.success;
	const deleteTemplateerror = deleteTemplateData.error;
	const deleteTemplateloading = deleteTemplateData.loading;

	/////////////////////////////////////////////////////

	const doctorLogin = useSelector((state) => state.doctorLogin);
	const { doctorInfo } = doctorLogin;

	console.log(doctorInfo?.user?._id);

	useEffect(() => {
		dispatch(getTemplateNames(doctorInfo?.user?._id, clinicName));
	}, []);

	useEffect(() => {
		dispatch(getTemplateNames(doctorInfo?.user?._id, clinicName));
	}, [deleteTemplatesuccess, deleteTemplateerror, deleteTemplateloading]);

	//Delete Template

	const handleDeleteTemplate = (templateId) => {
		dispatch(deleteTemplate(doctorInfo?.user?._id, templateId, clinicName));
	};
	//ALerts//////////////////////////////////////////////////////////////////

	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const alertmsg = useSelector((state) => state.allMessages);

	const [showPopup4, setShowPopup4] = React.useState(false);
	const toggleShowInfoPopup4 = (e) => {
		setShowPopup4(!showPopup4);
		e.preventDefault();
		console.log("error");
		dispatch(getTemplateReset());
	};
	const [showPopup5, setShowPopup5] = React.useState(false);
	const toggleShowInfoPopup5 = (e) => {
		setShowPopup5(!showPopup5);
		e.preventDefault();
		console.log("error");
		dispatch(getTemplateNamesReset());
	};

	const [showPopup7, setShowPopup7] = React.useState(false);
	const toggleShowInfoPopup7 = (e) => {
		setShowPopup7(!showPopup7);
		e.preventDefault();
		console.log("error");
		dispatch(deleteTemplateReset());
	};

	useEffect(() => {
		if (deleteTemplatesuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(deleteTemplateReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [deleteTemplatesuccess, dispatch]);

	return (
		<div className="pageSize">
			<Meta />

			{getTemplateserror && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup4}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{getTemplateserror}
				</Message>
			)}
			{templatenameserror && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup5}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{templatenameserror}
				</Message>
			)}

			{deleteTemplateerror && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup7}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{deleteTemplateerror}
				</Message>
			)}

			{(templatenamesloading ||
				getTemplatesloading ||
				deleteTemplateloading) && <Loader />}

			<div>
				<h2 className="header-center mt-3 mb-3">Delete Template</h2>
				<Container className="col-8  header-center">
					<Table striped bordered hover className="custom-table">
						<thead>
							<tr>
								<th scope="col">S.No</th>
								<th scope="col">Template Name</th>
								<th scope="col">Operations</th>
							</tr>
						</thead>
						<tbody>
							{templatenames?.data?.data ? (
								templatenames?.data?.data.map((name, index) => (
									<tr>
										<th scope="row">{index + 1}</th>
										<td>{name}</td>
										<td>
											<button className="m-2 btn btn-danger">
												<i
													className="fa fa-trash"
													onClick={() => handleDeleteTemplate(index)}
												></i>
											</button>
										</td>
									</tr>
								))
							) : (
								<div></div>
							)}
						</tbody>
					</Table>
				</Container>
			</div>
		</div>
	);
};

export default DeleteTemplateScreen;
