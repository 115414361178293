import { React, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Col, Row, Container } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { get_scheduleAppointments_To_Doctor_Reception } from "../../redux/prescriptionRedux/prescriptionApiCalls";
import Meta from "../../Components/Meta";
import { useParams } from "react-router-dom";
const ScheduledAppointments = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { clinicName } = useParams();
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const doctorAppointmentDates = receptionInfo?.user?.appointmentDates;
	const [selectDate, setSelectDate] = useState("Choose Date");
	const [dateOptionNumeric, setDateOptionNumeric] = useState("Choose Date");
	const [selectedOrNot, setSelectedOrNot] = useState(false);
	const handleSelection = (eventKey) => {
		const dateString = doctorAppointmentDates[eventKey].date.toString();
		setDateOptionNumeric(doctorAppointmentDates[eventKey].date);
		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ("0" + dateString.slice(6, 8)).slice(-2);
		setSelectDate(`${day}-${month}-${year}`);
		setSelectedOrNot(false);
	};
	const searchForScheduled = () => {
		if (selectDate === "Choose Date") {
			alert("first choose date");
		} else {
			setSelectedOrNot(true);

			dispatch(
				get_scheduleAppointments_To_Doctor_Reception(
					dateOptionNumeric,
					clinicName
				)
			);
		}
	};
	const { get_scheduleAppointments_To_Doctor_Reception1 } = useSelector(
		(state) => state.getScheduleAppointmentsForNotificationToDoctorReception
	);
	console.log("line29");
	console.log(get_scheduleAppointments_To_Doctor_Reception1);
	var days = [];

	const formattedDates = doctorAppointmentDates?.map((entry) => {
		const dateString = entry.date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ("0" + dateString.slice(6, 8)).slice(-2);

		// Create the formatted date string

		days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
	});
	return (
		<>
			<h1 className="header-center">Scheduled Appointments</h1>
			<div className="pageSize">
				<Meta />
				<Container className="header-center mt-4 mb-5">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center mt-1">
							<h6>
								Select the filters{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<DropdownButton
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={selectDate}
								onSelect={handleSelection}
							>
								{" "}
								<div className="dropScroll">
									{days?.map((day, index) => (
										<Dropdown.Item eventKey={index}>
											{day?.currDay}
										</Dropdown.Item>
									))}
								</div>
							</DropdownButton>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<Button
								id="searchButton1"
								className="btn-primary"
								onClick={searchForScheduled}
							>
								Search
							</Button>
						</Col>
					</Row>
				</Container>
				<div className="container">
					{selectedOrNot && (
						<div className="scheduled-table">
							{get_scheduleAppointments_To_Doctor_Reception1 !==
							"No appointments" ? (
								<Table striped bordered hover className="custom-table">
									<thead>
										<tr>
											<th>S.no</th>
											<th>Patient name</th>
											<th>Mobile number</th>
											<th>Doctor name</th>
											<th>Message for visit</th>
										</tr>
									</thead>
									<tbody>
										{get_scheduleAppointments_To_Doctor_Reception1?.scheduledAppointments?.map(
											({ mobilenumber, doctorName, message, user }, sno) => (
												<tr>
													<td>{sno + 1}</td>
													<td>{user}</td>
													<td>{mobilenumber}</td>
													<td>{doctorName}</td>
													<td>{message}</td>
												</tr>
											)
										)}
									</tbody>
								</Table>
							) : (
								<h4 className="header-center">
									No scheduled appointments for the selected date
								</h4>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default ScheduledAppointments;
