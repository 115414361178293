/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import SearchPatient from "../../Components/SearchPatients";
import {
	getPatientsInBlockedSlot,
	rescheduleAppointmentForPatientinBlockedSlot,
} from "../../redux/receptionRedux/receptionApiCalls";
import { rescheduleAppointmentForPatientinBlockedSlotReset } from "../../redux/receptionRedux/Appointment_Management/rescheduleAppointmentForPatientinBlockedSlot.js";
import { getTimeslots } from "../../redux/userApiCalls";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { SERVER_URL } from "../../App.js";
import { useParams } from "react-router-dom";
import axios from "axios";
import { editClinicMetadata } from "../../redux/doctorRedux/doctorApiCalls.js";

const MyClinicScreen = () => {
	const dispatch = useDispatch();
	const { clinicName } = useParams();
	// Accordian Collapse
	const [open, setOpen] = useState([]);

	const [clinicAddress, setClinicAddress] = useState("");
	const [clinicLocation, setClinicLocation] = useState("");
	const [clinicSummary, setClinicSummary] = useState("");
	const [contactNumber, setContactNumber] = useState("");
	const [services, setServices] = useState([]);

	const handleImageChange = (event, item) => {
		if (item === "logo") {
			const selectedFile = event.target.files[0];
			setlogoUploaded(true);
			handleUpload(selectedFile);
		} else {
			const selectedFiles = Array.from(event.target.files);
			if (selectedFiles.length > 5) {
				alert("Only 5 images to upload are allowed.");
				// Clear the selected files
				event.target.value = null;
			} else {
				setimageUploaded(true);
				handleMultipleUpload(selectedFiles);
			}
		}
	};

	const handleServicesChange = (event) => {
		const { value } = event.target;
		setServices(value.split(",").map((service) => service.trim()));
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		// Handle form submission

		dispatch(
			editClinicMetadata(
				logoUrl,
				clinicAddress,
				clinicLocation,
				clinicSummary,
				contactNumber,
				imageUrl,
				services,
				clinicName
			)
		);
		setLogoUrl({});
		setImageUrl([]);
		setlogoUploaded(false);
		setimageUploaded(false);
	};

	const [logoUrl, setLogoUrl] = useState({});
	const [logoUploaded, setlogoUploaded] = useState(false);

	const handleUpload = async (file) => {
		debugger;
		const formData = new FormData();
		formData.append("file", file);

		try {
			const response = await axios.post(
				`${SERVER_URL}/api/v1/imageUpload/`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			debugger;
			console.log(response.data.data.data);
			setLogoUrl(response.data.data.data);
			setlogoUploaded(false);
		} catch (error) {
			console.error("Upload failed:", error);
		}
	};

	const [imageUrl, setImageUrl] = useState([]);
	const [imageUploaded, setimageUploaded] = useState(false);

	const handleMultipleUpload = async (file) => {
		const formData = new FormData();

		for (let i = 0; i < file.length; i++) {
			formData.append("files", file[i]);
		}

		try {
			const response = await axios.post(
				`${SERVER_URL}/api/v1/imageUpload/clinicImagesUpload/`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			debugger;
			console.log(response.data.data.images);
			setImageUrl(response.data.data.images);
			setimageUploaded(false);
		} catch (error) {
			console.error("Upload failed:", error);
		}
	};
	console.log(imageUrl, logoUrl);

	return (
		<div className="pageSize">
			<h3 className="header-center">My Clinic</h3>
			<div className="card col-md-6 offset-3 mt-5">
				<Form onSubmit={handleSubmit} className="mt-3 mb-3">
					<Form.Group className="mt-3" controlId="clinicLogo">
						<Form.Label>
							<b>Clinic Logo</b>
						</Form.Label>
						<Form.Control
							type="file"
							accept="image/*"
							onChange={(e) => handleImageChange(e, "logo")}
						/>
					</Form.Group>
					{logoUrl && logoUploaded && <img src={logoUrl} />}
					<Form.Group className="mt-3" controlId="clinicAddress">
						<Form.Label>
							<b>Clinic Address</b>
						</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							value={clinicAddress}
							onChange={(e) => setClinicAddress(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className="mt-3" controlId="clinicLocation">
						<Form.Label>
							<b>Clinic Location</b>
						</Form.Label>
						<Form.Control
							type="text"
							value={clinicLocation}
							onChange={(e) => setClinicLocation(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className="mt-3" controlId="clinicSummary">
						<Form.Label>
							<b>About Clinic and Doctor</b>
						</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							value={clinicSummary}
							onChange={(e) => setClinicSummary(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className="mt-3" controlId="contactNumber">
						<Form.Label>
							<b>Contact Number</b>
						</Form.Label>
						<Form.Control
							type="number"
							pattern="[0-9]{10}"
							maxLength={10}
							value={contactNumber}
							onChange={(e) => setContactNumber(e.target.value)}
							required
						/>
					</Form.Group>

					<Form.Group className="mt-3" controlId="services">
						<Form.Label>
							<b>Services</b>
						</Form.Label>
						<Form.Control
							type="text"
							value={services.join(", ")}
							onChange={handleServicesChange}
							required
						/>
					</Form.Group>

					<Form.Group className="mt-3" controlId="images">
						<Form.Label>
							<b>Images (Max 5)</b>
						</Form.Label>
						<Form.Control
							type="file"
							accept="image/*"
							onChange={(e) => handleImageChange(e, "images")}
							multiple
						/>
					</Form.Group>
					<div className="text-center">
						<Button
							variant="primary"
							type="submit"
							className="mt-3"
							disabled={imageUploaded || logoUploaded}
						>
							Submit
						</Button>
					</div>
				</Form>
			</div>
		</div>
	);
};

export default MyClinicScreen;
