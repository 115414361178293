import { createSlice } from "@reduxjs/toolkit";

const deleteLeavesReducer = createSlice({
	name: "deleteLeaves",
	initialState: {},
	reducers: {
		deleteLeavesRequest: (state) => {
			state.loading = true;
		},
		deleteLeavesSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.deleteLeaves = action.payload;
			state.error = false;
			state.success = true;
		},
		deleteLeavesFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		deleteLeavesReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	deleteLeavesRequest,
	deleteLeavesSuccess,
	deleteLeavesFailure,
	deleteLeavesReset,
} = deleteLeavesReducer.actions;
export default deleteLeavesReducer.reducer;
