import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Loader from "../Components/Loader";
import Meta from "../Components/Meta";
import ProductCarousel from "../Components/ProductCarousel";
import AboutUs from "./AboutUs";
import Button from "react-bootstrap/Button";
import ViewPrescription from "../Components/ViewPrescription";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { useLocation } from "react-router-dom";
const HomeScreens = () => {
	const loading = 0;
	const [showPopup1, setShowPopup1] = React.useState(false);
	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log("error");
	};
	const today = new Date().toISOString().split("T")[0];
	const location = useLocation();
	const { pathname } = location;

	const currentURL = pathname;
	const pattern = /\/clinic\/([^/]+)/;
	const match = currentURL.match(pattern);
	var clinicName;
	if (match) {
		clinicName = match[1];
		console.log(clinicName); // Output: Clinic1
	} else {
		console.log("Clinic name not found in the URL");
	}
	return (
		<>
			<Meta />
			<Navbar className="bg-body-tertiary">
				<Container>
					<Navbar.Brand href="#home">
						<img
							alt=""
							src="/bhuvanslogo.png"
							width="70"
							height="60"
							className="d-inline-block align-top"
						/>{" "}
					</Navbar.Brand>
					<Form className="d-flex">
						<a
							className="btn btn-outline-success"
							href={`/clinic/${clinicName}/login`}
						>
							Login
						</a>
					</Form>
				</Container>
			</Navbar>
			<div className="bg-home-color" id="aboutUs">
				<Row className="justify-content-center">
					<h1 className="mb-5 mt-5 text-white">
						Welcome to Bhuvan's Homeopathy Clinic
					</h1>
				</Row>
			</div>
			<AboutUs />
		</>
	);
};

export default HomeScreens;
