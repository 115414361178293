/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import SearchPatient from "../../Components/SearchPatients";

const SearchPatientScreens = () => {
  const dispatch = useDispatch();
  return (
    <>
          <Meta />
      <SearchPatient usertype="doctor" />
    </>
  );
};

export default SearchPatientScreens;
