import { createSlice } from "@reduxjs/toolkit";

const editReceptionReducer = createSlice({
	name: "editReception",
	initialState: {},
	reducers: {
		editReceptionRequest: (state) => {
			state.loading = true;
		},
		editReceptionSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.error = false;
			state.success = true;
		},
		editReceptionFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editReceptionReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	editReceptionRequest,
	editReceptionSuccess,
	editReceptionFailure,
	editReceptionReset,
} = editReceptionReducer.actions;
export default editReceptionReducer.reducer;
