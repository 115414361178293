import React, { useState, useEffect } from "react";
import Chart from "../../Components/Revenue_Components/Chart.js";
import FeaturedInfo from "../../Components/Revenue_Components/FeaturedInfoPatientCount.js";
import Meta from "../../Components/Meta.js";
import "./home.css";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../Components/Message.js";
import Loader from "../../Components/Loader.js";
import { getRevenueForParticularYearReset } from "../../redux/doctorRedux/MyProfile_Management/getRevenueForParticularYear.js";
import { getRevenueForYearReset } from "../../redux/doctorRedux/MyProfile_Management/getRevenueForYear.js";
import { getDoctorVisitedPatientsforParticularDateReset } from "../../redux/adminRedux/Doctor_Management/getDoctorVisitedPatientsforParticularDate.js";
import {
	getRevenueForYear,
	getRevenueForParticularYear,
} from "../../redux/doctorRedux/doctorApiCalls.js";
import { getDoctorVisitedPatientsforParticularDate } from "../../redux/adminRedux/adminApiCalls.js";
import { Col, Row, Container } from "react-bootstrap";
import PieChart from "../../Components/PieChart.js";
import BarChart from "../../Components/BarChart.js";
import { useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";

const PatientStatisticsScreens = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { clinicName } = useParams();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);

	const getRevenueForYearData = useSelector((state) => state.getRevenueForYear);
	const getRevenueForYearDataloading = getRevenueForYearData.loading;

	const getDoctorVisitedPatientsforParticularDateData = useSelector(
		(state) => state.getDoctorVisitedPatientsforParticularDate
	);

	const getDoctorVisitedPatientsforParticularDateDataloading =
		getDoctorVisitedPatientsforParticularDateData.loading;

	console.log(getRevenueForYearData);
	console.log(getDoctorVisitedPatientsforParticularDateData);

	useEffect(() => {
		const year = new Date().getFullYear();
		const month = new Date().getMonth() + 1;
		const day = new Date().getDate();

		const numericDate = year * 10000 + month * 100 + day;
		dispatch(getRevenueForYear(doctorInfo?.user?._id, 2024, clinicName));
		dispatch(
			getDoctorVisitedPatientsforParticularDate(
				doctorInfo?.user?._id,
				parseInt(numericDate),
				clinicName
			)
		);
	}, []);

	const [last30DaysBar, setLast30DaysBar] = useState([]);
	const [last12MonsBar, setLast12MonsBar] = useState([]);
	const [activeBar, setActiveBar] = useState("30days");

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	useEffect(() => {
		let last30days = [];
		let last12mons = [];
		getRevenueForYearData?.yearlyCounter?.last_thirtydays?.map((day, index) => {
			let datearr = day?.date?.toString()?.split("");
			let date =
				datearr[6] +
				datearr[7] +
				"-" +
				datearr[4] +
				datearr[5] +
				"-" +
				datearr[0] +
				datearr[1] +
				datearr[2] +
				datearr[3];
			last30days?.push({
				Date: date,
				Amount: day?.opPatientsCount,
			});
		});
		getRevenueForYearData?.yearlyCounter?.last_twelve_months?.map(
			(mon, index) => {
				last12mons?.push({
					Date: months[mon?.monthNumber - 1],
					Amount: mon?.opPatientsCount,
				});
			}
		);
		setLast30DaysBar(last30days);
		setLast12MonsBar(last12mons);
	}, [getRevenueForYearDataloading]);

	console.log(last30DaysBar);

	const lastDay =
		getRevenueForYearData?.yearlyCounter?.last_thirtydays.length > 0
			? getRevenueForYearData?.yearlyCounter?.last_thirtydays[
					getRevenueForYearData?.yearlyCounter?.last_thirtydays.length - 1
			  ]
			: 0;

	const calculateTodaysStatistics = (visitedPatientsData, lastDayData) => {
		console.log(visitedPatientsData);
		if (!visitedPatientsData || visitedPatientsData.length === 0) {
			return [0, 0, 0, 0, 0, 0, 0, 0];
		}

		if (lastDayData === 0) {
			return [visitedPatientsData.length, 0];
		} else {
			return [visitedPatientsData.length, lastDayData.opPatientsCount];
		}
	};

	const statisticsToday = calculateTodaysStatistics(
		getDoctorVisitedPatientsforParticularDateData
			?.getDoctorVisitedPatientsforParticularDate?.data?.data?.visited_patients,
		lastDay
	);

	return (
		<>
			<Meta />
			<h1 className="header-center">Patients Count Analytics</h1>
			<FeaturedInfo statistics={statisticsToday} />

			<Container className="header-center mt-5">
				<button
					className={
						activeBar === "30days"
							? "btn btn-info m-1"
							: "btn btn-outline-info m-1"
					}
					onClick={() => setActiveBar("30days")}
				>
					Last 30 days
				</button>
				<button
					className={
						activeBar === "12mons"
							? "btn btn-info m-1"
							: "btn btn-outline-info m-1"
					}
					onClick={() => setActiveBar("12mons")}
				>
					Last 12 Months
				</button>

				{activeBar === "30days" && <BarChart data={last30DaysBar} />}
				{activeBar === "12mons" && <BarChart data={last12MonsBar} />}
			</Container>
		</>
	);
};

export default PatientStatisticsScreens;
