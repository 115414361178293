/** @format */

import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Carousel, Image } from "react-bootstrap";
import Loader from "./Loader";

// import * as am5 from '@amcharts/amcharts5';
// import * as am5xy from '@amcharts/amcharts5/xy';
// import * as am5percent from '@amcharts/amcharts5/percent';
// import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

import { ResponsiveBar } from "@nivo/bar";

const BarChart = ({ data }) => {
	// const chartRef = useRef(null);

	// useEffect(() => {
	// 	// Create root element
	// 	let root = am5.Root.new(chartRef.current);

	// 	// Set themes
	// 	root.setThemes([am5themes_Animated.new(root)]);

	// 	// Create chart
	// 	let chart = root.container.children.push(
	// 		am5xy.XYChart.new(root, {
	// 			panX: false,
	// 			panY: false,
	// 			wheelX: 'panX',
	// 			wheelY: 'zoomX',
	// 		})
	// 	);

	// 	// Add X-Axis
	// 	let xAxis = chart.xAxes.push(
	// 		am5xy.CategoryAxis.new(root, {
	// 			categoryField: 'category',
	// 			renderer: am5xy.AxisRendererX.new(root, {}),
	// 			tooltip: am5.Tooltip.new(root, {}),
	// 		})
	// 	);

	// 	// Add Y-Axis
	// 	let yAxis = chart.yAxes.push(
	// 		am5xy.ValueAxis.new(root, {
	// 			renderer: am5xy.AxisRendererY.new(root, {}),
	// 		})
	// 	);

	// 	// Create series
	// 	let series = chart.series.push(
	// 		am5xy.ColumnSeries.new(root, {
	// 			name: 'Series 1',
	// 			xAxis: xAxis,
	// 			yAxis: yAxis,
	// 			valueYField: 'value',
	// 			sequencedInterpolation: true,
	// 			categoryXField: 'category',
	// 			tooltip: am5.Tooltip.new(root, {
	// 				labelText: '{valueY}',
	// 			}),
	// 		})
	// 	);

	// 	series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5 });
	// 	series.data.setAll(data);

	// 	// Set data
	// 	xAxis.data.setAll(data);

	// 	// Make stuff animate on load
	// 	series.appear(1000);
	// 	chart.appear(1000, 100);

	// 	return () => {
	// 		root.dispose();
	// 	};
	// }, [data]); // Dependency array to re-create the chart if data changes
	// // Re-run this effect if the data prop changes

	return (
		// <div
		// 	ref={chartRef}
		// 	className='header-center offset-md-1'
		// 	style={{ width: '80%', height: '500px' }}></div>
		<div className="header-center" style={{ width: "100%", height: "500px" }}>
			<ResponsiveBar
				data={data}
				enableLabel={false}
				keys={["Amount"]}
				indexBy="Date"
				margin={{ top: 50, right: 130, bottom: 100, left: 100 }}
				padding={0.3}
				layout="vertical"
				colors={{ scheme: "category10" }}
				animate={true} // Enable animation
				motionStiffness={90} // Controls the stiffness of the animation
				motionDamping={15} // Controls the damping effect of the animation
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 90,
					legend: "Date",
					legendPosition: "middle",
					legendOffset: 90,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 0,
					tickRotation: 0,
					legend: "Amount",
					legendPosition: "middle",
					legendOffset: -50,
				}}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
				legends={[
					{
						dataFrom: "keys",
						anchor: "bottom-right",
						direction: "column",
						justify: false,
						translateX: 120,
						translateY: 0,
						itemsSpacing: 2,
						itemWidth: 100,
						itemHeight: 20,
						itemDirection: "left-to-right",
						itemOpacity: 0.85,
						symbolSize: 20,
						effects: [
							{
								on: "hover",
								style: {
									itemOpacity: 1,
								},
							},
						],
					},
				]}
			/>
		</div>
	);
};

export default BarChart;
