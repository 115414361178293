import React, { useState, useEffect } from "react";
import "../../Components/Revenue_Components/chart.css";
import Chart from "../../Components/Revenue_Components/Chart";
import FeaturedInfo from "../../Components/Revenue_Components/FeaturedInfo";
import "./home.css";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../Components/Message";
import Loader from "../../Components/Loader";
import { getRevenueForParticularYearReset } from "../../redux/doctorRedux/MyProfile_Management/getRevenueForParticularYear.js";
import { getRevenueForYearReset } from "../../redux/doctorRedux/MyProfile_Management/getRevenueForYear.js";
import {
	getRevenueForYear,
	getRevenueForParticularYear,
} from "../../redux/doctorRedux/doctorApiCalls";
import { Col, Row, Container } from "react-bootstrap";
import PieChart from "../../Components/PieChart.js";
import BarChart from "../../Components/BarChart.js";
import { getDoctorVisitedPatientsforParticularDate } from "../../redux/adminRedux/adminApiCalls";
import { getDoctorVisitedPatientsforParticularDateReset } from "../../redux/adminRedux/Doctor_Management/getDoctorVisitedPatientsforParticularDate.js";
import { useParams } from "react-router-dom";

const calculatePercentageChange = (currentValue, previousValue) => {
	if (previousValue === 0 || currentValue === 0) {
		return 0; // Avoid division by zero
	}
	var max = Math.max(currentValue, previousValue);
	var min = Math.min(currentValue, previousValue);
	return Math.round(((max - min) / max) * 100)?.toString() + "%";
};

const calculateTodaysStatistics = (visitedPatientsData, lastDayData) => {
	let cashAmount = 0;
	let onlineAmount = 0;
	if (!visitedPatientsData || visitedPatientsData.length === 0) {
		return [0, 0, 0, 0, 0, 0, 0, 0];
	}
	visitedPatientsData.forEach((appointment) => {
		if (appointment.hasPaid) {
			if (appointment.modeOfPayment === "Cash") {
				cashAmount += appointment.paidAmount;
			} else if (appointment.modeOfPayment === "Online") {
				onlineAmount += appointment.paidAmount;
			}
		}
	});
	if (lastDayData === 0) {
		return [
			visitedPatientsData.length,
			cashAmount + onlineAmount,
			cashAmount,
			onlineAmount,
			0,
			0,
			0,
			0,
		];
	} else {
		const a = calculatePercentageChange(
			visitedPatientsData.length,
			lastDayData.opPatientsCount
		);
		const x = calculatePercentageChange(
			cashAmount + onlineAmount,
			lastDayData.opPatientsRevenueCash + lastDayData.opPatientsRevenueOnline
		);
		const y = calculatePercentageChange(
			cashAmount,
			lastDayData.opPatientsRevenueCash
		);
		const z = calculatePercentageChange(
			onlineAmount,
			lastDayData.opPatientsRevenueOnline
		);

		return [
			visitedPatientsData.length,
			cashAmount + onlineAmount,
			cashAmount,
			onlineAmount,
			a,
			x,
			y,
			z,
		];
	}
};

export default function HospitalStatistics() {
	const dispatch = useDispatch();
	const { clinicName } = useParams();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);

	const getRevenueForYearData = useSelector((state) => state.getRevenueForYear);
	const getRevenueForYearDataloading = getRevenueForYearData.loading;

	const getDoctorVisitedPatientsforParticularDateData = useSelector(
		(state) => state.getDoctorVisitedPatientsforParticularDate
	);

	const getDoctorVisitedPatientsforParticularDateDataloading =
		getDoctorVisitedPatientsforParticularDateData.loading;

	console.log(getRevenueForYearData);
	console.log(getDoctorVisitedPatientsforParticularDateData);

	const lastDay =
		getRevenueForYearData?.yearlyCounter?.last_thirtydays.length > 0
			? getRevenueForYearData?.yearlyCounter?.last_thirtydays[
					getRevenueForYearData?.yearlyCounter?.last_thirtydays.length - 1
			  ]
			: 0;

	const statisticsToday = calculateTodaysStatistics(
		getDoctorVisitedPatientsforParticularDateData
			?.getDoctorVisitedPatientsforParticularDate?.data?.data?.visited_patients,
		lastDay
	);

	useEffect(() => {
		const year = new Date().getFullYear();
		const month = new Date().getMonth() + 1;
		const day = new Date().getDate();

		const numericDate = year * 10000 + month * 100 + day;
		dispatch(getRevenueForYear(doctorInfo?.user?._id, 2024, clinicName));
		dispatch(
			getDoctorVisitedPatientsforParticularDate(
				doctorInfo?.user?._id,
				parseInt(numericDate),
				clinicName
			)
		);
	}, []);

	const [last30DaysBar, setLast30DaysBar] = useState([]);
	const [last12MonsBar, setLast12MonsBar] = useState([]);
	const [activeBar, setActiveBar] = useState("30days");

	const months = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];

	useEffect(() => {
		let last30days = [];
		let last12mons = [];
		getRevenueForYearData?.yearlyCounter?.last_thirtydays?.map((day, index) => {
			let datearr = day?.date?.toString()?.split("");
			let date =
				datearr[6] +
				datearr[7] +
				" - " +
				datearr[4] +
				datearr[5] +
				" - " +
				datearr[0] +
				datearr[1] +
				datearr[2] +
				datearr[3];
			last30days?.push({
				Date: date,
				Amount:
					parseInt(day?.opPatientsRevenueCash) +
					parseInt(day?.opPatientsRevenueOnline),
			});
		});
		getRevenueForYearData?.yearlyCounter?.last_twelve_months?.map(
			(mon, index) => {
				last12mons?.push({
					Date: months[mon?.monthNumber - 1],
					Amount:
						parseInt(mon?.opPatientsRevenueCash) +
						parseInt(mon?.opPatientsRevenueOnline),
				});
			}
		);
		setLast30DaysBar(last30days);
		setLast12MonsBar(last12mons);
	}, [getRevenueForYearDataloading]);

	return (
		<div className="home">
			{(getRevenueForYearDataloading ||
				getDoctorVisitedPatientsforParticularDateDataloading) && <Loader />}

			<h1 className="offset-md-5 offset-2 mb-4">Revenue Analytics</h1>
			<FeaturedInfo statistics={statisticsToday} />
			<Container className="header-center mt-5">
				<button
					className={
						activeBar === "30days"
							? "btn btn-info m-1"
							: "btn btn-outline-info m-1"
					}
					onClick={() => setActiveBar("30days")}
				>
					Last 30 days
				</button>
				<button
					className={
						activeBar === "12mons"
							? "btn btn-info m-1"
							: "btn btn-outline-info m-1"
					}
					onClick={() => setActiveBar("12mons")}
				>
					Last 12 Months
				</button>

				{activeBar === "30days" && <BarChart data={last30DaysBar} />}
				{activeBar === "12mons" && <BarChart data={last12MonsBar} />}
			</Container>
		</div>
	);
}
