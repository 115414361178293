/** @format */

import { createSlice } from "@reduxjs/toolkit";

const createReceptionist = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    createReceptionistStart: (state) => {
      state.loading = true;
    },
    createReceptionistSuccess: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
      state.error = false;
      state.success = true;
    },
    createReceptionistFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createReceptionistReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  createReceptionistStart,
  createReceptionistSuccess,
  createReceptionistFailure,
  createReceptionistReset,
} = createReceptionist.actions;
export default createReceptionist.reducer;
