/** @format */

import { Container, Card, Button } from 'react-bootstrap';
import '../styles/feature.css';

export default function Feature() {
	return (
		<>
			<Container className='mt-5' id='ourServices'>
				<h2 className='mb-3 pt-5'>OUR SERVICES</h2>
				<h3 className='header-center'>Clinic Management Software</h3>
				<div class='Fcontainer d-flex align-items-center flex-wrap'>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/appointment.png' alt='' />
								<h3 className='text-white header-center'>Appointments</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Set up timings without any hurdle
									</h6>
									<h6 class='text-white fs-5'>
										Manage patient appointments online from anywhere
									</h6>
									<h6 class='text-white fs-5'>
										Handle appointments for patients who are not in attendance
										by rescheduling them to a list of on-hold appointments.
									</h6>
									<h6 class='text-white fs-5'>
										Start utilise your time to maximum
									</h6>
									<h6 class='text-white fs-5'>
										Don’t let your patients miss follow ups
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/revenue1.png' alt='' />
								<h3 className='text-white header-center'>Revenue</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Revenue management helps track the income generated.
									</h6>
									<h6 class='text-white fs-5'>
										It also provides insights into the number of visited
										patients.
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/records.png' alt='' />
								<h3 className='text-white header-center'>Patients Records</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Keep a track of your patient visits & history; easily and
										digitally!
									</h6>
									<h6 class='text-white fs-5'>
										Printed prescriptions for easy understanding
									</h6>
									<h6 class='text-white fs-5'>
										schedule an appointment for future
									</h6>
									<h6 class='text-white fs-5'>Improved care</h6>
								</div>
							</div>
						</div>
					</div>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/safety.png' alt='' />
								<h3 className='text-white header-center'>Safe and Secure</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Confidentiality is priority! With the practice management
										software, data is safe and secure
									</h6>
									<h6 class='text-white fs-5'>
										We comply with the international data norms
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/bil.png' alt='' />
								<h3 className='text-white header-center'>Bills</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Save bills for future references
									</h6>
									<h6 class='text-white fs-5'>
										Let your patients claim all the bills
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/inventory.png' alt='' />
								<h3 className='text-white header-center'>Inventory</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Know all the information about your inventory
									</h6>
									<h6 class='text-white fs-5'>
										Get reminders about stock levels and refill
									</h6>
									<h6 class='text-white fs-5'>
										Know your revenues on the inventory sales
									</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
				<h4 className='header-center'>Multi Speciality Management Software</h4>
				<div class='Fcontainer d-flex align-items-center flex-wrap'>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/24hrs.png' alt='' />
								<h3 className='text-white header-center'>Manage OPD</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Organize your OPD into a paperless experience - from booking
										appointments, making payments, recording clinical data,
										getting prescription and lab reports.
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/lab.png' alt='' />
								<h3 className='text-white header-center'>Lab Management</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Provide a connected experience with integrated billing,
										sample collection, automated lab and electronic test
										reports.
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/records.png' alt='' />
								<h3 className='text-white header-center'>
									Medical/Health Records
								</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Keep a track of your patient visits & history; easily and
										digitally!
									</h6>
									<h6 class='text-white fs-5'>
										Printed prescriptions for easy understanding
									</h6>
									<h6 class='text-white fs-5'>
										schedule an appointment for future
									</h6>

									<h6 class='text-white fs-5'>Improved care</h6>
								</div>
							</div>
						</div>
					</div>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/appointment.png' alt='' />
								<h3 className='text-white header-center'>Appointments</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Set up timings without any hurdle.
									</h6>
									<h6 class='text-white fs-5'>
										Manage patient appointments online from anywhere.
									</h6>
									<h6 class='text-white fs-5'>
										Handle appointments for patients who are not in attendance
										by rescheduling them to a list of on-hold appointments.
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/safety.png' alt='' />
								<h3 className='text-white header-center'>Safe and Secure</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Confidentiality is priority! With the practice management
										software, data is safe and secure
									</h6>
									<h6 class='text-white fs-5'>
										We comply with the international data norms
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/revenue1.png' alt='' />
								<h3 className='text-white header-center'>Revenue</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Revenue management helps track the income generated from
										each speciality, labtests, referral amounts.
									</h6>
									<h6 class='text-white fs-5'>
										It also provides insights into the number of visited
										patients.
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/bil.png' alt='' />
								<h3 className='text-white header-center'>Bills</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Save bills for future references
									</h6>
									<h6 class='text-white fs-5'>
										Let your patients claim all the bills
									</h6>
								</div>
							</div>
						</div>
					</div>
					<div class='box'>
						<div class='Fbody'>
							<div
								class='imgContainer'
								style={{
									backgroundColor: '#3d84a8',
								}}>
								<img src='/inventory.png' alt='' />
								<h3 className='text-white header-center'>Inventory</h3>
							</div>
							<div class='content d-flex flex-column align-items-center justify-content-center'>
								<div>
									<h6 class='text-white fs-5'>
										Know all the information about your inventory
									</h6>
									<h6 class='text-white fs-5'>
										Get reminders about stock levels and refill
									</h6>
									<h6 class='text-white fs-5'>
										Know your revenues on the inventory sales
									</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
}
