/** @format */

import { createSlice } from "@reduxjs/toolkit";
const doctorItemsFromStorage = JSON.parse(localStorage.getItem("persist:root"))
	? JSON.parse(localStorage.getItem("persist:root"))?.doctorLogin
	: {};

const doctor = createSlice({
	name: "doctor",
	initialState: doctorItemsFromStorage,
	reducers: {
		verifyStart: (state) => {
			state.loading = true;
		},
		verifySuccess: (state, action) => {
			state.loading = false;
			state.doctorInfo = action.payload;
			console.log(action.payload);
			state.error = false;
			state.success = true;
			console.log(state.doctorInfo);
		},
		verifyFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		verifyCodeDoctorReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
		logout: (state) => {
			state.doctorInfo = {};
		},
	},
});

export const {
	verifyStart,
	verifySuccess,
	verifyFailure,
	logout,
	verifyCodeDoctorReset,
} = doctor.actions;
export default doctor.reducer;
