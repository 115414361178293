/** @format */

import Message from "../../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Pagination from "react-bootstrap/Pagination";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import {
  createLeave,
  deleteLeave,
  getLeave,
  blockSlot,
  unBlockSlot,
} from "../../../redux/doctorRedux/doctorApiCalls";
import { getTimeslots } from "../../../redux/userApiCalls";
import {
  allMessagesStart,
  allMessagesSuccess,
  allMessagesFailure,
  allMessagesReset,
} from "../../../redux/allMessages";

import { createLeaveReset } from "../../../redux/doctorRedux/Leave_Management/createLeave";
import { deleteLeavesReset } from "../../../redux/doctorRedux/Leave_Management/deleteLeave";
import { getLeavesReset } from "../../../redux/doctorRedux/Leave_Management/getLeaves";
import { blockSlotReset } from "../../../redux/doctorRedux/Slot_Management/blockSlots";
import { unBlockSlotReset } from "../../../redux/doctorRedux/Slot_Management/unBlockSlots";
import { TimeSlotGetReset } from "../../../redux/getTimeSlots";
import { useParams } from "react-router-dom";

const DoctorBlockSlotsScreen = () => {
  const { clinicName } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const doctorLogin = useSelector((state) => state.doctorLogin);
  const { doctorInfo } = doctorLogin;
  const doctorAppointmentDates = doctorInfo?.user?.appointmentDates;
  // success and failure Message
  const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
  const getTimeSlotssuccess = getTimeSlotsData.success;
  const getTimeSlotserror = getTimeSlotsData.error;
  const getTimeSlotsloading = getTimeSlotsData.loading;

  const blockSlotData = useSelector((state) => state.blockSlots);
  const blockSlotsuccess = blockSlotData.success;
  const blockSloterror = blockSlotData.error;
  const blockSlotloading = blockSlotData.loading;

  // Initialize state to hold the values of checked checkboxes
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedCheckboxesIds, setSelectedCheckboxesIds] = useState([]);
  //Block Slots
  const days = [];
  const { allTimeSlots } = useSelector((state) => state.getTimeSlots);
  const [timeSlots, settimeSlots] = useState([]);

  const formattedDates = doctorAppointmentDates?.map((entry) => {
    const dateString = entry.date.toString();

    // Extract year, month, and day
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = ("0" + dateString.slice(6, 8)).slice(-2);

    // Create the formatted date string

    days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
  });
  console.log(days);
  console.log(formattedDates);
  const [dateOption, setDateOption] = useState("Choose Date");
  const [dateIndex, setDateIndex] = useState("0");
  const [dateSelected, setDateSelected] = useState(false);
  const handleDateSelect = (eventKey) => {
    console.log(eventKey);
    eventKey = eventKey.split("@");

    setDateOption(eventKey[1]);
    setDateIndex(eventKey[0]);
    setDateSelected(true);
    var intDate = parseInt(eventKey[0]);
    console.log(intDate);
    console.log(doctorAppointmentDates[intDate]);
    settimeSlots(doctorAppointmentDates[intDate].timeSlots);
    console.log(doctorAppointmentDates[intDate].timeSlots);
    setSelectedCheckboxes([]);
    setSelectedCheckboxesIds([]);
  };

  // Create a handler function to update the selectedCheckboxes array
  const handleCheckboxChange = (value, checked) => {
    // const { value, checked } = event.target;

    if (checked) {
      // If the checkbox is checked, add its value to the array
      setSelectedCheckboxes([...selectedCheckboxes, value.split("@@")[0]]);
      setSelectedCheckboxesIds([
        ...selectedCheckboxesIds,
        value.split("@@")[1],
      ]);
    } else {
      // If the checkbox is unchecked, remove its value from the array
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== value.split("@@")[0])
      );
      setSelectedCheckboxesIds(
        selectedCheckboxesIds.filter((item) => item !== value.split("@@")[1])
      );
    }
  };

  const handleBlockSlot = () => {
    if (selectedCheckboxes.length == 0) {
      alert("No slots selected! please select the slots to be blocked");
    } else {
      // console.log(dateOption.split("@"));
      // const rDateOption =
      // 	dateOption.split("-")[2].trim() +
      // 	"-" +
      // 	dateOption.split("-")[1].trim() +
      // 	"-" +
      // 	dateOption.split("-")[0].trim();
      console.log(selectedCheckboxes + "before sort ");
      console.log();
      selectedCheckboxes.sort(function (a, b) {
        return parseInt(a.split("-")[0]) - parseInt(b.split("-")[0]);
      });
      console.log(selectedCheckboxes + "line133");
      console.log(selectedCheckboxesIds + "line134");
      dispatch(
        blockSlot(
          doctorInfo?.user?._id,
          selectedCheckboxes,
          doctorInfo?.user?.speciality,
          dateOption,
          dateIndex,
          selectedCheckboxesIds,
          clinicName
        )
      );
      setDateOption("Choose Date");
      setDateSelected(false);
      setSelectedCheckboxes([]);
      setSelectedCheckboxesIds([]);
    }
  };

  //ALerts//////////////////////////////////////////////////////////////////

  const [timerRunning, setTimerRunning] = useState(false);
  useEffect(() => {
    dispatch(allMessagesStart());
  }, []);
  const alertmsg = useSelector((state) => state.allMessages);

  const [showPopup3, setShowPopup3] = React.useState(false);
  const toggleShowInfoPopup3 = (e) => {
    setShowPopup3(!showPopup3);
    e.preventDefault();
    console.log("error");
    dispatch(blockSlotReset());
  };

  useEffect(() => {
    if (blockSlotsuccess && !timerRunning) {
      setTimerRunning(true);

      const timer = setTimeout(() => {
        // Clear the alert and stop the timer after 3 seconds
        dispatch(blockSlotReset());

        setTimerRunning(false);
      }, 3000);

      // Clean up the timer if the component unmounts or the alert changes
    }
  }, [blockSlotsuccess, dispatch]);

  const [showPopup5, setShowPopup5] = React.useState(false);
  const toggleShowInfoPopup5 = (e) => {
    setShowPopup5(!showPopup5);
    e.preventDefault();
    console.log("error");
    dispatch(getLeavesReset());
  };

  const [showPopup6, setShowPopup6] = React.useState(false);
  const toggleShowInfoPopup6 = (e) => {
    setShowPopup6(!showPopup6);
    e.preventDefault();
    console.log("error");
    dispatch(TimeSlotGetReset());
  };

  return (
    <div className="pageSize">
      <Meta />
      {getTimeSlotserror && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup6}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {getTimeSlotserror}
        </Message>
      )}

      {blockSloterror && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup3}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {blockSloterror}
        </Message>
      )}

      {(getTimeSlotsloading || blockSlotloading) && <Loader />}

      <h1 className="header-center">Block Slots</h1>
      <Container className="header-center mt-5">
        <Row>
          <Col md="auto" className="text-center">
            <label className="form-label" htmlFor="name">
              <b>Select Date: </b>
              <span className="text-danger">
                <b>*</b>
              </span>
            </label>
          </Col>
          <Col md="auto" className="text-center">
            <DropdownButton
              key="down-centered"
              id={`dropdown-button-drop-down-centered`}
              drop="down-centered"
              variant="success"
              title={dateOption}
              onSelect={handleDateSelect}
            >
              {" "}
              <div className="dropScroll">
                {days?.map((day, index) => (
                  <Dropdown.Item eventKey={index + "@" + day.currDay}>
                    {day.currDay}
                  </Dropdown.Item>
                ))}
              </div>
            </DropdownButton>
          </Col>
        </Row>
      </Container>
      <Container className="header-center mt-5">
        <Row>
          <Col md="auto" className="text-center">
            <label className="form-label" htmlFor="name">
              {dateSelected ? (
                <div>
                  <b>Choose Slot: </b>
                  <span className="text-danger">
                    <b>*</b>
                  </span>
                </div>
              ) : (
                <b> </b>
              )}
            </label>
          </Col>
        </Row>
        <Row>
          <Col md="auto">
            {dateSelected &&
              timeSlots?.map((option, index) =>
                option?.maxAppointmentsperhour !== 0 ? (
                  !option?.isBlocked ? (
                    selectedCheckboxes.includes(option.timeSlot) ? (
                      <button
                        className="btn btn-success col-md-3 m-1 col-10"
                        id={option.timeSlot}
                        onClick={() =>
                          handleCheckboxChange(
                            option.timeSlot + "@@" + index,
                            false
                          )
                        }
                      >
                        {`${option.timeSlot}`} Slot is unblocked
                      </button>
                    ) : (
                      <button
                        className="btn btn-outline-success col-md-3 m-1 col-10"
                        id={option.timeSlot}
                        onClick={() =>
                          handleCheckboxChange(
                            option.timeSlot + "@@" + index,
                            true
                          )
                        }
                      >
                        {`${option.timeSlot}`} Slot is unblocked
                      </button>
                    )
                  ) : (
                    <button
                      className="btn btn-danger col-md-3 m-1 col-10"
                      disabled
                    >
                      {option.timeSlot} Slot is blocked
                    </button>
                  )
                ) : (
                  <button
                    className="btn btn-danger col-md-3 m-1 col-10"
                    disabled
                  >
                    {option.timeSlot} Break Time
                  </button>
                )
              )}
          </Col>
        </Row>
        {dateSelected && (
          <Row className="justify-content-center mt-3">
            <Col md="auto" className="text-center">
              <button className="btn btn-success" onClick={handleBlockSlot}>
                Block
              </button>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default DoctorBlockSlotsScreen;
