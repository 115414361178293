/** @format */

import { React, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Nav } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
const SideBar = () => {
	const location = useLocation();
	const { pathname } = location;

	const currentURL = pathname;
	const pattern = /\/clinic\/([^/]+)/;
	const match = currentURL.match(pattern);
	var clinicName;
	if (match) {
		clinicName = match[1];
		console.log(clinicName); // Output: Clinic1
	} else {
		console.log("Clinic name not found in the URL");
	}
	const { doctorInfo } = useSelector((state) => state.doctorLogin);

	const { receptionInfo } = useSelector((state) => state.receptionLogin);

	const [allDau, setAllDau] = useState([]);

	// Use an object to store the collapse state for each item
	const [openItems, setOpenItems] = useState({});

	let carray = [];

	const handleThisLogin = (carray) => {
		setAllDau(carray);
	};

	useEffect(() => {
		if (doctorInfo?.user?.name) {
			carray = [
				{
					name: "My Appointments",
					link: `/clinic/${clinicName}/doctor/patientstobevisited`,
					icon: "far fa-calendar-check", // Updated icon
					dropdownItems: [],
				},
				{
					name: "Book Appointment",
					link: `/clinic/${clinicName}/doctor/booking`,
					icon: "far fa-calendar-plus", // Updated icon
					dropdownItems: [],
				},
				{
					name: "Search Patients",
					link: `/clinic/${clinicName}/doctor/searchPatients`,
					icon: "fas fa-search", // Updated icon
					dropdownItems: [],
				},
				{
					name: "Patient Management",
					// link: "/doctor/register",
					// icon: "fas fa-user-plus",
					// Updated icon
					icon: "fas fa-hospital-user",
					dropdownItems: [
						{
							name: "Add User",
							link: `/clinic/${clinicName}/doctor/Adduser`,
							icon: "fas fa-user-plus",
						},
						{
							name: "Edit Patient",
							link: `/clinic/${clinicName}/doctor/editPatient`,
							icon: "fas fa-edit",
						},
						{
							name: "Register Patient",
							link: `/clinic/${clinicName}/register`,
							icon: "fas fa-user-plus",
						},
					],
				},
				{
					name: "Slots Management",
					// link: "/doctor/blockslots",
					icon: "far fa-clock", // Updated icon
					dropdownItems: [
						{
							name: "Block Slots",
							link: `/clinic/${clinicName}/doctor/blockslots`,
							icon: "fas fa-lock", // Updated icon
						},
						{
							name: "UnBlock Slots",
							link: `/clinic/${clinicName}/doctor/unblockslots`,
							icon: "fas fa-unlock", // Updated icon
						},
						{
							name: "Edit My Slots",
							link: `/clinic/${clinicName}/doctor/EditMySlots`,
							icon: "far fa-edit", // Updated icon
						},
						{
							name: "Edit Booking Period",
							link: `/clinic/${clinicName}/doctor/EditBookingPeriod`,
							icon: "far fa-calendar-alt", // Updated icon
						},
					],
				},
				{
					name: "Leaves Management",
					// link: "/doctor/createleaves",
					icon: "far fa-calendar-times", // Updated icon for applying leave
					dropdownItems: [
						{
							name: "Apply Leaves",
							link: `/clinic/${clinicName}/doctor/createleaves`,
							icon: "fas fa-calendar-plus", // Updated icon for applying leave
						},
						{
							name: "Cancel Leaves",
							link: `/clinic/${clinicName}/doctor/deleteleaves`,
							icon: "far fa-trash-alt", // Updated icon for deleting leave
						},
					],
				},

				{
					name: "Medicine Templates",
					// link: "/doctor/templates",
					icon: "far fa-file-alt", // Updated icon
					dropdownItems: [
						{
							name: "Add Template",
							link: `/clinic/${clinicName}/doctor/addtemplates`,
							icon: "far fa-file-alt",
						},
						{
							name: "Edit Template",
							link: `/clinic/${clinicName}/doctor/edittemplates`,
							icon: "fas fa-edit",
						},
						{
							name: "Delete Template",
							link: `/clinic/${clinicName}/doctor/deletetemplates`,
							icon: "fas fa-trash",
						},
					],
				},

				{
					name: "Reception Management",
					// link: "/doctor/receptionManagement",
					icon: "fas fa-users-cog", // Updated icon
					dropdownItems: [
						{
							name: "Add Receptionist",
							link: `/clinic/${clinicName}/doctor/addReception`,
							icon: "fas fa-user-plus",
						},
						{
							name: "Delete Receptionist",
							link: `/clinic/${clinicName}/doctor/deleteReception`,
							icon: "fas fa-trash",
						},
					],
				},

				{
					name: "Analytics Hub",
					// link: "/doctor/register",
					icon: "fas fa-chart-line", // Updated icon
					dropdownItems: [
						{
							name: "Revenue Analytics",
							link: `/clinic/${clinicName}/doctor/hospitalStatistic`,
							icon: "fas fa-rupee-sign",
						},
						{
							name: "Patient Analytics",
							link: `/clinic/${clinicName}/doctor/patientStatistic`,
							icon: "fas fa-users",
						},
					],
				},
				{
					name: "My Clinic",
					link: `/clinic/${clinicName}/doctor/myclinic`,
					icon: "fa fa-hospital-o", // Updated icon
					dropdownItems: [],
				},
			];
			handleThisLogin(carray);
		} else if (receptionInfo?.user?.name) {
			carray = [
				{
					name: "Appointments",
					link: `/clinic/${clinicName}/reception/patientstobevisited`,
					icon: "fa fa-clipboard",
					dropdownItems: [],
				},
				{
					name: "Book Appointments",
					link: `/clinic/${clinicName}/reception/booking`,
					icon: "fa fa-calendar-plus",
					dropdownItems: [],
				},
				{
					name: "Search Patients",
					link: `/clinic/${clinicName}/reception/searchPatient`,
					icon: "fa fa-search",
					dropdownItems: [],
				},
				{
					name: "Register Patient",
					link: `/clinic/${clinicName}/register`,
					icon: "fa fa-user-plus",
					dropdownItems: [],
				},
				{
					name: "Scheduled Appointments",
					link: `/clinic/${clinicName}/reception/scheduledAppointments`,
					icon: "fa fa-clock",
					dropdownItems: [],
				},

				{
					name: "Blocked Slots",
					link: `/clinic/${clinicName}/reception/BlockedSlots`,
					icon: "fa fa-ban",
					dropdownItems: [],
				},
				{
					name: "Update Password",
					link: `/clinic/${clinicName}/reception/updatepassword`,
					icon: "fa fa-key",
					dropdownItems: [],
				},
			];
			handleThisLogin(carray);
		}
	}, [doctorInfo?.user?.name, receptionInfo?.user?.name]);

	const handleToggle = (index) => {
		setOpenItems((prevOpenItems) => ({
			...prevOpenItems,
			[index]: !prevOpenItems[index],
		}));
	};
	return (
		<>
			<div className="sidebar">
				<Nav defaultActiveKey="/home" className="flex-column">
					{allDau?.map((items, index) => (
						<>
							{items?.dropdownItems?.length !== 0 ? (
								<>
									<Nav.Item>
										<Nav.Link
											className="sidebar-links"
											onClick={() => handleToggle(index)}
											aria-controls={`example-collapse-text-${index}`}
											aria-expanded={openItems[index]}
										>
											<i className={`${items.icon} mr-1`}></i> {items.name}{" "}
											<i class="fa fa-caret-down ml-1"></i>
										</Nav.Link>
									</Nav.Item>
									<Collapse
										in={openItems[index]}
										id={`example-collapse-text-${index}`}
									>
										<div>
											{items?.dropdownItems?.map(
												(dropdownItem, dropdownIndex) => (
													<Nav.Item key={dropdownIndex}>
														{dropdownItem &&
															dropdownItem.link &&
															dropdownItem.icon &&
															dropdownItem.name && (
																<Nav.Link
																	className="sidebar-sublinks header-left custom-nav-link"
																	href={dropdownItem?.link}
																>
																	<i
																		className={`${dropdownItem?.icon} mr-1`}
																	></i>{" "}
																	{dropdownItem?.name}
																</Nav.Link>
															)}
													</Nav.Item>
												)
											)}
										</div>
									</Collapse>
								</>
							) : (
								<Nav.Item>
									<Nav.Link className="sidebar-links" href={items.link}>
										<i className={`${items.icon} mr-1`}></i> {items.name}
									</Nav.Link>
								</Nav.Item>
							)}
						</>
					))}
				</Nav>
			</div>
		</>
	);
};

export default SideBar;
