import { createSlice } from "@reduxjs/toolkit";

const getUserDataById = createSlice({
	name: "getUserDataById",
	initialState: {},
	reducers: {
		getUserDataByIdRequest: (state) => {
			state.loading = true;
		},
		getUserDataByIdSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.userdata = action.payload;
			state.error = false;
			state.success = true;
		},
		getUserDataByIdFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getUserDataByIdReset: (state) => {
			return { error: false, success: false };
		},
		getUserDataErrorByIdReset: (state) => {
			return { userData: false };
		},
	},
});

export const {
	getUserDataByIdRequest,
	getUserDataByIdSuccess,
	getUserDataByIdFailure,
	getUserDataByIdReset,
	getUserDataErrorByIdReset,
} = getUserDataById.actions;
export default getUserDataById.reducer;
