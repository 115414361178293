import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRevenueForYear } from "../../redux/doctorRedux/doctorApiCalls";

import "./featuredInfo.css";

const FeaturedInfo = ({ statistics }) => {
	console.log(statistics);

	return (
		<div className="featured">
			<div className="featuredItem">
				<span className="featuredTitle">Visited Patients Count</span>
				<div className="featuredMoneyContainer">
					<span className="featuredMoney">{statistics[0]}</span>
					<span className="featuredMoneyRate">
						{statistics[4]}
						{statistics[4] > 0 ? (
							<i class="fas fa-arrow-up" style={{ color: "green" }}></i>
						) : (
							<i className="fa fa-arrow-down" style={{ color: "red" }}></i>
						)}
					</span>
				</div>
				<span className="featuredSub">Compared to Yesterday</span>
			</div>
			<div className="featuredItem">
				<span className="featuredTitle">Todays Revenue</span>
				<div className="featuredMoneyContainer">
					<span className="featuredMoney">{statistics[1]}</span>
					<span className="featuredMoneyRate">
						{statistics[5]}
						{statistics[5] >= 0 ? (
							<i class="fas fa-arrow-up" style={{ color: "green" }}></i>
						) : (
							<i className="fa fa-arrow-down" style={{ color: "red" }}></i>
						)}
					</span>
				</div>
				<span className="featuredSub">Compared to Yesterday</span>
			</div>
			<div className="featuredItem">
				<span className="featuredTitle">Revenue Cash</span>
				<div className="featuredMoneyContainer">
					<span className="featuredMoney">{statistics[2]}</span>
					<span className="featuredMoneyRate">
						{statistics[6]}
						{statistics[6] >= 0 ? (
							<i class="fas fa-arrow-up" style={{ color: "green" }}></i>
						) : (
							<i className="fa fa-arrow-down" style={{ color: "red" }}></i>
						)}
					</span>
				</div>
				<span className="featuredSub">Compared to Yesterday</span>
			</div>
			<div className="featuredItem">
				<span className="featuredTitle">Revenue Online</span>
				<div className="featuredMoneyContainer">
					<span className="featuredMoney">{statistics[3]}</span>
					<span className="featuredMoneyRate">
						{statistics[7]}
						{statistics[7] >= 0 ? (
							<i class="fas fa-arrow-up" style={{ color: "green" }}></i>
						) : (
							<i className="fa fa-arrow-down" style={{ color: "red" }}></i>
						)}
					</span>
				</div>
				<span className="featuredSub">Compared to Yesterday</span>
			</div>
		</div>
	);
};

export default FeaturedInfo;
