/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Table from "react-bootstrap/Table";
import Meta from "../../../Components/Meta";
import Message from "../../../Components/Message";
import { deleteReceptionReset } from "../../../redux/adminRedux/Reception_Management/deleteReception";
import { allMessagesStart } from "../../../redux/allMessages";
import {
	getAllReception,
	deleteReception,
} from "../../../redux/adminRedux/adminApiCalls";
import { useParams } from "react-router-dom";

const DeleteReceptionScreens = () => {
	const dispatch = useDispatch();
	const { clinicName } = useParams();
	var getAllReceptionData = useSelector((state) => state.getAllReception);
	getAllReceptionData = getAllReceptionData?.getAllReception?.data;
	console.log(getAllReceptionData);

	const deleteReceptionData = useSelector((state) => state.deleteReception);
	const deleteReceptionError = deleteReceptionData.error;
	const deleteReceptionSuccess = deleteReceptionData.success;
	const deleteReceptionLoading = deleteReceptionData.loading;

	useEffect(() => {
		dispatch(getAllReception(clinicName));
	}, [
		dispatch,
		deleteReceptionError,
		deleteReceptionSuccess,
		deleteReceptionLoading,
	]);

	const deleteReceptionHandle = (id) => {
		dispatch(deleteReception(id, clinicName));
	};
	console.log(
		deleteReceptionError,
		deleteReceptionSuccess,
		deleteReceptionLoading
	);

	////////////////////////////Alerts/////////////////////////////////////////
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const [showPopup1, setShowPopup1] = React.useState(false);
	const toggleShowInfoPopupDelete = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log("error");
		dispatch(deleteReceptionReset());
	};

	return (
		<div className="pageSize">
			<Meta />

			{deleteReceptionError && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopupDelete}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{deleteReceptionError}
				</Message>
			)}
			{deleteReceptionLoading && <Loader />}

			<div>
				<h1 className="header-center">Delete Receptionist</h1>
				<div className="col-12 offset-md-3 col-md-6 mt-5">
					<Table striped bordered hover className="custom-table">
						<thead>
							<tr>
								<th>Sno</th>
								<th>Receptionist Name</th>
								<th>Mobile Number</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{getAllReceptionData?.data?.map((rec, index) => (
								<tr>
									<td>{index + 1}</td>
									<td>{rec?.name}</td>
									<td>{rec?.mobilenumber}</td>
									<td>
										<button
											className="btn btn-danger"
											onClick={() => deleteReceptionHandle(rec?._id)}
										>
											<i className="fa fa-trash-o"></i>
										</button>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
				</div>
			</div>
		</div>
	);
};
export default DeleteReceptionScreens;
