/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../redux/allMessages";
import Message from "./Message";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
const AlertMessageBar = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { pathname } = location;

	const currentURL = pathname;
	const pattern = /\/clinic\/([^/]+)/;
	const match = currentURL.match(pattern);
	var clinicName;
	if (match) {
		clinicName = match[1];
		console.log(clinicName); // Output: Clinic1
	} else {
		console.log("Clinic name not found in the URL");
	}
	const alertmsg = useSelector((state) => state.allMessages);
	const { loading, success, alertMsg, error } = alertmsg;
	const [timerRunning, setTimerRunning] = useState(false);
	const [showPopup, setShowPopup] = React.useState(false);
	console.log(success);
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	console.log(doctorInfo);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	console.log(receptionInfo);
	const [myProfile, setMyProfile] = useState(false);
	useEffect(() => {
		if (
			doctorInfo?.user?.address === "" ||
			doctorInfo?.user?.dob === "" ||
			doctorInfo?.user?.opConsultationFee === 0 ||
			doctorInfo?.user?.aboutMe?.designation === "" ||
			doctorInfo?.user?.aboutMe?.education === "" ||
			doctorInfo?.user?.aboutMe?.personality === "" ||
			doctorInfo?.user?.aboutMe?.experience === ""
		) {
			setMyProfile(false);
		} else {
			setMyProfile(true);
		}
	}, [doctorInfo]);
	const [editMySlots, setEditMySlots] = useState(false);
	useEffect(() => {
		let flag = 1;
		let count = 0;
		for (let i = 0; i < doctorInfo?.user?.days?.length; i++) {
			if (doctorInfo?.user?.days[i]?.isWorking) {
				count++;
			}
		}
		for (let i = 0; i < doctorInfo?.user?.days?.length; i++) {
			if (doctorInfo?.user?.days[i]?.isWorking) {
				if (
					doctorInfo?.user?.days[i]?.breakTime === "" ||
					doctorInfo?.user?.days[i]?.startTime === "" ||
					doctorInfo?.user?.days[i]?.endTime === "" ||
					doctorInfo?.user?.days[i]?.maxAppointmentsperhour === 0
				) {
					flag = 0;
				}
			}
		}
		if (flag === 1 && count > 0) {
			setEditMySlots(true);
		}
		console.log(editMySlots);
	}, [doctorInfo]);
	useEffect(() => {
		// If there's an alert message and the timer isn't running, start the timer
		if (success && !timerRunning) {
			setTimerRunning(true);
			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(allMessagesReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [success, dispatch, timerRunning]);
	console.log(success);
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log("error");
		dispatch(allMessagesReset());
	};

	return (
		<div>
			{success && (
				<Message variant="success">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{alertMsg}
				</Message>
			)}
			{window.location.pathname !== "/" &&
				!window.location.pathname?.includes("/reception/") &&
				doctorInfo &&
				!myProfile && (
					<Message variant="danger">
						{"Please navigate to "}{" "}
						<a href={`/clinic/${clinicName}/doctor/myprofile`}>My profile</a>{" "}
						{" and update the required fields."}
					</Message>
				)}
			{window.location.pathname !== "/" &&
				!window.location.pathname?.includes("/reception/") &&
				doctorInfo &&
				!editMySlots && (
					<Message variant="danger">
						{"Please navigate to "}{" "}
						<a href={`/clinic/${clinicName}/doctor/EditMySlots`}>
							Edit My Slots
						</a>{" "}
						{" and update the required fields."}
					</Message>
				)}
		</div>
	);
};

export default AlertMessageBar;
