import { createSlice } from "@reduxjs/toolkit";

const getRevenueForParticularYearReducer = createSlice({
	name: "getRevenueForParticularYear",
	initialState: {},
	reducers: {
		getRevenueForParticularYearRequest: (state) => {
			state.loading = true;
		},
		getRevenueForParticularYearSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.getRevenueForParticularYear = action.payload;
			state.error = false;
			state.success = true;
		},
		getRevenueForParticularYearFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getRevenueForParticularYearReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	getRevenueForParticularYearRequest,
	getRevenueForParticularYearSuccess,
	getRevenueForParticularYearFailure,
	getRevenueForParticularYearReset,
} = getRevenueForParticularYearReducer.actions;
export default getRevenueForParticularYearReducer.reducer;
