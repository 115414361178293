/** @format */

import axios from "axios";
import { SERVER_URL } from "../App";

import { registerStart } from "./userRegister";
import { registerFailure } from "./userRegister";
import { registerSuccess } from "./userRegister";

import { UpdateFailure, UpdateStart, UpdateSuccess } from "./userUpdate";

import {
	createBookingStart,
	createBookingFailure,
	createBookingSuccess,
} from "./createBooking";

import {
	TimeSlotGetRequest,
	TimeSlotGetFailure,
	TimeSlotGetSuccess,
} from "./getTimeSlots";
import { persistor } from "./store";

import {
	UserBookingGetFailure,
	UserBookingGetRequest,
	UserBookingGetSuccess,
} from "./getPatientBooking";

import {
	deleteBookingFailure,
	deleteBookingSuccess,
	deleteBookingStart,
} from "./deleteBooking";

import {
	createCategoryStart,
	createCategoryFailure,
	createCategorySuccess,
} from "./createCategory";

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../redux/allMessages";

// import { UpdateStart } from "./userUpdate";
// import { UpdateSuccess } from "./userUpdate";
// import { UpdateFailure } from "./userUpdate";
// import { userUpdateProfileReset } from "./userUpdate";
// import { bookingListMyReset } from "./getMyAppointmentss";
// import { tourReviewCreateReset } from "./tourReview";

export const register =
	(name, mobilenumber, gender, dob, address, bloodGroup, clinicname) =>
	async (dispatch) => {
		try {
			dispatch(registerStart());

			const config = {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					clinicname: clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/users/signup`,
				{
					name,

					mobilenumber,
					gender,
					dob,
					address,
					bloodGroup,
				},
				config
			);
			console.log(data);
			dispatch(registerSuccess(data));
			dispatch(allMessagesSuccess(" Registered Succesfully. Please login"));
		} catch (error) {
			console.log(error?.response);
			dispatch(
				registerFailure(
					error?.response && error?.response?.data?.data?.message
						? error?.response?.data?.data?.message
						: error?.message
				)
			);
		}
	};

export const logout1 = () => (dispatch) => {
	// dispatch(userUpdateProfileReset());
	// dispatch(bookingListMyReset());
	// dispatch(tourReviewCreateReset());
	localStorage.removeItem("userInfo");
	localStorage.removeItem("adminInfo");
	localStorage.removeItem("token-user");
	localStorage.removeItem("persist:root");
	localStorage.removeItem("username");
	persistor.pause();
	persistor.flush().then(() => {
		return persistor.purge();
	});
};

export const updateUserProfileByAdmin =
	(name, gender, dob, mobno, bloodGroup, clinicname) => async (dispatch) => {
		try {
			dispatch(UpdateStart());
			console.log("getting3221");

			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};
			console.log("getting3221asd");

			const { data } = await axios.patch(
				`${SERVER_URL}/api/v1/booking/updateMeByAdmin`,
				{ name, gender, dob, mobno, bloodGroup, username },
				config
			);
			console.log(data);
			dispatch(UpdateSuccess(data));

			localStorage.setItem("userInfo", JSON.stringify(data));
			dispatch(allMessagesSuccess("Added patient successfully"));
		} catch (error) {
			const message =
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message;
			if (message === "Not authorized, token failed") {
				localStorage.removeItem("persist:root");
			}
			dispatch(UpdateFailure(message));
		}
	};

// export const deleteUser = (name, gender, dob) => async (dispatch) => {
// 	try {
// 		dispatch(DeleteStart());
// 		console.log("getting3221");

// 		const token = localStorage.getItem("token-user");
// 		const config = {
// 			headers: {
// 				"Content-Type": "application/json",
// 				Authorization: `Bearer ${token}`,
// 			},
// 		};
// 		console.log("getting3221asd");

// 		const { data } = await axios.post(
// 			`${SERVER_URL}/api/v1/users/deleteUser`,
// 			{ name, gender, dob },
// 			config
// 		);
// 		console.log(data);
// 		dispatch(DeleteSuccess(data));
// 		dispatch(loginSuccess(data));
// 		localStorage.setItem("userInfo", JSON.stringify(data));
// 	} catch (error) {
// 		const message =
// 			error.response && error.response.data.data.message
// 				? error.response.data.data.message
// 				: error.message;
// 		if (message === "Not authorized, token failed") {
// 			localStorage.removeItem("persist:root");
// 			dispatch(logout());
// 		}
// 		dispatch(DeleteFailure(message));
// 	}
// };

export const getTimeslots =
	(doctorId, dateIdx, clinicname) => async (dispatch) => {
		console.log(doctorId, dateIdx);
		try {
			console.log("11223344");

			dispatch(TimeSlotGetRequest());
			console.log(doctorId, dateIdx);
			const username = localStorage.getItem("username");
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					username: username,
					clinicname: clinicname,
				},
			};

			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/booking/getTimeSlots/${doctorId}/${dateIdx}`,
				config
			);
			console.log(data);
			dispatch(TimeSlotGetSuccess(data.data.timeSlots));
		} catch (error) {
			dispatch(
				TimeSlotGetFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const createBooking =
	(
		name,
		scheduledDate,
		slot,
		slotCount,
		userPhone,
		doctorName,
		doctor,
		specialityName,
		opConsultationFee,
		userId,
		dayIdx,
		clinicname
	) =>
	async (dispatch) => {
		try {
			dispatch(createBookingStart());
			const token = localStorage.getItem("token-user");
			const username = localStorage.getItem("username");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};
			console.log(
				name,
				scheduledDate,
				slot,
				slotCount,
				userPhone,
				doctorName,
				doctor,
				specialityName,
				opConsultationFee,
				userId,
				dayIdx
			);
			const data = await axios.post(
				`${SERVER_URL}/api/v1/booking`,
				{
					name: name,
					doctorName: doctorName,
					doctor: doctor,
					scheduledDate: scheduledDate,
					slot: slot,
					slotCount: slotCount,
					userPhone: userPhone,
					username: username,
					specialityName,
					opConsultationFee,
					userId,
					dayIdx,
				},
				config
			);
			console.log(data + "review123");
			dispatch(createBookingSuccess(data));
			dispatch(allMessagesSuccess("Booking created Successfully"));
		} catch (error) {
			const message =
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message;
			if (message === "Not authorized, token failed") {
				localStorage.removeItem("persist:root");
			}
			dispatch(createBookingFailure(message));
		}
	};

export const getUserBooking = (ids, clinicname) => async (dispatch) => {
	try {
		console.log(ids + "    11223344");
		dispatch(UserBookingGetRequest());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				clinicname: clinicname,
			},
		};
		const { data } = await axios.post(
			`${SERVER_URL}/api/v1/booking/getUserBookings/${username}`,
			{ ids },
			config
		);
		// let users = data.user.name + "/";
		// for (let i = 0; i < data.user.registered_patients_array.length; i++) {
		// 	users = data.user.registered_patients_array[i] + "/";
		// }
		// console.log(users);
		// localStorage.setItem("token-user", data.token);
		// localStorage.setItem("name", data.user.name);
		//localStorage.setItem("users", users);

		// console.log(data.user);
		console.log(data + "56777777");
		dispatch(UserBookingGetSuccess(data));
	} catch (error) {
		dispatch(
			UserBookingGetFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const deleteBooking = (id, clinicname) => async (dispatch) => {
	try {
		console.log("11223344");
		dispatch(deleteBookingStart());

		const token = localStorage.getItem("token-user");
		const username = localStorage.getItem("username");
		const config = {
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
				clinicname: clinicname,
			},
		};
		const data = await axios.delete(
			`${SERVER_URL}/api/v1/booking/${id}/${username}`,
			config
		);
		// let users = data.user.name + "/";
		// for (let i = 0; i < data.user.registered_patients_array.length; i++) {
		// 	users = data.user.registered_patients_array[i] + "/";
		// }
		// console.log(users);
		// localStorage.setItem("token-user", data.token);
		// localStorage.setItem("name", data.user.name);
		//localStorage.setItem("users", users);

		// console.log(data.user);
		console.log(data);
		dispatch(deleteBookingSuccess(data));
		dispatch(allMessagesSuccess("Booking deleted successfully"));
	} catch (error) {
		dispatch(
			deleteBookingFailure(
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message
			)
		);
	}
};

export const createCategory =
	(userId, category, name, clinicname) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(createCategoryStart());
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/prescription/createCategory`,
				{ userId, category, name },
				config
			);
			dispatch(createCategorySuccess(data));
			dispatch(allMessagesSuccess("Category added Successfully "));
		} catch (error) {
			console.log(error);
			dispatch(
				createCategoryFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};
