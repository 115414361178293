/** @format */

import { createSlice } from "@reduxjs/toolkit";

const editClinicDetails = createSlice({
	name: "editClinicDetails",
	initialState: {},
	reducers: {
		editClinicDetailsStart: (state) => {
			state.loading = true;
		},
		editClinicDetailsSuccess: (state, action) => {
			state.loading = false;
			state.template = action.payload;
			state.error = false;
			state.success = true;
		},
		editClinicDetailsFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editClinicDetailsReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	editClinicDetailsStart,
	editClinicDetailsSuccess,
	editClinicDetailsFailure,
	editClinicDetailsReset,
} = editClinicDetails.actions;
export default editClinicDetails.reducer;
