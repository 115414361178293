/** @format */
import Message from "../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, useLocation } from "react-router-dom";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

// import Accordion from 'react-bootstrap/Accordion';
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";

import { getTimeslots } from "../../redux/userApiCalls";
import { getMyAppointments } from "../../redux/doctorRedux/doctorApiCalls";
import { getuserByIdForPrescription } from "../../redux/prescriptionRedux/prescriptionApiCalls";
import Form from "react-bootstrap/Form";
import { getMyAppointmentsReset } from "../../redux/doctorRedux/Appointment_Management/getMyAppointments";
import { TimeSlotGetReset } from "../../redux/getTimeSlots";
import { getuserByIdForPrescriptionReset } from "../../redux/prescriptionRedux/getuserByIdForPrescription";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";
import { useParams } from "react-router-dom";

const GetUserPrescriptionsScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	//success and failure
	const doctorLogin = useSelector((state) => state.doctorLogin);
	const { doctorInfo } = doctorLogin;
	console.log(doctorInfo?.user);
	const getUserDataByIdData = useSelector(
		(state) => state.getuserByIdForPrescription
	);
	const userdata = getUserDataByIdData.userdata;
	const success = getUserDataByIdData.success;
	const loading = getUserDataByIdData.loading;
	const error = getUserDataByIdData.error;
	// Accordian Collapse
	const [open, setOpen] = useState("1");
	const toggle = (id) => {
		if (open === id) {
			setOpen();
		} else {
			setOpen(id);
		}
	};
	console.log(history);

	//Modal Open
	const [lgShow, setLgShow] = useState(false);
	const [startPres, setStartPres] = useState(false);

	const today = new Date();
	const year = today.getFullYear();
	const month = today.getMonth() + 1; // Months are zero-indexed, so add 1
	const day = today.getDate();

	const curDate = year * 10000 + month * 100 + day;

	console.log(location.state?.id, location.state?.name);

	console.log(userdata);
	useEffect(() => {
		console.log(location.state.id, location.state.name);
		dispatch(
			getuserByIdForPrescription(
				location.state?.id,
				location.state?.name,
				clinicName
			)
		);
	}, []);

	const prescriptionByDate = [];
	console.log(JSON.stringify(prescriptionByDate) + "line 78");
	let scheduledObj = "";
	for (let i = 0; i < userdata?.data?.data?.scheduledAppointment?.length; i++) {
		if (
			userdata?.data?.data?.scheduledAppointment[i].doctor ==
			doctorInfo?.user._id
		) {
			scheduledObj = userdata?.data?.data?.scheduledAppointment[i];
			console.log(scheduledObj);
			//   scheduledObj.date = userdata?.data?.data?.scheduledAppointment[i].date
			//     .split("-")
			//     .reverse()
			//     .join("-");
		}
	}

	//Filter dropdown
	const [filterOption, setFilterOption] = useState("Date");
	const handleFilterSelect = (eventKey) => {
		setFilterOption(eventKey);
	};

	const [categoryOption, setCategoryOption] = useState("Choose Category");
	const [categoryDropdown, setcategoryDropdown] = useState("col-2 btn d-none");

	const [ind, setInd] = useState(-1);

	const handleCategoryNameSelect = (eventKey) => {
		setCategoryOption(eventKey);
		for (let i = 0; i < userdata?.data?.data?.prescCategory.length; i++) {
			if (eventKey === userdata?.data?.data?.prescCategory[i]?.category) {
				setInd(i);
			}
		}
	};

	const handleCategorySelect = () => {
		setcategoryDropdown("col-2 btn d-block");
	};

	const handleDateSelect = () => {
		setcategoryDropdown("col-2 btn d-none");
	};

	const startPrescription = () => {
		history(
			`/clinic/${clinicName}/doctor/patientstobevisited/userappointment/editpres`,
			{
				state: {
					id: location.state.id,
					name: location.state.name,
					date: location.state.date,
					hasPaid: location.state.hasPaid,
				},
			}
		);
	};

	const viewBtn = (id, age, gender, bgrp, name) => {
		history(`/clinic/${clinicName}/doctor/viewpres`, {
			state: {
				id: id,
				age: age,
				gender: gender,
				bgrp: bgrp,
				name: name,
				page: "Doc2Screen",
			},
		});
	};
	//ALerts
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const [showPopup6, setShowPopup6] = React.useState(false);
	const toggleShowInfoPopup6 = (e) => {
		setShowPopup6(!showPopup6);
		e.preventDefault();
		console.log("error");
		dispatch(getuserByIdForPrescriptionReset());
	};

	console.log(location.state?.date, curDate, userdata?.data?.data?.isBooked);
	// prescriptionByDate?.map((pres, index) =>
	// 	console.log(
	// 		`${pres.bookedDate.toString().substr(6, 2)}-${pres.bookedDate
	// 			.toString()
	// 			.substr(4, 2)}-${pres.bookedDate.toString().substr(0, 4)}`
	// 	)
	// );

	return (
		<div className="pageSize">
			<Meta />
			{error && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup6}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}
			<h4 className="header-center">Patient Name: {location.state?.name}</h4>
			<a
				role="button"
				href={`/clinic/${clinicName}/doctor/patientstobevisited`}
				className="btn offset-1 back-button"
			>
				<i className="fa fa-angle-left custom fa-2x"></i>
			</a>
			<div className="card mt-2 container">
				<div className="row mt-3 mb-3">
					<h6 className="col-3 col-md-2 offset-md-2">
						Age: {userdata?.data?.data?.age}
					</h6>
					<h6 className="col-4 col-md-2 offset-md-1">
						Sex: {userdata?.data?.data?.gender}
					</h6>
					<h6 className="col-5 col-md-2 offset-md-1">
						Contact: {userdata?.data?.data?.mobilenumber}
					</h6>
				</div>
			</div>
			<div className="card container mt-2">
				<div className="row mt-5 mb-3">
					<h6 className="col-md-2 offset-md-2">
						Choose Filter
						<span className="text-danger">
							<b>*</b>
						</span>
					</h6>
					<DropdownButton
						className="col-4 col-md-2 btn"
						key="down-centered"
						id={`dropdown-button-drop-down-centered`}
						drop="down-centered"
						variant="success"
						title={filterOption}
						onSelect={handleFilterSelect}
					>
						<Dropdown.Item eventKey={"Date"} onClick={handleDateSelect}>
							{"Date"}
						</Dropdown.Item>
						<Dropdown.Item eventKey={"Category"} onClick={handleCategorySelect}>
							{"Category"}
						</Dropdown.Item>
					</DropdownButton>
					<DropdownButton
						className={categoryDropdown}
						key="down-centered"
						id={`dropdown-button-drop-down-centered`}
						drop="down-centered"
						variant="success"
						title={categoryOption}
						onSelect={handleCategoryNameSelect}
					>
						{/* {userdata?.data?.data?.category?.map((categories, index) => (
							<Dropdown.Item eventKey={categories}>{categories}</Dropdown.Item>
						))} */}
					</DropdownButton>
				</div>
			</div>
			<div className="card container mt-2">
				{scheduledObj?.message !== undefined && (
					<h6 className="text-danger header-center">
						You have requested the user for a scheduled appointment on
						{" " +
							`${scheduledObj?.date
								.toString()
								.substr(6, 2)}-${scheduledObj?.date
								.toString()
								.substr(4, 2)}-${scheduledObj?.date
								.toString()
								.substr(0, 4)}`}{" "}
						for the reason :{" " + scheduledObj?.message}
					</h6>
				)}
				{location.state?.date === curDate && userdata?.data?.data?.isBooked ? (
					<div>
						<div className="row mt-5 mb-3">
							<h6 className="col-3 col-md-2 offset-md-2">Date</h6>
							<h6 className="col-3 col-md-2">Doctor</h6>
						</div>

						<div className="row mt-2 mb-2">
							<h6 className="col-3 col-md-2 offset-md-2">{`${day}-${month}-${year}`}</h6>
							<h6 className="col-3 col-md-2">{location.state.docname}</h6>

							<button
								className="col-3 col-md-2 btn btn-success"
								onClick={() => startPrescription()}
							>
								Start Prescription
							</button>
						</div>
					</div>
				) : (
					<div> </div>
				)}
			</div>

			<div className="card container mt-2">
				<h4 className="center">Patients History</h4>
				<div className="row mt-5 mb-3">
					<h6 className="col-3 col-md-2">Date</h6>
					<h6 className="col-3 col-md-2">Doctor</h6>
					<h6 className="col-3 col-md-2">Category</h6>
					<h6 className="col-3 col-md-5">Issue</h6>
				</div>
				{/* {location.state?.date.split("-")[0].trim() == curDate &&
        userdata?.data?.data?.isBooked ? (
          <div className="row mt-2 mb-2">
            <h6 className="col-3 col-md-2">{location.state.date}</h6>
            <h6 className="col-3 col-md-2">{location.state.docname}</h6>
            <h6 className="col-5 col-md-6"></h6>
            <button
              className="col-3 col-md-2 btn btn-success"
              onClick={() => startPrescription()}
            >
              Start Prescription
            </button>
          </div>
        ) : (
          <div> </div>
        )} */}
				{filterOption === "Date" &&
					prescriptionByDate?.map((pres, index) => (
						<div className="row mt-2 mb-2">
							<h6 className="col-3 col-md-2">
								{`${pres.bookedDate.toString().substr(6, 2)}-${pres.bookedDate
									.toString()
									.substr(4, 2)}-${pres.bookedDate.toString().substr(0, 4)}`}
							</h6>
							<h6 className="col-3 col-md-2">{pres?.doctorName}</h6>
							<h6 className="col-3 col-md-2">{pres?.category}</h6>
							<h6 className="col-3 col-md-5 d-block d-md-none">
								{" "}
								{pres?.title.slice(0, 20)}. . .
							</h6>
							<h6 className="col-3 col-md-5 d-none d-md-block">
								{" "}
								{pres?.title.slice(0, 105)}. . .
							</h6>
							<button
								className="col-12 col-md-4 offset-md-4 btn btn-secondary"
								onClick={() =>
									viewBtn(
										pres?.prescriptionId,
										userdata?.data?.data?.age,
										userdata?.data?.data?.gender,
										userdata?.data?.data?.vitals[0]?.vitalValue,
										location.state?.name
									)
								}
							>
								View Prescription
							</button>
						</div>
					))}
				{filterOption === "Category" &&
					userdata?.data?.data?.prescCategory[ind]?.prescriptionIds?.map(
						(pres, index) => (
							<div className="row mt-2 mb-2">
								<h6 className="col-3 col-md-2">
									{`${pres.bookedDate.toString().substr(6, 2)}-${pres.bookedDate
										.toString()
										.substr(4, 2)}-${pres.bookedDate.toString().substr(0, 4)}`}
								</h6>
								<h6 className="col-3 col-md-2">{pres?.doctorName}</h6>
								<h6 className="col-3 col-md-2">
									{userdata?.data?.data?.prescCategory[ind]?.category}
								</h6>
								<h6 className="col-3 col-md-5 d-block d-md-none">
									{" "}
									{pres?.title.slice(0, 20)}. . .
								</h6>
								<h6 className="col-3 col-md-5 d-none d-md-block">
									{" "}
									{pres?.title.slice(0, 105)}. . .
								</h6>
								<button
									className="col-12 col-md-4 offset-md-4 btn btn-secondary"
									onClick={() =>
										viewBtn(
											pres?.prescriptionId,
											userdata?.data?.data?.age,
											userdata?.data?.data?.gender,
											userdata?.data?.data?.vitals[0]?.vitalValue,
											location.state?.name
										)
									}
								>
									View Prescription
								</button>
							</div>
						)
					)}
			</div>
		</div>
	);
};

export default GetUserPrescriptionsScreen;
