import React from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import "../styles/aboutus.css";
import Meta from "../Components/Meta";
const AboutUs = () => {
	return (
		<>
			<Meta />
			<Container>
				<div className="aboutus mt-5">
					<Row className="mt-4">
						<h3>About Clinic and Doctor </h3>{" "}
					</Row>
					<Row className="mt-4">
						<p className="justify-content-center">
							Bhuvan's Homeopathy, where the healing touch of nature meets the
							expertise of Dr. Pathuri Rajashekhar Reddy, a seasoned homeopathy
							practitioner with a passion for holistic well-being. With over two
							decades of dedicated service, Dr. Reddy brings forth a wealth of
							knowledge and experience to help you embark on a journey towards
							optimal health and vitality. At Bhuvan's Homeopathy, we believe in
							the innate power of the human body to heal itself when provided
							with the right support and guidance. Founded by Dr. Reddy in 1995,
							our clinic has been a beacon of hope for countless individuals
							seeking natural remedies for various ailments. Situated in the
							serene locale of Kamareddy, Telangana, our clinic serves as a
							sanctuary where patients can find solace and relief from their
							health concerns. Dr. Reddy's journey into the realm of homeopathy
							began with a profound desire to make a difference in people's
							lives. Armed with a degree in BHMS from the esteemed Nagpur
							Homeopathy College, he embarked on a mission to unravel the
							mysteries of alternative medicine and its profound impact on human
							health. Over the years, he has honed his skills and specialized in
							treating a wide range of conditions, including asthma, kidney
							stones, thyroid disorders, skin diseases, and stomach ailments.
							What sets Bhuvan's Homeopathy apart is our personalized approach
							to healing. Dr. Reddy understands that every individual is unique,
							and as such, their treatment should be tailored to address their
							specific needs and concerns. Through thorough consultations and
							in-depth evaluations, he delves deep into the root cause of your
							ailment, aiming not just to alleviate symptoms but to restore
							balance and harmony to your body, mind, and spirit. Central to our
							practice is the principle of natural healing. Homeopathy, with its
							gentle yet effective remedies derived from natural sources, offers
							a safe and non-invasive alternative to conventional medicine. Dr.
							Reddy harnesses the power of these remedies to stimulate the
							body's innate healing mechanisms, promoting long-lasting wellness
							from within. But our commitment to your well-being extends beyond
							the confines of our clinic walls. At Bhuvan's Homeopathy, we
							strive to empower our patients with the knowledge and tools they
							need to take control of their health and lead fulfilling lives.
							Through educational resources, lifestyle guidance, and ongoing
							support, we aim to foster a culture of wellness that transcends
							mere treatment and embraces holistic living. As we look towards
							the future, our vision for Bhuvan's Homeopathy remains
							unwavering—to be a beacon of hope and healing for all who seek it.
							Whether you're struggling with a chronic condition or simply
							seeking to optimize your health, we invite you to embark on a
							journey with us towards renewed vitality and well-being. Come,
							experience the transformative power of homeopathy at Bhuvan's
							Homeopathy, where healing is not just a destination but a way of
							life. Book your appointment today and take the first step towards
							a healthier, happier you.
						</p>
					</Row>
					<Row className="mt-4">
						<h3>Clinic Gallery</h3>
					</Row>
					<Row className="mt-4">
						<Col className="mr-5 ">
							<img src="/rajashekhar.jpg" height="80%" width="140%" />
						</Col>
						<Col className="mr-5 ml-5">
							<img src="/bhuvans_clinic.jpg" height="80%" width="120%" />
						</Col>
						<Col>
							<img src="/bhuvans_clinic2.jpg" height="80%" width="120%" />
						</Col>
					</Row>
					<Row>
						<h3>Services</h3>
					</Row>
					<Row className="mt-4 justify-content-center">
						<Col>
							<ul>
								<li>Asthma Treatment</li>
								<li>Kidney Stones Treatment</li>
								<li>Thyroid Treatment</li>
								<li>Skin Disease Treatment</li>
							</ul>
						</Col>
						<Col>
							<ul>
								<li>Cold & Cough Treatment</li>
								<li>Stomach Ache Treatment</li>
								<li>Knee & Joint Pain Treatment</li>
								<li>Obesity Treatment</li>
							</ul>
						</Col>
					</Row>
				</div>
			</Container>
			<footer className="bg-dark text-light py-4">
				<Container>
					<Row>
						<Col md={4}>
							<h5>Get in Touch</h5>
							<p>Phone: 9494260222</p>
						</Col>
						<Col md={4}>
							<h5>Location</h5>
							<p>
								<a
									href="https://www.google.com/maps?s=web&rlz=1C1CHBF_enIN1028IN1028&vet=12ahUKEwinleL51-CFAxW1RmcHHQM1B44Q5OUKegQIHhAN..i&cs=0&um=1&ie=UTF-8&fb=1&gl=in&sa=X&geocode=Kd21-c2iScw7MXPw3U_2rYZr&daddr=88GH%2BC2W,+Kamareddy,+Telangana+503111"
									target="_blank"
									rel="noopener noreferrer"
								>
									Tirumala Apartments Old Saibaba Temple Road Behind New Bus
									stand Area NGO'S Colony
								</a>
							</p>
						</Col>
						<Col md={4}>
							<h5>Follow Us</h5>
							<a
								className="btn btn-social-icon btn-google"
								href="http://google.com/+"
							>
								<i className="icon-home fab fa-google-plus-square fa-lg"></i>
							</a>
							<a
								className="btn btn-social-icon btn-facebook"
								href="http://www.facebook.com/profile.php?id="
							>
								<i className="icon-home fab fa-facebook-square fa-lg"></i>
							</a>
							<a
								className="btn btn-social-icon btn-linkedin"
								href="http://www.linkedin.com/in/"
							>
								<i className="icon-home fab fa-linkedin fa-lg"></i>
							</a>
							<a
								className="btn btn-social-icon btn-twitter"
								href="http://twitter.com/"
							>
								<i className="icon-home fab fa-twitter fa-lg"></i>
							</a>
							<a
								className="btn btn-social-icon btn-google"
								href="http://youtube.com/"
							>
								<i className="icon-home fab fa-youtube fa-lg"></i>
							</a>
							<a className="btn btn-social-icon" href="mailto:">
								<i className="icon-home fas fa-envelope fa-lg"></i>
							</a>
						</Col>
					</Row>
					<Row>
						<Col>
							<p className="text-center mt-4 mb-0">
								&copy; Copyright 2024 HextGen
							</p>
						</Col>
					</Row>
				</Container>
			</footer>
		</>
	);
};

export default AboutUs;
