import { createSlice } from "@reduxjs/toolkit";

const deleteReceptionReducer = createSlice({
  name: "deleteReception",
  initialState: {},
  reducers: {
    deleteReceptionRequest: (state) => {
      state.loading = true;
    },
    deleteReceptionSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.deleteReception = action.payload;
      state.error = false;
      state.success = true;
    },
    deleteReceptionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteReceptionReset: (state) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  deleteReceptionRequest,
  deleteReceptionSuccess,
  deleteReceptionFailure,
  deleteReceptionReset,
} = deleteReceptionReducer.actions;
export default deleteReceptionReducer.reducer;
