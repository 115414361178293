/** @format */

import { createSlice } from "@reduxjs/toolkit";

const changeBookingPeriod = createSlice({
	name: "changeBookingPeriod",
	initialState: {},
	reducers: {
		changeBookingPeriodStart: (state) => {
			state.loading = true;
		},
		changeBookingPeriodSuccess: (state, action) => {
			state.loading = false;
			state.Leave = action.payload;
			state.error = false;
			state.success = true;
		},
		changeBookingPeriodFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		changeBookingPeriodReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
	},
});

export const {
	changeBookingPeriodStart,
	changeBookingPeriodSuccess,
	changeBookingPeriodFailure,
	changeBookingPeriodReset,
} = changeBookingPeriod.actions;
export default changeBookingPeriod.reducer;
