/** @format */

import { createSlice } from "@reduxjs/toolkit";

const createTemplate = createSlice({
  name: "createTemplate",
  initialState: {},
  reducers: {
    createTemplateStart: (state) => {
      state.loading = true;
    },
    createTemplateSuccess: (state, action) => {
      state.loading = false;
      state.template = action.payload;
      state.error = false;
      state.success = true;
    },
    createTemplateFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createTemplateReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  createTemplateStart,
  createTemplateSuccess,
  createTemplateFailure,
  createTemplateReset,
} = createTemplate.actions;
export default createTemplate.reducer;
