import { createSlice } from "@reduxjs/toolkit";

const getTemplateNames = createSlice({
  name: "getTemplateNames",
  initialState: {},
  reducers: {
    getTemplateNamesRequest: (state) => {
      state.loading = true;
    },
    getTemplateNamesSuccess: (state, action) => {
      state.loading = false;
      state.templatenames = action.payload;
      state.error = false;
      state.success = true;
    },
    getTemplateNamesFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getTemplateNamesReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  getTemplateNamesRequest,
  getTemplateNamesSuccess,
  getTemplateNamesFailure,
  getTemplateNamesReset,
} = getTemplateNames.actions;
export default getTemplateNames.reducer;
