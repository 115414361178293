/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Errortemplate from "../../../Components/Errortemplate";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { getUserDataById } from "../../../redux/prescriptionRedux/prescriptionApiCalls";
import {
	//
	getTimeslots, //
} from "../../../redux/userApiCalls";
import { editUser } from "../../../redux/doctorRedux/doctorApiCalls";
import { Col, Row, Container } from "react-bootstrap";
import {
	getUserDataByCategory, //
	//
} from "../../../redux/adminRedux/adminApiCalls";
import Message from "../../../Components/Message";
// import {bookingListScreen} from "../redux/userApiCalls";
import { getUserDataByCategoryReset } from "../../../redux/adminRedux/User_Management/getUserDataByCategory";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../../redux/allMessages";
import { useParams } from "react-router-dom";

const EditPatientScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	//success and failure messages
	const { userData, error, loading, success } = useSelector(
		(state) => state.getUserdataByCategory
	);

	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	console.log(error);
	const [mno, setMno] = useState("");
	const [editClicked, setEditClicked] = useState(false);
	const [searchClicked, setSearchClicked] = useState(false);

	const data2 = useSelector((state) => state.getUserDataInSearchPatient);
	const searchLoading = data2.loading;
	const searchUserData = data2.userData;
	const searchError = data2.error;

	const handleSearchPatient = (e) => {
		e.preventDefault();
		dispatch(getUserDataByCategory(mno, clinicName));
		setSearchClicked(true);
	};
	const [showPopup, setShowPopup] = useState(false);
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log("error");
		dispatch(getUserDataByCategoryReset());
	};

	let bloodgroupflag = false;
	const BloodGrouphandler = (eventKey) => {
		setBloodGroup(eventKey);
		bloodgroupflag = true;
	};
	const blood_groups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-", "NA"];

	let users_array = [];
	if (mno !== "") {
		users_array.push({
			name: userData?.data?.data?.name,
			dob: userData?.data?.data?.dob,
			gender: userData?.data?.data?.gender,
			id: userData?.data?.data?._id,
			bid: userData?.data?.data?.bookingId,
			vitals: userData?.data?.data?.vitals,
		});
		for (
			let i = 0;
			i < userData?.data?.data?.registered_patients_array?.length;
			i++
		) {
			users_array.push({
				name: userData?.data?.data?.registered_patients_array[i]?.name,
				id: userData?.data?.data?._id,
				bid: userData?.data?.data?.registered_patients_array[i]?.bookingId,
				dob: userData?.data?.data?.registered_patients_array[i]?.dob,
				gender: userData?.data?.data?.registered_patients_array[i]?.gender,
				vitals: userData?.data?.data?.registered_patients_array[i]?.vitals,
			});
		}
	}

	const [newMno, setNewMno] = useState("");
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [dob, setDob] = useState("");
	const [address, setAddress] = useState("");
	const [activeUser, setActiveUser] = useState(null);
	const [bloodGroup, setBloodGroup] = useState("");
	const [uname, setUname] = useState("");
	const [userOptionClicked, setUserOptionClicked] = useState(false);
	const handelUserSelect = (index) => {
		setNewMno(userData?.data?.data?.mobilenumber);
		setName(users_array[index]?.name);
		setUname(users_array[index]?.name);
		setGender(users_array[index]?.gender);
		setDob(users_array[index]?.dob);
		setAddress(userData?.data?.data?.address);
		for (let i = 0; i < users_array[index]?.vitals?.length; i++) {
			if (users_array[index]?.vitals[i]?.vitalName === "BloodGroup") {
				setBloodGroup(users_array[index]?.vitals[i]?.vitalValue);
			}
		}
		setUserOptionClicked(true);
		setActiveUser(index);
	};

	const submitHandler = () => {
		console.log(activeUser);
		var letters = /^[A-Za-z\s]+$/;
		if (name === "") {
			alert("Username cannot be empty");
		} else if (!name.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (gender === "") {
			alert("Please select gender, gender cannot be empty");
		} else if (dob === "") {
			alert("Please select dob, dob cannot be empty");
		} else if (address === "") {
			alert("Address cannot be empty");
		} else {
			alert("User edited successfully");
			console.log(name);
			dispatch(
				editUser(
					userData?.data?.data?._id,
					name,
					newMno,
					gender,
					dob,
					address,
					activeUser - 1,
					activeUser - 1 === -1 ? 0 : 1,
					clinicName,
					bloodGroup
				)
			);
			setName("");
			setGender("");
			setDob("");
			setAddress("");
			setNewMno("");
		}
		console.log(name, newMno, gender, dob, address);
		setEditClicked(false);
		setSearchClicked(false);
		setUserOptionClicked(false);
	};

	const handleDiscard = () => {
		setNewMno(userData?.data?.data?.mobilenumber);
		setName(users_array[activeUser]?.name);
		setGender(users_array[activeUser]?.gender);
		setDob(users_array[activeUser]?.dob);
		setAddress(userData?.data?.data?.address);
		setUserOptionClicked(true);
		setEditClicked(false);
	};

	console.log(userData);
	const ISTOffset = 330; // 5 hours and 30 minutes in minutes
	const todayInIST = new Date(new Date().getTime() + ISTOffset * 60000)
		.toISOString()
		.split("T")[0];

	return (
		<div className="pageSize">
			<Meta />
			{searchClicked && error && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}

			<form className="form-group" onSubmit={handleSearchPatient}>
				<Container className="header-center mt-5">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center mt-1">
							<h6>
								Enter Patient's Mobile Number{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<input
								className="form-control"
								type="text"
								placeholder="9999999999"
								id="mobilenumber"
								required="required"
								onChange={(e) => {
									setMno(e.target.value);
								}}
								minlength="10"
								maxlength="10"
								pattern="^[0-9]*$"
							/>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<button type="submit" className="btn btn-primary">
								Search
							</button>
						</Col>
					</Row>
				</Container>
			</form>
			{searchClicked && !error && (
				<Container className="header-center mt-5">
					<Row className="justify-content-end">
						<Col md="auto" className="text-center mt-1">
							<button
								className="btn btn-outline-success"
								onClick={() => setEditClicked(true)}
							>
								<i className="fa fa-edit mt-2"></i>
								{"  "}Edit
							</button>
						</Col>
					</Row>
				</Container>
			)}

			<div className="row offset-md-2">
				{!error &&
					searchClicked &&
					userData &&
					users_array.map((users, index) =>
						users.name === uname ? (
							<button
								onClick={() => handelUserSelect(index)}
								className="btn btn-secondary col-md-3 m-1"
							>
								{users.name}
							</button>
						) : (
							<button
								onClick={() => handelUserSelect(index)}
								className="btn btn-outline-secondary col-md-3 m-1"
							>
								{users.name}
							</button>
						)
					)}
			</div>

			{userOptionClicked && searchClicked && !error ? (
				<div className="card col-12 col-md-10 offset-md-1 mt-2 mb-3">
					<div class="card-body">
						<div className="card-text">
							<div className="row mt-2">
								<div className="col-4 col-md-2 cardHeading">Mobile Number:</div>
								{editClicked ? (
									<input
										type="text"
										className="col-8 form-control"
										value={newMno}
										onChange={(e) => setNewMno(e.target.value)}
										disabled={activeUser !== 0}
									/>
								) : (
									<div className="col-8">{newMno}</div>
								)}
							</div>
							<div className="row mt-2">
								<div className="col-4 col-md-2 cardHeading">Name:</div>
								{editClicked ? (
									<input
										type="text"
										className="col-8 form-control"
										value={name}
										onChange={(e) => setName(e.target.value)}
										disabled
									/>
								) : (
									<div className="col-8">{name}</div>
								)}
							</div>
							<div className="row mt-2">
								<div className="col-4 col-md-2 cardHeading">Gender:</div>
								{editClicked ? (
									// <input
									// 	type="text"
									// 	className="col-8 form-control"
									// 	value={gender}
									// 	onChange={(e) => setGender(e.target.value)}
									// />
									<div>
										<label>
											<input
												type="radio"
												value="male"
												checked={gender === "Male"}
												onChange={() => setGender("Male")}
											/>
											Male
										</label>

										<label>
											<input
												type="radio"
												value="female"
												checked={gender === "Female"}
												onChange={() => setGender("Female")}
											/>
											Female
										</label>

										{/* Add more checkboxes for other genders if needed */}
									</div>
								) : (
									<div className="col-8">{gender}</div>
								)}
							</div>
							<div className="row mt-2">
								<div className="col-4 col-md-2 cardHeading">Date Of Birth:</div>
								{editClicked ? (
									<DropdownButton
										key="down-centered"
										id={`dropdown-button-drop-down-centered`}
										drop="down-centered"
										variant="success"
										title={bloodGroup}
										onSelect={BloodGrouphandler}
										required
									>
										{blood_groups.map((blood) => (
											<Dropdown.Item eventKey={blood}>{blood}</Dropdown.Item>
										))}
									</DropdownButton>
								) : (
									<div className="col-8">{bloodGroup}</div>
								)}
							</div>
							<div className="row mt-2">
								<div className="col-4 col-md-2 cardHeading">Date Of Birth:</div>
								{editClicked ? (
									<input
										type="date"
										className="col-8 form-control"
										value={dob}
										onChange={(e) => setDob(e.target.value)}
										max={todayInIST}
									/>
								) : (
									<div className="col-8">{dob}</div>
								)}
							</div>
							<div className="row mt-2">
								<div className="col-4 col-md-2 cardHeading">Address:</div>
								{editClicked ? (
									<input
										type="text"
										className="col-8 form-control"
										value={address}
										onChange={(e) => setAddress(e.target.value)}
										disabled={activeUser !== 0}
									/>
								) : (
									<div className="col-8">{address}</div>
								)}
							</div>
							{/*<div className="row mt-2">
                {editClicked && (
                  <button
                    onClick={submitHandler}
                    className="offset-4 offset-md-1 btn btn-primary"
                  >
                    Submit
                  </button>
                )}
              </div>
                */}
							{editClicked && (
								<div className="row mt-2">
									<button
										className="btn btn-danger col-4 offset-1 col-md-2 offset-md-4"
										onClick={handleDiscard}
									>
										Discard Changes
									</button>
									<button
										className="btn btn-success col-4 col-md-2 offset-1"
										onClick={submitHandler}
									>
										Submit Changes
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			) : (
				<div></div>
			)}
		</div>
	);
};

export default EditPatientScreen;
