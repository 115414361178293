/** @format */

import { createSlice } from "@reduxjs/toolkit";
const doctorItemsFromStorage = JSON.parse(localStorage.getItem("persist:root"))
	? JSON.parse(localStorage.getItem("persist:root"))?.doctorLogin
	: {};

const doctor = createSlice({
	name: "doctor",
	initialState: doctorItemsFromStorage,
	reducers: {
		resetPasswordStart: (state) => {
			state.loading = true;
		},
		resetPasswordSuccess: (state, action) => {
			state.loading = false;
			//state.doctorInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		resetPasswordFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		resetPasswordReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
		logout: (state) => {
			state.doctorInfo = {};
		},
	},
});

export const {
	resetPasswordStart,
	resetPasswordSuccess,
	resetPasswordFailure,
	logout,
	resetPasswordReset,
} = doctor.actions;
export default doctor.reducer;
