/** @format */

import { createSlice } from "@reduxjs/toolkit";
const receptionItemsFromStorage = JSON.parse(
  localStorage.getItem("persist:root")
)
  ? JSON.parse(localStorage.getItem("persist:root"))?.receptionLogin
  : {};

const reception = createSlice({
  name: "reception",
  initialState: receptionItemsFromStorage,
  reducers: {
    forgotPasswordStart: (state) => {
      state.loading = true;
    },
    forgotPasswordSuccess: (state, action) => {
      state.loading = false;
      //state.receptionInfo = action.payload;
      state.error = false;
      state.success = true;
    },
    forgotPasswordFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    forgotPasswordReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
    logout: (state) => {
      state.receptionInfo = {};
    },
  },
});

export const {
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  logout,
  forgotPasswordReset,
} = reception.actions;
export default reception.reducer;
