/** @format */

import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { Container, Row, Col } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataByCategory } from "../redux/adminRedux/adminApiCalls";
import { updateUserProfileByAdmin } from "../redux/userApiCalls";
import { getUserDataByCategoryReset } from "../redux/adminRedux/User_Management/getUserDataByCategory";
import { userUpdateProfileReset } from "../redux/userUpdate";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../redux/allMessages";
import Message from "./Message";
import Loader from "./Loader";
import { useParams } from "react-router-dom";

const AddUser = () => {
	const dispatch = useDispatch();
	const { clinicName } = useParams();
	const data1 = useSelector((state) => state.getUserdataByCategory);
	const { error, loading, success } = data1;
	const adduserinfo = useSelector((state) => state.userUpdate);
	const adduserinfoerror = adduserinfo.error;
	const adduserinfosuccess = adduserinfo.success;
	const adduserinfoloading = adduserinfo.loading;
	const [mno, setMno] = useState("");
	const blood_groups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-", "NA"];
	const [name, setName] = useState("");
	const [gender, setGender] = useState("");
	const [dob, setdob] = useState(new Date());
	const [bloodGroup, setBloodGroup] = useState("Select a blood group");
	const handleBloodGroup = (eventKey) => {
		setBloodGroup(eventKey);
	};
	const handleSearchPatient = (e) => {
		e.preventDefault();
		dispatch(getUserDataByCategory(mno, clinicName));
	};
	const addUserOnSubmit = (e) => {
		e.preventDefault();
		console.log(name + " " + gender + " " + dob + " " + mno);
		var letters = /^[A-Za-z\s]+$/;
		if (name === "") {
			alert("Username cannot be empty");
		} else if (!name.match(letters)) {
			alert("Username must contain only alphabets");
		} else if (gender === "") {
			alert("Please select gender, gender cannot be empty");
		} else if (dob === "") {
			alert("Please select dob, dob cannot be empty");
		} else if (new Date(dob) >= new Date(new Date())) {
			alert("Please select proper dob, dob should not exceed todays date");
		} else {
			if (bloodGroup == "Select a blood group") {
				dispatch(
					updateUserProfileByAdmin(
						name.trim(),
						gender,
						dob,
						mno,
						"NA",
						clinicName
					)
				);
			} else {
				dispatch(
					updateUserProfileByAdmin(
						name.trim(),
						gender,
						dob,
						mno,
						bloodGroup,
						clinicName
					)
				);
			}
			setName("");
			setGender("");
			setdob("");
			setBloodGroup("Select a blood group");
		}
	};
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const [showPopup, setShowPopup] = React.useState(false);

	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log("error");
		dispatch(getUserDataByCategoryReset());
	};
	const [showPopup1, setShowPopup1] = React.useState(false);

	const toggleShowInfoPopup1 = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log("error");
		dispatch(userUpdateProfileReset());
	};
	const ISTOffset = 330; // 5 hours and 30 minutes in minutes
	const todayInIST = new Date(new Date().getTime() + ISTOffset * 60000)
		.toISOString()
		.split("T")[0];
	return (
		<div className="pageSize">
			{error && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{error}
				</Message>
			)}
			{adduserinfoerror && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup1}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{adduserinfoerror}
				</Message>
			)}
			{(loading || adduserinfoloading) && <Loader />}
			<form className="form-group" onSubmit={handleSearchPatient}>
				<Container className="header-center mt-5">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center mt-1">
							<h6>
								Enter Patient's Mobile Number{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<input
								className="form-control"
								type="text"
								placeholder="9999999999"
								id="mobilenumber"
								required="required"
								onChange={(e) => {
									setMno(e.target.value);
								}}
								minlength="10"
								maxlength="10"
								pattern="^[0-9]*$"
							/>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<button type="submit" className="btn btn-primary">
								Search
							</button>
						</Col>
					</Row>
				</Container>
			</form>

			<Container>
				<div className="card col-12 col-md-10 offset-md-1 mt-2 mb-3">
					<div class="card-body offset-md-3">
						<div className="card-text">
							<form className="form-group" onSubmit={addUserOnSubmit}>
								<div className="row mt-3">
									<div className="col-4 col-md-3 mt-2 cardHeading">Name:</div>
									<input
										className="form-control col-8 col-md-4"
										id="name"
										type="name"
										required="required"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</div>
								<div className="row mt-3">
									<div className="col-4 col-md-3 mt-2 cardHeading">Gender:</div>
									<div className="row">
										<b>
											<Form.Check
												onChange={() => {
													setGender("Male");
												}}
												checked={gender === "Male"}
												className="form-check col-5 col-md-4"
												inline
												label="Male"
												type="radio"
												id={`inline-radio-1`}
											/>
											<Form.Check
												onChange={() => {
													setGender("Female");
												}}
												checked={gender === "Female"}
												inline
												label="Female"
												className="form-check col-5 col-md-4"
												type="radio"
												id={`inline-radio-2`}
											/>
										</b>
									</div>
								</div>
								<div className="row mt-3">
									<div className="col-4 col-md-3 mt-2 cardHeading">
										Date of Birth:
									</div>
									<input
										type="date"
										className="col-md-4 form-control"
										selected={dob}
										value={dob}
										onChange={(e) => setdob(e.target.value)}
										max={todayInIST}
									/>
								</div>
								<div className="row mt-3">
									<div className="col-4 col-md-3 mt-2 cardHeading">
										Blood Group:
									</div>
									<DropdownButton
										className="col-md-1 btn"
										key="down-centered"
										id={`dropdown-button-drop-down-centered`}
										drop="down-centered"
										variant="success"
										title={bloodGroup}
										onSelect={handleBloodGroup}
									>
										{blood_groups.map((blood) => (
											<Dropdown.Item eventKey={blood}>{blood}</Dropdown.Item>
										))}
									</DropdownButton>
								</div>
								<div>
									<Button
										className="offset-5 offset-md-4 mt-3 btn"
										type="submit"
										// onClick={() => setLgShow(false)}
									>
										Add
									</Button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default AddUser;
