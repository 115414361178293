/** @format */

import React, { useState } from "react";
import { Alert } from "reactstrap";

const Message = ({ variant, children }) => {
	//   const [visible, setVisible] = useState(true);

	// const onDismiss = () => setVisible(false);

	// return (
	//   <Alert class="alert alert-danger alert-dismissible fade show" color={variant} isOpen={visible} toggle={onDismiss}>

	//     {children}
	//   </Alert>
	// )
	const [showPopup, setShowPopup] = React.useState(false);

	const toggleShowInfoPopup = () => {
		setShowPopup(!showPopup);
	};

	return (
		<>
			{(() => {
				if (!showPopup) {
					return (
						<div
							className={`alert alert-${variant} alert-dismissible`}
							role="alert"
						>
							{children}
						</div>
					);
				}
			})()}
		</>
	);
};

Message.defaultProps = {
	variant: "danger",
};

export default Message;
