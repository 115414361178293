/** @format */

import Message from "../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import { useNavigate, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import ReactDOM from "react-dom";
//API calls
import {
	getCategoriesVitals, //
	createPrescription, //
	getUserDataById,
	onSpotcreatePrescription,
} from "../../redux/prescriptionRedux/prescriptionApiCalls";
import { createCategory } from "../../redux/userApiCalls"; //
import {
	getTemplate1, //
	getTemplateNames, //
} from "../../redux/doctorRedux/doctorApiCalls";
// Reset calls

import { createCategoryReset } from "../../redux/createCategory";
import { createPrescriptionReset } from "../../redux/prescriptionRedux/createPrescription";
import { getTemplateNamesReset } from "../../redux/doctorRedux/Template_Management/getTemplateNames"; //
import { getTemplateReset } from "../../redux/doctorRedux/Template_Management/getTemplate"; //
import { getUserDataByIdReset } from "../../redux/prescriptionRedux/getUserDataById";
import { getMyIssueCategoryVitalsReset } from "../../redux/prescriptionRedux/getCategoriesVitals";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {
	getMyAppointments,
	paymentStatusUpdate,
} from "../../redux/doctorRedux/doctorApiCalls";
import { useParams } from "react-router-dom";

const DynamicRow = ({ index, values, handleChange, handleDelete }) => {
	const { clinicName } = useParams();
	const [activeBox, setActiveBox] = useState(true);
	const [Time, setTime] = useState("");
	const [M, setM] = useState("");
	const [A, setA] = useState("");
	const [N, setN] = useState("");
	const handleFreq = (value) => {
		if (value === "M") {
			if (M === "M") setM("");
			else setM("M");
		} else if (value === "A") {
			if (A === "A") setA("");
			else setA("A");
		} else if (value === "N") {
			if (N === "N") setN("");
			else setN("N");
		}
	};
	useEffect(() => {
		if (Time !== "" && (M === "M" || A === "A" || N === "N")) {
			handleChange(`dosage-${index}`, Time + "," + M + "," + A + "," + N);
		}
	}, [Time, M, A, N]);

	useEffect(() => {
		handleChange(`dosage-${index}`, "");
		setTime("");
		setM("");
		setA("");
		setN("");
	}, [activeBox]);

	useEffect(() => {
		handleChange(`duration-${index}`, "1" + " " + duration);
	}, []);

	const [duration, setDuration] = useState("Days");
	const changeDur = (eventKey) => {
		setDuration(eventKey);
		handleChange(`duration-${index}`, "1 " + eventKey);
	};
	const incr = (quan) => {
		let quan1 = Number(quan);
		quan1++;
		console.log(quan, "hi");
		handleChange(`duration-${index}`, quan1.toString() + " " + duration);
	};
	const decr = (quan) => {
		quan = Number(quan);
		quan--;
		handleChange(`duration-${index}`, quan.toString() + " " + duration);
	};

	return (
		<div id="medication" className="row mt-4 mb-2 section">
			<div className="col-md-3">
				<div className="col-md-6">Medicine:</div>
				<textarea
					rows={1}
					cols={33}
					name={`name-${index}`}
					value={values[`name-${index}`]}
					onChange={(e) => handleChange(`name-${index}`, e.target.value)}
				></textarea>
			</div>
			<div className="col-md-4 offset-md-1">
				<div className="col-md-6">Frequency:</div>
				<button
					className="btn btn-primary mr-2"
					onClick={() => setActiveBox(!activeBox)}
				>
					<i class="fas fa-sync-alt"></i>
				</button>
				{activeBox ? (
					<>
						<ButtonGroup className="col-md-2 mr-3" aria-label="Basic example">
							<Button
								onClick={() => setTime("B/F")}
								// variant="secondary"
								variant={Time === "B/F" ? "success" : "secondary"}
							>
								B/F
							</Button>
							<Button
								onClick={() => setTime("A/F")}
								variant={Time === "A/F" ? "success" : "secondary"}
							>
								A/F
							</Button>
						</ButtonGroup>
						<Button
							onClick={() => handleFreq("M")}
							className="offset-md-2 mr-1"
							variant={M === "M" ? "success" : "outline-success"}
						>
							M
						</Button>
						<Button
							onClick={() => handleFreq("A")}
							className="mr-1"
							variant={A === "A" ? "success" : "outline-success"}
						>
							A
						</Button>
						<Button
							onClick={() => handleFreq("N")}
							className="mr-1"
							variant={N === "N" ? "success" : "outline-success"}
						>
							N
						</Button>
					</>
				) : (
					<>
						<textarea
							rows={1}
							cols={33}
							type="text"
							name={`dosage-${index}`}
							value={values[`dosage-${index}`].split("@@")[1]}
							onChange={(e) =>
								handleChange(`dosage-${index}`, "O@@" + e.target.value)
							}
						></textarea>
					</>
				)}
			</div>
			<div className="col-md-3 ">
				<div className="col-md-6">Duration:</div>
				<>
					<button
						className="btn btn-primary ml-1"
						onClick={() => decr(values[`duration-${index}`]?.split(" ")[0])}
					>
						<i className="fas fa-minus"></i>
					</button>

					<input
						type="text"
						className="ml-1 mr-1 col-md-4"
						name={`duration-${index}`}
						value={values[`duration-${index}`]?.split(" ")[0]}
						onChange={(e) => handleChange(`duration-${index}`, e.target.value)}
					/>
					<button
						className="btn btn-primary mr-1"
						onClick={() => incr(values[`duration-${index}`]?.split(" ")[0])}
					>
						<i className="fas fa-plus"></i>
					</button>
					<DropdownButton
						className="col-md-1 btn"
						key="down-centered"
						id={`dropdown-button-drop-down-centered`}
						drop="down-centered"
						variant="success"
						title={duration}
						onSelect={changeDur}
					>
						<Dropdown.Item eventKey={"Days"}>{"Days"}</Dropdown.Item>
						<Dropdown.Item eventKey={"Weeks"}>{"Weeks"}</Dropdown.Item>
						<Dropdown.Item eventKey={"Months"}>{"Months"}</Dropdown.Item>
						<Dropdown.Item eventKey={"Years"}>{"Years"}</Dropdown.Item>
					</DropdownButton>
				</>
				{/* <textarea
					rows={1}
					cols={33}
					type="text"
					name={`duration-${index}`}
					value={values[`duration-${index}`]}
					onChange={(e) => handleChange(`duration-${index}`, e.target.value)}
				></textarea> */}
			</div>
			<div className="col-md-1">
				<button
					className="m-2 btn btn-danger"
					onClick={() => handleDelete(index)}
				>
					<i className="fa fa-trash fa-2x"></i>
				</button>
			</div>
		</div>
	);
};

const EditPrescriptionScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();

	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	// const { receptionInfo } = useSelector((state) => state.receptionLogin);
	//success and error
	const getTemplateNamesData = useSelector((state) => state.getTemplateNames);
	const templatenamessuccess = getTemplateNamesData.success;
	const templatenameserror = getTemplateNamesData.error;
	const templatenamesloading = getTemplateNamesData.loading;

	const gettemplatesData = useSelector((state) => state.getTemplate);
	const getTemplatessuccess = gettemplatesData.success;
	const getTemplateserror = gettemplatesData.error;
	const getTemplatesloading = gettemplatesData.loading;

	const getUserDataByIdData = useSelector((state) => state.getUserDataById);
	const getUserDataByIdsuccess = getUserDataByIdData.success;
	const getUserDataByIderror = getUserDataByIdData.error;
	const getUserDataByIdloading = getUserDataByIdData.loading;

	const { allCategoryVitals, loading, error, success } = useSelector(
		(state) => state.getCategoriesVitals
	);

	const createCategoryData = useSelector((state) => state.createCategory);
	const createCategorySuccess = createCategoryData.success;
	const createCategoryFailure = createCategoryData.error;
	const createCategoryloading = createCategoryData.loading;
	const createPrescriptionData = useSelector(
		(state) => state.createPrescription
	);

	const createPrescriptionSuccess = createPrescriptionData.success;
	const createPrescriptionError = createCategoryData.error;
	const createPrescriptionLoading = createPrescriptionData.loading;
	///////////////////////////////////////////////////////////

	const [Category, setCategory] = useState("");
	const [lgShow, setLgShow] = useState(false);
	const { userdata } = useSelector((state) => state.getUserDataById);
	console.log(JSON.stringify(location.state) + "line241");
	useEffect(() => {
		dispatch(
			getUserDataById(location.state.id, location.state.name, clinicName)
		);
	}, []);

	console.log(allCategoryVitals?.data?.data?.category);
	useEffect(() => {
		dispatch(
			getCategoriesVitals(location.state.id, location?.state?.name, clinicName)
		);
	}, [
		history,
		dispatch,
		error,
		success,
		createCategorySuccess,
		createCategoryFailure,
	]);
	console.log(allCategoryVitals);
	const addCategoryOnSubmit = (e) => {
		e.preventDefault();
		let flag = false;
		console.log(Category);
		for (let i = 0; i < allCategoryVitals?.data?.data?.category?.length; i++) {
			if (
				allCategoryVitals?.data?.data?.category[i].toLowerCase() ===
				Category?.toLowerCase()
			) {
				flag = true;
			}
		}
		console.log(Category);
		var letters = /^[A-Za-z\s]+$/;
		if (Category === "") {
			alert("Category cannot be empty");
		} else if (flag) {
			alert(Category + " already exists");
		} else {
			console.log(Category, userdata?.data?.data?.name);
			dispatch(
				createCategory(
					location.state.id,
					Category,
					location?.state?.name,
					clinicName
				)
			); // add category dispatch function
			setCategory("");
			setLgShow(false);
		}
	};

	const backButton = () => {
		history(
			`/clinic/${clinicName}/doctor/patientstobevisited/userappointment`,
			{
				state: {
					id: location.state.id,
					name: location.state.name,
					date: location.state.date,
				},
			}
		);
	};
	const [temp, setTemp] = useState("");
	const [bp, setBp] = useState("");
	const [height, setHeight] = useState("");
	const [weight, setWeight] = useState("");
	const [vitals, setVitals] = useState([]);
	const [chooseCategory, setChooseCategory] = useState("Choose Categories");
	const [title, setTitle] = useState("");
	const [summary, setSummary] = useState("");
	// const [chooseSuggestedMedicine, setChooseSuggestedMedicine] = useState("");
	const [advice, setAdvice] = useState();
	const [show, setShow] = useState(false);

	const handleCategorySelect = (eventKey) => {
		setChooseCategory(eventKey);
	};

	let vitalsarr = [];
	useEffect(() => {
		vitalsarr.push({
			vitalName: "Temperature",
			vitalValue: temp,
		});
		vitalsarr.push({
			vitalName: "Blood Pressure",
			vitalValue: bp,
		});
		vitalsarr.push({
			vitalName: "Weight",
			vitalValue: weight,
		});
		vitalsarr.push({
			vitalName: "Height",
			vitalValue: height,
		});
		setVitals(vitalsarr);
	}, [temp, bp, height, weight]);
	console.log(vitals);

	const [chooseEditTemplate, setChooseEditTemplate] =
		useState("Choose Template");
	const [chooseEditTemplateId, setChooseEditTemplateId] = useState("");
	const handleEditTemplateSelect = (eventKey) => {
		seteditTemplateclick(editTemplateclick * -1);
		setChooseEditTemplate(eventKey.split("@")[0]);
		setChooseEditTemplateId(eventKey.split("@")[1]);
		let arr = [];
		setRows(arr);
		setValues(arr);
		dispatch(
			getTemplate1(doctorInfo?.user?._id, eventKey.split("@")[1], clinicName)
		);
	};

	const templates = useSelector((state) => state.getTemplate);

	console.log(templates?.getTemplate?.data?.data?.medicines);
	let editrow = [];
	let editvalue = [];
	const [editRows, setEditRows] = useState([]);
	const [editValues, setEditValues] = useState([]);
	const [activeBoxes, setActiveBoxes] = useState([]);

	useEffect(() => {
		if (chooseEditTemplate !== "Choose Template") {
			for (
				let i = 0;
				i < templates?.getTemplate?.data?.data?.medicines?.length;
				i++
			) {
				editrow.push(i);
			}
			for (
				let i = 0;
				i < templates?.getTemplate?.data?.data?.medicines?.length;
				i++
			) {
				editvalue.push({
					name: templates?.getTemplate?.data?.data?.medicines[i]?.name,
					dosage: templates?.getTemplate?.data?.data?.medicines[i]?.dosage,
					duration: templates?.getTemplate?.data?.data?.medicines[i]?.duration,
				});
			}
			setEditRows(editrow);
			setEditValues(editvalue);
		}
	}, [templates?.getTemplate?.data?.data?.medicines]);
	console.log(editValues);

	const handleEditChange = (value, index, key) => {
		const editData = [...editValues];
		if (key === "name") editData[index].name = value;
		else if (key === "dosagebox") {
			editData[index].dosage = "";
			let val = activeBoxes;
			val[index] = !value;
			setActiveBoxes(val);
		} else if (key === "dosage") {
			if (value === "M") {
				if (editData[index].dosage?.split(",")?.includes("M")) {
					let valls = editData[index].dosage?.split(",");
					let ind = valls.indexOf("M");
					valls[ind] = "";
					editData[index].dosage = valls?.join(",");
				} else {
					let valls = editData[index].dosage?.split(",");
					valls.splice(1, 0, "M");
					editData[index].dosage = valls?.join(",");
				}
			} else if (value === "A") {
				if (editData[index].dosage?.split(",")?.includes("A")) {
					let valls = editData[index].dosage?.split(",");
					let ind = valls.indexOf("A");
					valls[ind] = "";
					editData[index].dosage = valls?.join(",");
				} else {
					let valls = editData[index].dosage?.split(",");
					valls.splice(2, 0, "A");
					editData[index].dosage = valls?.join(",");
				}
			} else if (value === "N") {
				if (editData[index].dosage?.split(",")?.includes("N")) {
					let valls = editData[index].dosage?.split(",");
					let ind = valls.indexOf("N");
					valls[ind] = "";
					editData[index].dosage = valls?.join(",");
				} else {
					let valls = editData[index].dosage?.split(",");
					valls.splice(3, 0, "N");
					editData[index].dosage = valls?.join(",");
				}
			} else {
				editData[index].dosage = value;
			}
		} else if (key === "duration") editData[index].duration = value;
		console.log(editData);
		setEditValues(editData);
	};

	const handleEditDelete = (index) => {
		const editData = [...editValues];
		if (index > -1) {
			editData.splice(index, 1);
		}
		setEditValues(editData);
		console.log(editValues);
	};

	console.log(editValues);

	const [rows, setRows] = useState([]);
	const [values, setValues] = useState({});
	console.log(rows);
	console.log(values);
	const handleChange = (name, value) => {
		// const { name, value } = event.target;
		setValues((prevValues) => ({ ...prevValues, [name]: value }));
	};

	const handleAddRow = () => {
		setRows((prevRows) => [...prevRows, Date.now()]);
	};

	// const handleChange = (name, value) => {
	// 	// const { name, value } = event.target;
	// 	setValues((prevValues) => ({ ...prevValues, [name]: value }));
	// };

	// const handleAddRow = () => {
	// 	const newItem = Date.now();
	// 	setRows((prevRows) => [...prevRows, newItem]);
	// 	handleChange(`dosage-${newItem}`, "0");
	// };
	console.log(vitals[0]?.vitalValue);
	const handleDeleteRow = (index) => {
		const updatedValues = { ...values };
		Object.keys(updatedValues)
			.filter((key) => key.includes(index))
			.forEach((key) => delete updatedValues[key]);

		setValues(updatedValues);

		setRows((prevRows) => prevRows.filter((rowIndex) => rowIndex !== index));
	};
	let returnedDate = location.state?.date;
	const submitHandler = () => {
		if (
			Object.keys(values).length % 3 !== 0 ||
			Object.keys(values).length / 3 !== rows.length
		) {
			alert("Enter all the required medication fields");
		} else if (chooseCategory === "Choose Categories") {
			alert("Enter the category");
		} else if (title === "") {
			alert("Enter the title");
		} else if (summary === "") {
			alert("Enter the summary");
		} else if (advice === "") {
			alert("Enter the advice");
		} else {
			let extractedValues = [];
			let flag = false;
			Object.keys(values).forEach((key) => {
				let id = key.replace(/-\d+$/, "");

				if (key.includes("dosage")) {
					let index = key.split("-")[1];
					let dosage = values[`dosage-${index}`];
					if (
						!dosage?.includes("A/F") &&
						!dosage?.includes("B/F") &&
						!dosage?.includes("O@@")
					) {
						flag = true;
					}
				}
			});

			if (flag) {
				alert("Please Select B/F or A/F for the entered medicines.");
			} else {
				Object.keys(values).forEach((key) => {
					let id = key.replace(/-\d+$/, "");

					if (key.includes("name")) {
						let index = key.split("-")[1];
						let name = values[`name-${index}`];
						let dosage = values[`dosage-${index}`];
						let duration = values[`duration-${index}`];

						extractedValues?.push({
							name: name,
							dosage: dosage,
							duration: duration,
						});
					}
				});

				console.log(extractedValues);
				const medicines = extractedValues;
				let updatedMed = editValues;
				for (let i = 0; i < medicines.length; i++) {
					updatedMed.push({
						name: medicines[i].name,
						dosage: medicines[i].dosage,
						duration: medicines[i].duration,
					});
				}
				if (vitals[0]?.vitalName === "") {
					vitalsarr.push({
						vitalName: "Temperature",
						vitalValue: "Not Collected",
					});
					vitalsarr.push({
						vitalName: "Blood Pressure",
						vitalValue: bp,
					});
					vitalsarr.push({
						vitalName: "Weight",
						vitalValue: weight,
					});
					vitalsarr.push({
						vitalName: "Height",
						vitalValue: height,
					});
				}
				if (vitals[1]?.vitalName === "") {
					vitalsarr.push({
						vitalName: "Temperature",
						vitalValue: temp,
					});
					vitalsarr.push({
						vitalName: "Blood Pressure",
						vitalValue: "Not Collected",
					});
					vitalsarr.push({
						vitalName: "Weight",
						vitalValue: weight,
					});
					vitalsarr.push({
						vitalName: "Height",
						vitalValue: height,
					});
				}
				if (vitals[2]?.vitalName === "") {
					vitalsarr.push({
						vitalName: "Temperature",
						vitalValue: temp,
					});
					vitalsarr.push({
						vitalName: "Blood Pressure",
						vitalValue: bp,
					});
					vitalsarr.push({
						vitalName: "Weight",
						vitalValue: "Not Collected",
					});
					vitalsarr.push({
						vitalName: "Height",
						vitalValue: height,
					});
				}
				if (vitals[3]?.vitalName === "") {
					vitalsarr.push({
						vitalName: "Temperature",
						vitalValue: temp,
					});
					vitalsarr.push({
						vitalName: "Blood Pressure",
						vitalValue: bp,
					});
					vitalsarr.push({
						vitalName: "Weight",
						vitalValue: weight,
					});
					vitalsarr.push({
						vitalName: "Height",
						vitalValue: "Not Collected",
					});
				}
				setEditValues(updatedMed);
				console.log(extractedValues);
				if (!location?.state?.hasPaid) {
					alert(
						"Payment status not updated for the current patient. Please Navigate to visited patients page to update."
					);
					// history('/doctor/patientsvisited');
				}
				setShow(true);
				debugger;
				if (location.state.onSpot) {
					// const [day, month, year] = returnedDate.split("-");
					const dateString = returnedDate.split("-");
					const year = dateString[2];
					const month =
						dateString[1]?.length === 1 ? "0" + dateString[1] : dateString[1];
					const day =
						dateString[0]?.length === 1 ? "0" + dateString[0] : dateString[0];
					const convertedDate = `${year}${month}${day}`;
					console.log(parseInt(convertedDate));
					dispatch(
						onSpotcreatePrescription(
							vitals,
							chooseCategory,
							title,
							doctorInfo?.user?._id,
							doctorInfo?.user?.name,
							summary,
							advice,
							updatedMed,
							location.state.name,
							location.state.id,
							parseInt(convertedDate),
							doctorInfo?.user?.speciality,
							clinicName
						)
					);
				} else {
					dispatch(
						createPrescription(
							vitals,
							chooseCategory,
							title,
							doctorInfo?.user?._id,
							doctorInfo?.user?.name,
							summary,
							advice,
							updatedMed,
							location.state.name,
							location.state.id,
							returnedDate,
							doctorInfo?.user?.speciality,
							clinicName
						)
					);
					clearAllData();
				}
			}
		}
	};
	console.log(JSON.stringify(location.state));
	const handleClose = () => {
		setShow(false);
		dispatch(createPrescriptionReset());
		history(`/clinic/${clinicName}/doctor/patientstobevisited`);
	};
	const [editTemplateclick, seteditTemplateclick] = useState(-1);
	useEffect(() => {
		handleAddRow();
		dispatch(getTemplateNames(doctorInfo?.user?._id, clinicName));
	}, []);

	const { templatenames } = useSelector((state) => state.getTemplateNames);
	console.log(templatenames);

	// 	useEffect(() => {
	// 	dispatch(getUserDataById(location.state.id, location.state.name));
	// }, [addCategoryOnSubmit]);
	//ALerts//////////////////////////////////////////////////////////////////

	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);
	const alertmsg = useSelector((state) => state.allMessages);

	const [showPopup, setShowPopup] = React.useState(false);
	const toggleShowInfoPopupForgetCateoryVitals = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log("error");
		dispatch(getMyIssueCategoryVitalsReset());
	};

	const [showPopup1, setShowPopup1] = React.useState(false);
	const toggleShowInfoPopupForcreateCategory = (e) => {
		setShowPopup1(!showPopup1);
		e.preventDefault();
		console.log("error");
		dispatch(createCategoryReset());
	};

	useEffect(() => {
		if (createCategorySuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(createCategoryReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [createCategorySuccess, dispatch]);

	const [showPopup2, setShowPopup2] = React.useState(false);
	const toggleShowInfoPopupForcreatePrescription = (e) => {
		setShowPopup2(!showPopup2);
		e.preventDefault();
		console.log("error");
		dispatch(createPrescriptionReset());
	};

	console.log(location.state.id);
	const handleNextSteps = () => {
		history(`/clinic/${clinicName}/doctor/nextappointment`, {
			state: {
				id: location.state.id,
				name: location.state.name,
				mno: userdata?.data?.data?.mobilenumber,
				hasPaid: location?.state?.hasPaid,
			},
		});
		dispatch(createPrescriptionReset());
	};

	const [showPopup3, setShowPopup3] = React.useState(false);
	const toggleShowInfoPopup3 = (e) => {
		setShowPopup3(!showPopup3);
		e.preventDefault();
		console.log("error");
		dispatch(getUserDataByIdReset());
	};
	const [showPopup4, setShowPopup4] = React.useState(false);
	const toggleShowInfoPopup4 = (e) => {
		setShowPopup4(!showPopup4);
		e.preventDefault();
		console.log("error");
		dispatch(getTemplateReset());
	};
	const [showPopup5, setShowPopup5] = React.useState(false);
	const toggleShowInfoPopup5 = (e) => {
		setShowPopup5(!showPopup5);
		e.preventDefault();
		console.log("error");
		dispatch(getTemplateNamesReset());
	};

	const clearAllData = () => {
		setTemp("");
		setBp("");
		setHeight("");
		setWeight("");
		setVitals([]);
		setTitle("");
		setSummary("");
		setAdvice("");
		setChooseCategory("Choose Categories");
		setChooseEditTemplate("Choose Templates");
		setRows([]);
		setValues([]);
		setEditRows([]);
		setEditValues([]);
		setActiveBoxes([]);
	};
	return (
		<div className="pageSize">
			<Meta />
			{(loading ||
				createCategoryloading ||
				createPrescriptionLoading ||
				templatenamesloading ||
				getTemplatesloading ||
				getUserDataByIdloading) && <Loader />}

			{error && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopupForgetCateoryVitals}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{error}
				</Message>
			)}

			{createCategoryFailure && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopupForcreateCategory}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{createCategoryFailure}
				</Message>
			)}

			{createPrescriptionError && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopupForcreatePrescription}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{createPrescriptionError}
				</Message>
			)}

			{getUserDataByIderror && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup3}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{getUserDataByIderror}
				</Message>
			)}
			{getTemplateserror && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup4}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{getTemplateserror}
				</Message>
			)}
			{templatenameserror && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup5}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{templatenameserror}
				</Message>
			)}
			<h4 className="patient-name mt-3 header-center">
				Patient Name: {location?.state?.name}
			</h4>
			<button className="btn offset-1 back-button" onClick={() => backButton()}>
				<i className="fa fa-angle-left custom fa-2x"></i>
			</button>
			<Container className="card mt-4">
				<div className="row mt-4 mb-2 section">
					<div className="col-md-3 offset-md-1">
						Age: {userdata?.data?.data?.age}
					</div>
					<div className="col-md-3 offset-md-1">
						Gender: {userdata?.data?.data?.gender}
					</div>
					<div className="col-md-3 offset-md-1">
						Contact: {userdata?.data?.data?.mobilenumber}
					</div>
				</div>
			</Container>
			<Container className="header-center mt-5 card">
				<Row className="mt-4 mb-4">
					<Col md="3">
						<Col md="12">Temperature (°F):</Col>
						<Col md="12">
							<input
								type="text"
								className="form-control "
								value={temp}
								onChange={(e) => setTemp(e.target.value)}
							/>
						</Col>
					</Col>
					<Col md="3">
						<Col md="12">Blood Pressure (MM/Hg):</Col>
						<Col md="12">
							<input
								type="text"
								className="form-control "
								value={bp}
								onChange={(e) => setBp(e.target.value)}
							/>
						</Col>
					</Col>
					<Col md="3">
						<Col md="12">Weight (Kg):</Col>
						<Col md="12">
							<input
								type="text"
								className="form-control "
								value={weight}
								onChange={(e) => setWeight(e.target.value)}
							/>
						</Col>
					</Col>
					<Col md="3">
						<Col md="12">Height (Cm):</Col>
						<Col md="12">
							<input
								type="text"
								className="form-control "
								value={height}
								onChange={(e) => setHeight(e.target.value)}
							/>
						</Col>
					</Col>
				</Row>
			</Container>
			{/* <Container className="card mt-4">
				<div className="row mt-4 mb-2 section">
					<div className="row col-md-3">
						<div className="col-md-7">Temperature: </div>
						<input
							type="text"
							className="form-control col-md-4"
							value={temp}
							onChange={(e) => setTemp(e.target.value)}
						/>
					</div>
					<div className="row col-md-4">
						<div className="col-md-6">Blood Pressure: </div>
						<input
							type="text"
							className="form-control col-md-3"
							value={bp}
							onChange={(e) => setBp(e.target.value)}
						/>
					</div>
					<div className="row col-md-3">
						<div className="col-md-5">Weight: </div>
						<input
							type="text"
							className="form-control col-md-4"
							value={weight}
							onChange={(e) => setWeight(e.target.value)}
						/>
					</div>
					<div className="row col-md-3">
						<div className="col-md-5">Height: </div>
						<input
							type="text"
							className="form-control col-md-4"
							value={height}
							onChange={(e) => setHeight(e.target.value)}
						/>
					</div>
				</div>
			</Container> */}
			<Container className="card mt-4">
				<div className="row mt-4 mb-2 section">
					<div className="col-md-2">Category:</div>
					<DropdownButton
						className="col-md-3 btn"
						key="down-centered"
						id={`dropdown-button-drop-down-centered`}
						drop="down-centered"
						variant="success"
						title={chooseCategory}
						onSelect={handleCategorySelect}
					>
						<div className="dropButton">
							{allCategoryVitals?.data?.data?.category?.length !== 0 ? (
								allCategoryVitals?.data?.data?.category.map(
									(category, index) => (
										<Dropdown.Item eventKey={category}>
											{category}
										</Dropdown.Item>
									)
								)
							) : (
								<Dropdown.Item disabled>No items found</Dropdown.Item>
							)}
						</div>
					</DropdownButton>
					<Button
						className="btn offset-4 offset-md-0 btn-secondary"
						onClick={() => setLgShow(true)}
					>
						Add Category
					</Button>
					<Modal
						size="lg"
						aria-labelledby="example-modal-sizes-title-lg"
						show={lgShow}
						onHide={() => setLgShow(false)}
					>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-lg">
								Add Category
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<form className="form-group" onSubmit={addCategoryOnSubmit}>
								<div className="row mt-3">
									<label className="col-3 col-md-3" htmlFor="Category">
										<b>Category </b>{" "}
										<span className="text-danger">
											<b>*</b>
										</span>
									</label>
									<input
										className="form-control col-8 col-md-8"
										id="Category"
										type="Category"
										required="required"
										value={Category}
										onChange={(e) => setCategory(e.target.value)}
									/>
								</div>
								<div>
									<Button
										className="offset-5 offset-md-6 mt-5 btn"
										type="submit"
									>
										Add
									</Button>
								</div>
							</form>
						</Modal.Body>
					</Modal>
				</div>
				<div className="row mt-4 mb-2 section">
					<div className="col-md-2">Title:</div>
					<textarea
						className="col-md-7"
						rows={3}
						onChange={(e) => setTitle(e.target.value)}
						value={title}
					></textarea>
				</div>
				<div className="row mt-4 mb-2 section">
					<div className="col-md-2">Summary:</div>
					<textarea
						className="col-md-9"
						rows={6}
						onChange={(e) => setSummary(e.target.value)}
						value={summary}
					></textarea>
				</div>
			</Container>
			<Container className="card mt-4">
				<div className="row mt-4 mb-2 section">
					<div className="col-md-4">Medication</div>
				</div>
				<div className="row mt-4 mb-2 section">
					<div className="col-md-2">Choose Template:</div>
					<DropdownButton
						className="col-md-3 btn"
						key="down-centered"
						id={`dropdown-button-drop-down-centered`}
						drop="down-centered"
						variant="success"
						title={chooseEditTemplate}
						onSelect={handleEditTemplateSelect}
					>
						<div className="dropButton">
							{templatenames?.data?.data ? (
								templatenames?.data?.data.map((name, index) => (
									<Dropdown.Item eventKey={name + "@" + index}>
										{name}
									</Dropdown.Item>
								))
							) : (
								<Dropdown.Item disabled>No items found</Dropdown.Item>
							)}
						</div>
					</DropdownButton>
				</div>
				{editValues.map((medicine, index) => (
					<div id="medication" className="row mt-4 mb-2 section">
						<div className="col-md-3">
							<div className="col-md-6">Medicine:</div>
							<textarea
								rows={1}
								cols={33}
								value={medicine?.name}
								onChange={(e) =>
									handleEditChange(e.target.value, index, "name")
								}
							></textarea>
						</div>
						<div className="col-md-4 offset-md-1">
							<div className="col-md-6">Frequency:</div>
							<button
								className="btn btn-primary mr-2"
								onClick={(e) =>
									handleEditChange(activeBoxes[index], index, "dosagebox")
								}
							>
								<i class="fas fa-sync-alt"></i>
							</button>
							{medicine?.dosage?.includes("O@@") ? (
								<textarea
									rows={1}
									type="text"
									cols={33}
									value={medicine?.dosage?.split("@@")[1]}
									onChange={(e) =>
										handleEditChange("O@@" + e.target.value, index, "dosage")
									}
								></textarea>
							) : (
								<>
									<ButtonGroup
										className="col-md-2 mr-3"
										aria-label="Basic example"
									>
										<Button
											onClick={() =>
												handleEditChange(
													"B/F," +
														medicine?.dosage?.split(",")?.slice(1)?.join(","),
													index,
													"dosage"
												)
											}
											// variant="secondary"
											variant={
												medicine?.dosage?.includes("B/F")
													? "success"
													: "secondary"
											}
										>
											B/F
										</Button>
										<Button
											onClick={() =>
												handleEditChange(
													"A/F," +
														medicine?.dosage?.split(",")?.slice(1)?.join(","),
													index,
													"dosage"
												)
											}
											variant={
												medicine?.dosage?.includes("A/F")
													? "success"
													: "secondary"
											}
										>
											A/F
										</Button>
									</ButtonGroup>
									<Button
										onClick={() => handleEditChange("M", index, "dosage")}
										className="offset-md-2 mr-1"
										variant={
											medicine?.dosage?.split(",")?.includes("M")
												? "success"
												: "outline-success"
										}
									>
										M
									</Button>
									<Button
										onClick={() => handleEditChange("A", index, "dosage")}
										className="mr-1"
										variant={
											medicine?.dosage?.split(",")?.includes("A")
												? "success"
												: "outline-success"
										}
									>
										A
									</Button>
									<Button
										onClick={() => handleEditChange("N", index, "dosage")}
										className="mr-1"
										variant={
											medicine?.dosage?.split(",")?.includes("N")
												? "success"
												: "outline-success"
										}
									>
										N
									</Button>
								</>
							)}
						</div>
						<div className="col-md-3">
							<div className="col-md-6">Duration:</div>
							<>
								<button
									className="btn btn-primary ml-1"
									onClick={() =>
										handleEditChange(
											(
												Number(medicine?.duration?.split(" ")[0]) - 1
											).toString() +
												" " +
												medicine?.duration?.split(" ")[1],
											index,
											"duration"
										)
									}
								>
									<i className="fas fa-minus"></i>
								</button>

								<input
									type="text"
									className="ml-1 mr-1 col-md-4"
									name={`duration-${index}`}
									value={medicine?.duration?.split(" ")[0]}
									onChange={(e) =>
										handleEditChange(
											e.target.value + " " + medicine?.duration?.split(" ")[1],
											index,
											"duration"
										)
									}
								/>
								<button
									className="btn btn-primary mr-1"
									onClick={() =>
										handleEditChange(
											(
												Number(medicine?.duration?.split(" ")[0]) + 1
											).toString() +
												" " +
												medicine?.duration?.split(" ")[1],
											index,
											"duration"
										)
									}
								>
									<i className="fas fa-plus"></i>
								</button>
								<DropdownButton
									className="col-md-1 btn"
									key="down-centered"
									id={`dropdown-button-drop-down-centered`}
									drop="down-centered"
									variant="success"
									title={medicine?.duration?.split(" ")[1]}
								>
									<Dropdown.Item
										eventKey={"Days"}
										onClick={() =>
											handleEditChange(
												medicine?.duration?.split(" ")[0] + " " + "Days",
												index,
												"duration"
											)
										}
									>
										{"Days"}
									</Dropdown.Item>
									<Dropdown.Item
										eventKey={"Weeks"}
										onClick={() =>
											handleEditChange(
												medicine?.duration?.split(" ")[0] + " " + "Weeks",
												index,
												"duration"
											)
										}
									>
										{"Weeks"}
									</Dropdown.Item>
									<Dropdown.Item
										eventKey={"Months"}
										onClick={() =>
											handleEditChange(
												medicine?.duration?.split(" ")[0] + " " + "Months",
												index,
												"duration"
											)
										}
									>
										{"Months"}
									</Dropdown.Item>
									<Dropdown.Item
										eventKey={"Years"}
										onClick={() =>
											handleEditChange(
												medicine?.duration?.split(" ")[0] + " " + "Years",
												index,
												"duration"
											)
										}
									>
										{"Years"}
									</Dropdown.Item>
								</DropdownButton>
							</>
							{/* <textarea
                    rows={1}
                    cols={33}
                    value={medicine?.duration}
                    onChange={(e) =>
                      handleEditChange(e.target.value, index, "duration")
                    }
                  ></textarea> */}
						</div>
						<div className="col-md-1">
							<button
								className="m-2 btn btn-danger"
								onClick={() => handleEditDelete(index)}
							>
								<i className="fa fa-trash fa-2x"></i>
							</button>
						</div>
					</div>
				))}
				{rows.map((rowIndex) => (
					<DynamicRow
						key={rowIndex}
						index={rowIndex}
						values={values}
						handleChange={handleChange}
						handleDelete={handleDeleteRow}
					/>
				))}
				<div className="row mt-4 mb-2 section">
					<button
						className="btn btn-secondary offset-md-5"
						onClick={handleAddRow}
					>
						+ Add more medicines
					</button>
				</div>
			</Container>
			<Container className="card mt-4">
				<div className="row mt-4 mb-2 section">
					<div className="col-md-2">Advice:</div>
					<textarea
						className="col-md-9"
						rows={6}
						onChange={(e) => setAdvice(e.target.value)}
						value={advice}
					></textarea>
				</div>
			</Container>
			<Container className="mt-4">
				<div className="row mt-4 mb-2 offset-md-10 section">
					<button className="btn btn-success" onClick={submitHandler}>
						Submit
					</button>
					<button className="offset-md-1 btn btn-danger" onClick={clearAllData}>
						Clear
					</button>
				</div>
			</Container>
			{createPrescriptionSuccess && (
				<Modal show={show} onHide={handleClose}>
					<Modal.Header closeButton>
						<Modal.Title>Prescription created succesfully !</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						Do you want plan a next visit/ remainder or refer to another doctor.
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Skip
						</Button>
						<Button variant="primary" onClick={handleNextSteps}>
							Continue
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</div>
	);
};

export default EditPrescriptionScreen;
