/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
// import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { logout3 } from "../redux/doctorRedux/doctorApiCalls";
import { logout4 } from "../redux/receptionRedux/receptionApiCalls";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useLocation } from "react-router-dom";
const Header = () => {
	const location = useLocation();
	const { pathname } = location;

	const currentURL = pathname;
	const pattern = /\/clinic\/([^/]+)/;
	const match = currentURL.match(pattern);
	var clinicName;
	if (match) {
		clinicName = match[1];
		console.log(clinicName); // Output: Clinic1
	} else {
		console.log("Clinic name not found in the URL");
	}
	const dispatch = useDispatch();
	const history = useNavigate();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	console.log(doctorInfo);

	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	console.log(receptionInfo);

	const [allDau, setAllDau] = useState([]);
	let carray = [];
	// console.log(user.userInfo === null ? "yes" : "no");
	const handleThisLogin = (carray) => {
		setAllDau(carray);
	};
	console.log(allDau);
	// console.log(JSON.stringify(receptionInfo));
	console.log("line 19");
	useEffect(() => {
		if (doctorInfo?.user?.name) {
			carray = [
				{
					name: "My Appointments",
					link: `/clinic/${clinicName}/doctor/patientstobevisited`,
					icon: "far fa-calendar-check", // Updated icon
					dropdownItems: [],
				},
				{
					name: "Book Appointment",
					link: `/clinic/${clinicName}/doctor/booking`,
					icon: "far fa-calendar-plus", // Updated icon
					dropdownItems: [],
				},
				{
					name: "Search Patients",
					link: `/clinic/${clinicName}/doctor/searchPatients`,
					icon: "fas fa-search", // Updated icon
					dropdownItems: [],
				},
				{
					name: "Patient Management",
					// link: "/doctor/register",
					// icon: "fas fa-user-plus",
					// Updated icon
					icon: "fas fa-hospital-user",
					dropdownItems: [
						{
							name: "Add User",
							link: `/clinic/${clinicName}/doctor/Adduser`,
							icon: "fas fa-user-plus",
						},
						{
							name: "Edit Patient",
							link: `/clinic/${clinicName}/doctor/editPatient`,
							icon: "fas fa-edit",
						},
						{
							name: "Register Patient",
							link: `/clinic/${clinicName}/register`,
							icon: "fas fa-user-plus",
						},
					],
				},
				{
					name: "Slots Management",
					// link: "/doctor/blockslots",
					icon: "far fa-clock", // Updated icon
					dropdownItems: [
						{
							name: "Block Slots",
							link: `/clinic/${clinicName}/doctor/blockslots`,
							icon: "fas fa-lock", // Updated icon
						},
						{
							name: "UnBlock Slots",
							link: `/clinic/${clinicName}/doctor/unblockslots`,
							icon: "fas fa-unlock", // Updated icon
						},
						{
							name: "Edit My Slots",
							link: `/clinic/${clinicName}/doctor/EditMySlots`,
							icon: "far fa-edit", // Updated icon
						},
						{
							name: "Edit Booking Period",
							link: `/clinic/${clinicName}/doctor/EditBookingPeriod`,
							icon: "far fa-calendar-alt", // Updated icon
						},
					],
				},
				{
					name: "Leaves Management",
					// link: "/doctor/createleaves",
					icon: "far fa-calendar-times", // Updated icon for applying leave
					dropdownItems: [
						{
							name: "Apply Leaves",
							link: `/clinic/${clinicName}/doctor/createleaves`,
							icon: "fas fa-calendar-plus", // Updated icon for applying leave
						},
						{
							name: "Cancel Leaves",
							link: `/clinic/${clinicName}/doctor/deleteleaves`,
							icon: "far fa-trash-alt", // Updated icon for deleting leave
						},
					],
				},

				{
					name: "Medicine Templates",
					// link: "/doctor/templates",
					icon: "far fa-file-alt", // Updated icon
					dropdownItems: [
						{
							name: "Add Template",
							link: `/clinic/${clinicName}/doctor/addtemplates`,
							icon: "far fa-file-alt",
						},
						{
							name: "Edit Template",
							link: `/clinic/${clinicName}/doctor/edittemplates`,
							icon: "fas fa-edit",
						},
						{
							name: "Delete Template",
							link: `/clinic/${clinicName}/doctor/deletetemplates`,
							icon: "fas fa-trash",
						},
					],
				},

				{
					name: "Reception Management",
					// link: "/doctor/receptionManagement",
					icon: "fas fa-users-cog", // Updated icon
					dropdownItems: [
						{
							name: "Add Receptionist",
							link: `/clinic/${clinicName}/doctor/addReception`,
							icon: "fas fa-user-plus",
						},
						{
							name: "Delete Receptionist",
							link: `/clinic/${clinicName}/doctor/deleteReception`,
							icon: "fas fa-trash",
						},
					],
				},

				{
					name: "Analytics Hub",
					// link: "/doctor/register",
					icon: "fas fa-chart-line", // Updated icon
					dropdownItems: [
						{
							name: "Revenue Analytics",
							link: `/clinic/${clinicName}/doctor/hospitalStatistic`,
							icon: "fas fa-rupee-sign",
						},
						{
							name: "Patient Analytics",
							link: `/clinic/${clinicName}/doctor/patientStatistic`,
							icon: "fas fa-users",
						},
					],
				},
				{
					name: "My Clinic",
					link: `/clinic/${clinicName}/doctor/myclinic`,
					icon: "fa fa-hospital-o", // Updated icon
					dropdownItems: [],
				},
			];
			handleThisLogin(carray);
		} else if (receptionInfo?.user?.name) {
			carray = [
				{
					name: "Appointments",
					link: `/clinic/${clinicName}/reception/patientstobevisited`,
					icon: "fa fa-clipboard",
					dropdownItems: [],
				},
				{
					name: "Book Appointments",
					link: `/clinic/${clinicName}/reception/booking`,
					icon: "fa fa-calendar-plus",
					dropdownItems: [],
				},
				{
					name: "Search Patients",
					link: `/clinic/${clinicName}/reception/searchPatient`,
					icon: "fa fa-search",
					dropdownItems: [],
				},
				{
					name: "Register Patient",
					link: `/clinic/${clinicName}/register`,
					icon: "fa fa-user-plus",
					dropdownItems: [],
				},
				{
					name: "Scheduled Appointments",
					link: `/clinic/${clinicName}/reception/scheduledAppointments`,
					icon: "fa fa-clock",
					dropdownItems: [],
				},

				{
					name: "Blocked Slots",
					link: `/clinic/${clinicName}/reception/BlockedSlots`,
					icon: "fa fa-ban",
					dropdownItems: [],
				},
				{
					name: "Update Password",
					link: `/clinic/${clinicName}/reception/updatepassword`,
					icon: "fa fa-key",
					dropdownItems: [],
				},
			];
			handleThisLogin(carray);
		}
	}, [doctorInfo?.user?.name, receptionInfo?.user?.name]);

	const logoutHandler = (typeOfUser) => {
		if (typeOfUser === "doctor") {
			dispatch(logout3());
		} else if (typeOfUser === "reception") {
			dispatch(logout4());
		}
	};

	console.log(window.location.href.includes("login"));
	return (
		<div>
			<div className="d-none d-md-block">
				<Navbar expand="lg" className="navbar-bg">
					<Navbar.Brand
						href="/doctor/patientstobevisited"
						className="offset-md-1"
					>
						<img
							alt="No Img"
							src="/bhuvanslogo.png"
							width="70"
							height="60"
							className="d-inline-block align-top"
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="offset-md-10">
							{(receptionInfo?.user?.name || doctorInfo?.user?.name) && (
								<NavDropdown
									id="nav-dropdown-dark-example"
									title={
										<>
											<i
												className="mr-2 fas fa-user-circle"
												style={{ fontSize: "125%" }}
											>
												{" "}
											</i>

											{receptionInfo?.user?.name}
											{doctorInfo?.user?.name}
										</>
									}
									menuVariant="dark"
									className="profile-name"
								>
									{doctorInfo?.user?.name ? (
										<NavDropdown.Item
											href={`/clinic/${clinicName}/doctor/myprofile`}
										>
											My Profile
										</NavDropdown.Item>
									) : (
										<NavDropdown.Item
											href={`/clinic/${clinicName}/reception/myprofile`}
										>
											My Profile
										</NavDropdown.Item>
									)}
									{doctorInfo?.user?.name ? (
										<NavDropdown.Item
											href={`/clinic/${clinicName}/doctor/updatepassword`}
										>
											Update Password
										</NavDropdown.Item>
									) : (
										<NavDropdown.Item
											href={`/clinic/${clinicName}/reception/updatepassword`}
										>
											Update Password
										</NavDropdown.Item>
									)}

									{doctorInfo?.user?.name ? (
										<NavDropdown.Item
											href={`/clinic/${clinicName}/login`}
											className="bg-danger text-light"
											onClick={() => logoutHandler("doctor")}
										>
											<i className="fa fa-sign-out"></i>
											{"  "}Logout
										</NavDropdown.Item>
									) : (
										<NavDropdown.Item></NavDropdown.Item>
									)}
									{receptionInfo?.user?.name ? (
										<NavDropdown.Item
											href={`/clinic/${clinicName}/login`}
											onClick={() => logoutHandler("reception")}
											className="bg-danger text-light"
										>
											<i className="fa fa-sign-out"></i>
											{"  "}Logout
										</NavDropdown.Item>
									) : (
										<NavDropdown.Item></NavDropdown.Item>
									)}
								</NavDropdown>
							)}
							{!doctorInfo?.user?.name &&
							!receptionInfo?.user?.name &&
							!window.location.href.includes("login") ? (
								<a
									className="btn btn-success"
									role="button"
									href={`/clinic/${clinicName}/login`}
								>
									<i className="fa fa-sign-in"></i>
									{"  "}Login
								</a>
							) : (
								<div></div>
							)}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
			<div className="d-block d-md-none">
				<Navbar expand="lg" className="navbar-bg">
					<Navbar.Brand href="/" className="offset-md-1">
						<img
							alt="No Img"
							src="/logo1.png"
							width="70"
							height="60"
							className="d-inline-block align-top"
						/>
					</Navbar.Brand>
					{!window.location.href.includes("login") && (
						<Navbar.Toggle aria-controls="basic-navbar-nav" />
					)}
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ml-auto">
							{allDau?.map((items, index) => (
								<>
									{items?.dropdownItems?.length !== 0 ? (
										<>
											<NavDropdown
												title={
													<>
														<i className={`${items.icon} mr-1`}></i>{" "}
														{items.name}{" "}
													</>
												}
												id="basic-nav-dropdown"
												className="profile-name"
											>
												{items?.dropdownItems?.map(
													(dropdownItem, dropdownIndex) =>
														dropdownItem &&
														dropdownItem.link &&
														dropdownItem.icon &&
														dropdownItem.name && (
															<NavDropdown.Item href={dropdownItem?.link}>
																<>
																	{" "}
																	<i
																		className={`${dropdownItem?.icon} mr-1`}
																	></i>{" "}
																	{dropdownItem?.name}
																</>
															</NavDropdown.Item>
														)
												)}
											</NavDropdown>
										</>
									) : (
										<Nav.Item>
											<Nav.Link href={items.link} className="profile-name">
												<i className={items.icon}></i> {items.name}
											</Nav.Link>
										</Nav.Item>
									)}
								</>
							))}

							{doctorInfo?.user?.name && (
								<Nav.Link className="profile-name">
									{doctorInfo?.user?.name}
								</Nav.Link>
							)}
							{receptionInfo?.user?.name && (
								<Nav.Link className="profile-name">
									{receptionInfo?.user?.name}
								</Nav.Link>
							)}
							{doctorInfo?.user?.name ? (
								<a
									className="btn btn-danger"
									role="button"
									href={`/clinic/${clinicName}/login`}
									onClick={() => logoutHandler("doctor")}
								>
									<i className="fa fa-sign-out"></i>
									{"  "}Logout
								</a>
							) : (
								<div></div>
							)}

							{receptionInfo?.user?.name ? (
								<a
									className="btn btn-danger"
									role="button"
									href={`/clinic/${clinicName}/login`}
									onClick={() => logoutHandler("reception")}
								>
									<i className="fa fa-sign-out"></i>
									{"  "}Logout
								</a>
							) : (
								<div></div>
							)}
							{!doctorInfo?.user?.name &&
							!receptionInfo?.user?.name &&
							!window.location.href.includes("login") ? (
								<a
									className="btn btn-success"
									role="button"
									href={`/clinic/${clinicName}/login`}
								>
									<i className="fa fa-sign-in"></i>
									{"  "}Login
								</a>
							) : (
								<div></div>
							)}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
		</div>
	);
};

export default Header;
