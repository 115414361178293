/** @format */

import { useRef, React } from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { getPrescription } from "../redux/prescriptionRedux/prescriptionApiCalls";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import HomeScreens from "../Screens/HomeScreen";
import ViewPrescriptionComponent from "./ViewPrescriptionComponent";
import { useReactToPrint } from "react-to-print";
import "./styles.css"; // Import your CSS file with print styles
import { useParams } from "react-router-dom";

const ViewPrescription = (uid) => {
  const dispatch = useDispatch();
  const { clinicName } = useParams();
  const history = useNavigate();
  const location = useLocation();
  const reportTemplateRef = useRef(null);
  let element = (
    <div style={{ display: "flex", flexWrap: "wrap" }}>Sample Text</div>
  ); // ViewPrescription html component needs to be passed;
  console.log(location?.state?.name + "line 37");

  // const handleGeneratePdf = () => {
  // 	const doc = new jsPDF("p", "px", [2050, 1300], true);

  // 	// format: "a4",
  // 	// unit: "px",
  // 	// const input = reportTemplateRef.current;
  // 	// html2canvas(input).then((canvas) => {
  // 	//   const imgdata = canvas.toDataURL("image/png");
  // 	//   const doc = new jsPDF("p", "mm", "a4", true);
  // 	//   const pdfWidth = doc.internal.pageSize.getWidth();
  // 	//   const pdfHeight = doc.internal.pageSize.getHeight();
  // 	//   const imgWidth = canvas.width;
  // 	//   const imgHeight = canvas.height;
  // 	//   const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  // 	//   const imgX = (pdfWidth - imgWidth * ratio) / 2;
  // 	//   const imgY = 30;
  // 	//   doc.addImage(
  // 	//     imgdata,
  // 	//     "PNG",
  // 	//     imgX,
  // 	//     imgY,
  // 	//     imgWidth * ratio,
  // 	//     imgHeight * ratio
  // 	//   );
  // 	//   doc.save(location.state.name);
  // 	// });

  // 	// Adding the fonts.
  // 	// doc.setFont("Inter-Regular", "normal");
  // 	// console.log(location.state.name + "line 37");
  // 	// doc.setProperties({ marginLeft: 10, marginRight: 40 });

  // 	// Wait for the image to fully loa

  // 	// Add the logo to the PDF

  // 	doc.html(reportTemplateRef.current, {
  // 		async callback(doc) {
  // 			const pdfFileName = location?.state?.name;
  // 			// Load the saved PDF
  // 			await doc.save(pdfFileName);
  // 		},
  // 	});
  // };

  // const handleGeneratePdf = async () => {
  // 	const doc = new jsPDF("p", "mm", "a4", true);
  // 	console.log("2222222222");
  // 	// Adding the logo and header to the PDF

  // 	// Adding the prescription content
  // 	const input = reportTemplateRef.current;
  // 	const pdfWidth = doc.internal.pageSize.getWidth();
  // 	const pdfHeight = doc.internal.pageSize.getHeight();

  // 	// Use html2canvas to capture the prescription content
  // 	const imgData = await html2canvas(input, { scale: 2 });

  // 	// Calculate the positioning to center the content
  // 	const imgWidth = pdfWidth;
  // 	const imgHeight = (imgWidth / imgData.width) * imgData.height;
  // 	const yPos = (pdfHeight - imgHeight) / 2;

  // 	// Add the prescription content to the PDF
  // 	doc.addImage(imgData, "PNG", 0, yPos, imgWidth, imgHeight);

  // 	// Save the PDF
  // 	const pdfFileName = location?.state?.name;
  // 	doc.save(pdfFileName);
  // };

  // const handleGeneratePdf = async () => {
  // 	const doc = new jsPDF("p", "mm", "a4", true);
  // 	console.log("2222222222");

  // 	// Function to add a new page to the PDF
  // 	const addNewPage = () => {
  // 		doc.addPage();
  // 		// Add headers or any other content you want on each new page
  // 	};

  // 	// Adding the prescription content
  // 	const input = reportTemplateRef.current;
  // 	const pdfWidth = doc.internal.pageSize.getWidth();
  // 	const pdfHeight = doc.internal.pageSize.getHeight();

  // 	// Use html2canvas to capture the prescription content
  // 	const imgData = await html2canvas(input, { scale: 2 });

  // 	// Set a fixed font size for consistency
  // 	const fontSize = 12;
  // 	doc.setFontSize(fontSize);

  // 	// Calculate the positioning to center the content on the first page
  // 	const imgWidth = pdfWidth;
  // 	const imgHeight = (imgWidth / imgData.width) * imgData.height;
  // 	let yPos = (pdfHeight - imgHeight) / 2;

  // 	// Add the prescription content to the PDF
  // 	doc.addImage(imgData, "PNG", 0, yPos, imgWidth, imgHeight);

  // 	// Check if the content exceeds the first page
  // 	if (yPos + imgHeight + fontSize > pdfHeight) {
  // 		addNewPage();
  // 		yPos = 10; // Set a new starting position for the second page
  // 	}

  // 	// Save the PDF
  // 	const pdfFileName = location?.state?.name;
  // 	doc.save(pdfFileName);
  // };
  const handleGeneratePdf = useReactToPrint({
    content: () => reportTemplateRef.current,
    documentTitle: "Visitor Pass",
    onBeforeGetContent: () => {
      // Apply styles to the content to add margins
      const content = reportTemplateRef.current;
      content.style.margin = "30mm 0 0 0"; // Adjust the margin as needed
      return content;
    },
    onAfterPrint: () => console.log("Printed PDF successfully!"),
  });

  const backButton = () => {
    if (uid?.urlback === `/clinic/${clinicName}/doctor/patientstobevisited`) {
      history(-1);
    } else {
      history(-1);
    }
  };
  const backURL = "/doctor/patientstobevisited";
  return (
    <div className="prescribe pageSize">
      <div className="row mt-3">
        <button
          // role='button'
          // href={uid?.urlback}
          onClick={backButton}
          className="btn offset-1 back-button"
        >
          <i className="fa fa-angle-left custom fa-2x"></i>
        </button>
        <button
          className="btn btn-success offset-9"
          onClick={handleGeneratePdf}
        >
          Generate PDF <i className="fa fa-download"></i>
        </button>
      </div>
      <div className="my-profile"></div>
      <div ref={reportTemplateRef} className="border mt-3">
        <div className="headi offset-md-1 d-flex justify-content-between mt-3">
          <img src="/bhuvanslogo.png" alt="logo" width="110" height="100" />
          <h6 className="text-muted">
            Bhuvans Homeopathy
            <br />
            phone number : 9494260222
            <br />
            Tirumala Apartments, Old Saibaba Temple Road,
            <br /> Behind New Bus stand, NGO'S Colony
          </h6>
        </div>
        <ViewPrescriptionComponent
          uid={location?.state?.id}
          uage={location?.state?.age}
          ugender={location?.state?.gender}
          ubgrg={location?.state?.bgrp}
          urlback={backURL}
        />
      </div>
    </div>
  );
};

export default ViewPrescription;
