/** @format */

import { createSlice } from "@reduxjs/toolkit";
const receptionItemsFromStorage = JSON.parse(
	localStorage.getItem("persist:root")
)
	? JSON.parse(localStorage.getItem("persist:root"))?.receptionLogin
	: {};

const reception = createSlice({
	name: "reception",
	initialState: receptionItemsFromStorage,
	reducers: {
		loginStart: (state) => {
			state.loading = true;
		},
		loginSuccess: (state, action) => {
			state.loading = false;
			state.receptionInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		loginFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		loginReceptionReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
		logout: (state) => {
			state.receptionInfo = {};
		},
	},
});

export const {
	loginStart,
	loginSuccess,
	loginFailure,
	logout,
	loginReceptionReset,
} = reception.actions;
export default reception.reducer;
