/** @format */
import Message from "../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, useLocation } from "react-router-dom";
import Loader from "../../Components/Loader";
import Meta from "../../Components/Meta";
import { useNavigate } from "react-router-dom";
import ViewPrescription from "../../Components/ViewPrescription";
import { useParams } from "react-router-dom";
const ViewPrescriptionDoctorScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const history = useNavigate();
	const location = useLocation();
	let backURL = `/clinic/${clinicName}/doctor`;
	if (location?.state?.page === "search") {
		backURL = `/clinic/${clinicName}/doctor/searchPatients`;
	} else if (location?.state?.page === "Doc2Screen") {
		backURL = `/clinic/${clinicName}/doctor/patientstobevisited`;
	}
	return (
		<>
			<Meta />
			<ViewPrescription
				uid={location?.state?.id}
				uage={location?.state?.age}
				ugender={location?.state?.gender}
				ubgrg={location?.state?.bgrp}
				urlback={backURL}
			/>
		</>
	);
};

export default ViewPrescriptionDoctorScreen;
