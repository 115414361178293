/** @format */

import { createSlice } from "@reduxjs/toolkit";
const doctorItemsFromStorage = JSON.parse(localStorage.getItem("persist:root"))
	? JSON.parse(localStorage.getItem("persist:root"))?.doctorLogin
	: {};

const doctor = createSlice({
	name: "doctor",
	initialState: doctorItemsFromStorage,
	reducers: {
		forgotPasswordStart: (state) => {
			state.loading = true;
		},
		forgotPasswordSuccess: (state, action) => {
			state.loading = false;
			//state.doctorInfo = action.payload;
			state.error = false;
			state.success = true;
		},
		forgotPasswordFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		forgotPasswordReset: (state, action) => {
			state.error = false;
			state.success = false;
		},
		logout: (state) => {
			state.doctorInfo = {};
		},
	},
});

export const {
	forgotPasswordStart,
	forgotPasswordSuccess,
	forgotPasswordFailure,
	logout,
	forgotPasswordReset,
} = doctor.actions;
export default doctor.reducer;
