/** @format */
import React from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { getPrescription } from "../redux/prescriptionRedux/prescriptionApiCalls";
import jsPDF from "jspdf";
import ReactDOMServer from "react-dom/server";
import HomeScreens from "../Screens/HomeScreen";
import { getPrescriptionReset } from "../redux/prescriptionRedux/getPrescription";
import {
  allMessagesStart,
  allMessagesSuccess,
  allMessagesFailure,
  allMessagesReset,
} from "../redux/allMessages";
import Loader from "./Loader";
import Message from "./Message";
import { useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";

const ViewPrescriptionComponent = (uid) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const { clinicName } = useParams();
  //success and failure
  const getPrescriptionData = useSelector((state) => state.getPrescription);
  const userdata = getPrescriptionData.userdata;
  const success = getPrescriptionData.success;
  const loading = getPrescriptionData.loading;
  const error = getPrescriptionData.error;

  useEffect(() => {
    dispatch(getPrescription(uid?.uid, clinicName));
  }, []);
  const presData = useSelector((state) => state.getPrescription);
  console.log(uid);
  console.log(presData);
  const backButton = () => {
    history(uid?.urlback, {
      state: {
        id: location.state.id,
        name: location.state.name,
        date: location.state.date,
      },
    });
  };

  let formattedDate;
  const inputString =
    presData?.getPrescription?.data?.data?.bookedDate.toString();
  console.log(inputString);
  const year = inputString?.slice(0, 4);
  const month = ("0" + inputString?.slice(4, 6)).slice(-2);
  const day = ("0" + inputString?.slice(6, 8)).slice(-2);

  formattedDate = day + "-" + month + "-" + year;

  //ALerts
  useEffect(() => {
    dispatch(allMessagesStart());
  }, []);

  const [showPopup6, setShowPopup6] = React.useState(false);
  const toggleShowInfoPopup6 = (e) => {
    setShowPopup6(!showPopup6);
    e.preventDefault();
    console.log("error");
    dispatch(getPrescriptionReset());
  };

  return (
    <div>
      {error && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup6}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {error}
        </Message>
      )}
      {loading && <Loader />}
      <h4 className="patient-name mt-3">
        Patient Name: {presData?.getPrescription?.data?.data?.username}
      </h4>
      <Container className="mt-4">
        <Table striped bordered hover className="custom-table">
          <tbody>
            <tr>
              <td>
                <b>Date:</b> {formattedDate}
              </td>
              <td>
                <b>Age:</b> {uid?.uage}
              </td>
              <td>
                <b>Gender:</b> {uid?.ugender}
              </td>
              <td>
                <b>Blood Group:</b> {uid?.ubgrg}
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
      <Container className="mt-4">
        <Table striped bordered hover className="custom-table">
          <tbody>
            <tr>
              <td>
                {" "}
                <b>Temperature:</b>{" "}
                {presData?.getPrescription?.data?.data?.vitals[0]?.vitalValue}{" "}
                °F
              </td>
              <td>
                {" "}
                <b>Blood Pressure:</b>{" "}
                {presData?.getPrescription?.data?.data?.vitals[1]?.vitalValue}{" "}
                MM/Hg
              </td>
              <td>
                {" "}
                <b>Weight:</b>{" "}
                {presData?.getPrescription?.data?.data?.vitals[2]?.vitalValue}{" "}
                Kg
              </td>
              <td>
                {" "}
                <b>Height:</b>{" "}
                {presData?.getPrescription?.data?.data?.vitals[3]?.vitalValue}{" "}
                Cm
              </td>
              <td>
                {" "}
                <b>Category:</b>{" "}
                {presData?.getPrescription?.data?.data?.category}
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>

      <Container className="card mt-4">
        <div className="row mt-4 mb-2 section">
          <div className="col-md-4">
            <b>Title:</b>
          </div>
        </div>
        <Row className="mb-4 mt-2">
          <Col md="auto"></Col>
          <Col md="auto">{presData?.getPrescription?.data?.data?.title}</Col>
        </Row>
      </Container>
      <Container className="card mt-4">
        <div className="row mt-4 mb-2 section">
          <div className="col-md-4">
            <b>Summary:</b>
          </div>
        </div>
        <Row className="mb-4 mt-2">
          <Col md="auto"></Col>
          <Col md="auto">
            {presData?.getPrescription?.data?.data?.doctor?.summary
              .split(/\d+\./)
              .filter((point) => point.trim() !== "")
              .map((point, index) => (
                <div key={index} className="advice-line">
                  {index + 1}. {point.trim()}
                </div>
              ))}
          </Col>
        </Row>
      </Container>

      <Container className="card mt-4">
        <div className="row mt-4 mb-2 section">
          <div className="col-md-4">
            <b>Medication:</b>
          </div>
        </div>
        <Table striped bordered hover className="custom-table">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Medicine</th>
              <th>Frequency</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            {presData?.getPrescription?.data?.data?.doctor?.medicines?.map(
              (medicine, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{medicine?.name}</td>
                  <td>
                    {medicine?.dosage?.includes("O@@") ? (
                      medicine?.dosage?.split("O@@")[1]
                    ) : (
                      <>
                        {medicine?.dosage?.includes("A/F") ? (
                          <span className="medication-definition after-food">
                            AF
                          </span>
                        ) : (
                          <span className="medication-definition before-food">
                            BF
                          </span>
                        )}
                        {medicine?.dosage?.split(",")?.includes("M") && (
                          <span className="medication-definition morning">
                            M
                          </span>
                        )}
                        {medicine?.dosage?.split(",")?.includes("A") && (
                          <span className="medication-definition afternoon">
                            AN
                          </span>
                        )}
                        {medicine?.dosage?.split(",")?.includes("N") && (
                          <span className="medication-definition night">N</span>
                        )}
                      </>
                    )}
                  </td>
                  <td>
                    {medicine?.duration.charAt(0) === "1"
                      ? medicine?.duration.replace("s", "")
                      : medicine?.duration}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </Table>
        <div className="row mt-4 mb-2 section">
          <div className="col-md-12">
            <div className="medication-definition-box">
              <span className="medication-definition before-food">BF</span>
              <span className="mt-1">Before Food</span>
              <span className="medication-definition after-food">AF</span>
              <span className="mt-1">After Food</span>
              <span className="medication-definition morning">M</span>
              <span className="mt-1">Morning</span>
              <span className="medication-definition afternoon">AN</span>
              <span className="mt-1">After Noon</span>
              <span className="medication-definition night">N</span>
              <span className="mt-1">Night</span>
            </div>
          </div>
        </div>
      </Container>

      <Container className="card mt-4">
        <div className="row mt-4 mb-2 section">
          <div className="col-md-4">
            <b>Advice:</b>
          </div>
        </div>
        <Row className="mb-4 mt-2">
          <Col md="auto"></Col>
          <Col md="auto">
            {presData?.getPrescription?.data?.data?.doctor?.advice
              .split(/\d+\./)
              .filter((point) => point.trim() !== "")
              .map((point, index) => (
                <div key={index} className="advice-line">
                  {index + 1}. {point.trim()}
                </div>
              ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ViewPrescriptionComponent;
