/** @format */

import { createSlice } from "@reduxjs/toolkit";

const getPatientsInBlockedSlot = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    getPatientsInBlockedSlotStart: (state) => {
      state.loading = true;
    },
    getPatientsInBlockedSlotSuccess: (state, action) => {
      state.loading = false;
      state.getBlockedPatients = action.payload;
      state.error = false;
      state.success = true;
    },
    getPatientsInBlockedSlotFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getPatientsInBlockedSlotReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  getPatientsInBlockedSlotStart,
  getPatientsInBlockedSlotSuccess,
  getPatientsInBlockedSlotFailure,
  getPatientsInBlockedSlotReset,
} = getPatientsInBlockedSlot.actions;
export default getPatientsInBlockedSlot.reducer;
