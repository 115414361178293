/** @format */
import Message from "./Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import Meta from "./Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { getTimeslots } from "../redux/userApiCalls";
import { paymentStatusvisitedPatientsReset } from "../redux/updatePaymentStatusinVisitedPatient";
import {
	//
	//changeToVisited,
	changeToOnHold, //
	changeSlot, //
	getDoctorVisitedPatientsforParticularDate,
} from "../redux/adminRedux/adminApiCalls";
// import Accordion from 'react-bootstrap/Accordion';

import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";
import { doctorBookingGetReset } from "../redux/adminRedux/Booking_Management/getBooking";
import { changeOnHoldReset } from "../redux/adminRedux/Booking_Management/changeOnHold";
import { changeSlotReset } from "../redux/adminRedux/Booking_Management/changeSlot";
import Form from "react-bootstrap/Form";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../redux/allMessages";

import { TimeSlotGetReset } from "../redux/getTimeSlots";
import { Table } from "react-bootstrap";
import {
	getMyAppointments,
	paymentStatusUpdate,
	paymentStatusvisitedPatients,
} from "../redux/doctorRedux/doctorApiCalls";
import { getMyAppointmentsReset } from "../redux/doctorRedux/Appointment_Management/getMyAppointments";
import { useParams } from "react-router-dom";
//Date picker

const PatientsVisitedScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { clinicName } = useParams();
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const { loading, error, success } = useSelector(
		(state) => state.paymentStatusvisitedPatients
	);

	const doctorAppointmentDates =
		doctorInfo === undefined
			? receptionInfo?.user?.appointmentDates
			: doctorInfo?.user?.appointmentDates;

	const [visitedDate, setVisitedDate] = useState("");
	const [searchClicked, setSearchClicked] = useState(false);
	const handleDateSelect1 = (eventKey) => {
		setVisitedDate(eventKey);
		setSearchClicked(false);
	};

	//Date Format
	var days = [];
	let slotArrayVisited = [];
	const todaydate = new Date();
	todaydate.setDate(new Date().getDate());

	const today = todaydate.toISOString().split("T")[0];
	const formattedDates = doctorAppointmentDates?.map((entry) => {
		const dateString = entry.date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ("0" + dateString.slice(6, 8)).slice(-2);

		// Create the formatted date string

		days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
	});
	console.log(days);
	const today1 = new Date();
	const year = today1.getFullYear();
	const month = today1.getMonth() + 1; // Months are zero-indexed
	const day = today1.getDate();

	// Form a number in YYYYMMDD format
	const numericDate = year * 10000 + month * 100 + day;
	const todaymax = new Date().toISOString().split("T")[0];
	let dateModified = numericDate;
	console.log(todaymax);

	function isDateGreaterThanToday(dateToCheck) {
		// Get today's date
		const today = new Date();

		// Get tomorrow's date
		const tomorrow = new Date();
		tomorrow.setDate(today.getDate());
		// Convert the input date string to a Date object
		const inputDate = new Date(dateToCheck);

		// Compare the input date with today's date
		return inputDate > tomorrow;
	}

	const searchForVisited = async () => {
		if (visitedDate == "") {
			alert("Please select the date");
		} else {
			const isGreater = isDateGreaterThanToday(visitedDate);
			if (isGreater) {
				alert("Date cannot be greater than today");
				setVisitedDate(todaymax);
			} else {
				const tempArr1 = visitedDate.split("-");
				console.log(visitedDate);

				const dateModified1 = tempArr1[0] + "" + tempArr1[1] + "" + tempArr1[2];
				setSearchClicked(true);
				dispatch(
					getDoctorVisitedPatientsforParticularDate(
						doctorInfo === undefined
							? receptionInfo?.user?.doctorId
							: doctorInfo?.user?._id,
						parseInt(dateModified1),
						clinicName
					)
				);
			}
		}
	};

	useEffect(() => {
		const tempArr1 = visitedDate.split("-");
		const dateModified1 = tempArr1[0] + "" + tempArr1[1] + "" + tempArr1[2];
		dispatch(
			getDoctorVisitedPatientsforParticularDate(
				doctorInfo === undefined
					? receptionInfo?.user?.doctorId
					: doctorInfo?.user?._id,
				parseInt(dateModified1),
				clinicName
			)
		);
	}, [loading]);
	const getVisiPat = useSelector(
		(state) => state.getDoctorVisitedPatientsforParticularDate
	);

	const [showv, setShowV] = useState(false);
	const handleCloseV = () => setShowV(false);
	const [userId_payemntv, setuserId_payemntv] = useState("");
	const [prescriptionId_payemntv, setprescriptionId_payemntv] = useState("");
	const [userIdx, setuserIdx] = useState(0);
	const handleUpdatePaymentStatus1 = (userId, prescriptionId, index) => {
		setShowV(true);
		setuserId_payemntv(userId);
		setprescriptionId_payemntv(prescriptionId);
		setuserIdx(index);
	};

	const initialAmount =
		doctorInfo === undefined
			? receptionInfo?.user?.opConsultationFee
			: doctorInfo?.user?.opConsultationFee;

	const [discountVisited, setDiscountVisited] = useState("");
	const [discountReasonVisited, setDiscountReasonVisited] = useState("");
	const [additionalChargesVisited, setAdditionalChargesVisited] = useState(0);
	const [amountVisited, setAmountVisited] = useState(initialAmount);
	const [modeOfPaymentVisited, setModeOfPaymentVisited] = useState("Choose");
	// submit for visited patients
	const handleSubmitVisited = () => {
		if (modeOfPaymentVisited === "Choose") {
			alert("Choose Mode of payment.");
		} else {
			// Log all input values
			console.log("Amount:", amountVisited);
			console.log("Discount:", discountVisited);
			console.log("Discount Reason:", discountReasonVisited);
			console.log("Mode of Payment:", modeOfPaymentVisited);
			console.log("Additional charges:", additionalChargesVisited);
			console.log(
				"" + (parseInt(amountVisited) + parseInt(additionalChargesVisited))
			);
			dispatch(
				paymentStatusvisitedPatients(
					discountVisited,
					discountReasonVisited,
					"" + (parseInt(amountVisited) + parseInt(additionalChargesVisited)),
					userId_payemntv,
					modeOfPaymentVisited,
					doctorInfo === undefined
						? receptionInfo?.user?.doctorId
						: doctorInfo?.user?._id,
					prescriptionId_payemntv,
					userIdx,
					clinicName
				)
			);
			// Additional logic for handling form submission, e.g., sending data to the server
			// ...
			setAmountVisited(initialAmount);
			setDiscountVisited(0);
			setModeOfPaymentVisited("");
			setDiscountReasonVisited("");
			setAdditionalChargesVisited(0);
			// Close the modal
			handleCloseV();
		}
	};

	const handleDiscountChangeVisited = (event) => {
		const discountPercentage = parseFloat(event.target.value) || 0;
		const discountedAmount =
			initialAmount - (initialAmount * discountPercentage) / 100;
		setDiscountVisited(event.target.value);
		setAmountVisited(discountedAmount);
	};

	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
	const fileExtension = ".xlsx";
	const exportToExcel = async () => {
		let tempObj =
			getVisiPat?.getDoctorVisitedPatientsforParticularDate?.data?.data
				?.visited_patients;
		console.log(tempObj);
		let tempArr = [];
		for (let i = 0; i < tempObj.length; i++) {
			const obj = {
				"Patient Name": tempObj[i].name,
				"Slot ": tempObj[i].slot,
				"Mobile No": tempObj[i].mobilenumber,
				"Amount Paid": tempObj[i].paidAmount,
				"Paid Through": tempObj[i].modeOfPayment,
			};
			tempArr.push(obj);
		}
		console.log(tempArr + "dataaaaaa");

		const ws = XLSX.utils.json_to_sheet(tempArr);

		// Set column widths (example: set the width of the first column to 20)
		ws["!cols"] = [
			{ wch: 20 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 15 },
			{ wch: 20 },
		];

		const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(
			data,
			doctorInfo === undefined
				? receptionInfo?.user?.doctorName
				: doctorInfo?.user?.name + "_" + visitedDate
		);
		console.log("saved");
	};
	const [showPopup, setShowPopup] = useState(false);
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log("error");
		dispatch(paymentStatusvisitedPatientsReset());
	};

	return (
		<div className="pageSize">
			<Meta />
			{error && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}
			{doctorInfo ? (
				<Breadcrumb>
					<Breadcrumb.Item
						href={`/clinic/${clinicName}/doctor/patientstobevisited`}
					>
						Patients to be visited
					</Breadcrumb.Item>
					<Breadcrumb.Item
						href={`/clinic/${clinicName}/doctor/patientsvisited`}
					>
						Visited patients
					</Breadcrumb.Item>
				</Breadcrumb>
			) : (
				<Breadcrumb>
					<Breadcrumb.Item
						href={`/clinic/${clinicName}/reception/patientstobevisited`}
					>
						Patients to be visited
					</Breadcrumb.Item>
					<Breadcrumb.Item
						href={`/clinic/${clinicName}/reception/patientsvisited`}
					>
						Visited patients
					</Breadcrumb.Item>
				</Breadcrumb>
			)}

			<div>
				<h1 className="header-center">Visited Patients</h1>
				<Container className="header-center mt-4 mb-5">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center mt-1">
							<h6>
								Select the filters{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<input
								type="date"
								className="form-control"
								onChange={(e) => handleDateSelect1(e.target.value)}
								max={todaymax}
							/>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<Button
								id="searchButton1"
								className="btn-primary"
								onClick={searchForVisited}
							>
								Search
							</Button>
						</Col>
					</Row>
				</Container>

				<Container>
					<button
						className="btn btn-success offset-md-10 mb-2"
						onClick={(e) => exportToExcel()}
					>
						<i className="fa fa-download"></i>&nbsp;Download
					</button>
					<Table striped bordered hover className="custom-table">
						<thead>
							<tr>
								<th></th>
								<th scope="col">Patient No</th>
								<th scope="col">Patient Name</th>
								<th scope="col">Slot</th>
								<th scope="col">Patient Mobile Number</th>
								<th scope="col">Payment</th>
							</tr>
						</thead>
						{visitedDate !== "" &&
							searchClicked &&
							getVisiPat?.getDoctorVisitedPatientsforParticularDate?.data?.data?.visited_patients?.map(
								(
									{ name, mobilenumber, prescriptionId, slot, hasPaid, userId },
									index
								) => (
									<tbody>
										<tr>
											<td></td>
											<td>{index + 1}</td>
											<td>{name}</td>
											<td>{slot}</td>
											<td>{mobilenumber}</td>
											<td>
												{hasPaid === false ? (
													<button
														className="btn btn-success"
														onClick={() =>
															handleUpdatePaymentStatus1(
																userId,
																prescriptionId,
																index
															)
														}
													>
														<i className="	fa fa-credit-card"></i>
													</button>
												) : (
													<div>
														<button className="btn btn-success">
															<i className="fa fa-check"></i>
														</button>
													</div>
												)}
											</td>
										</tr>
									</tbody>
								)
							)}
					</Table>
				</Container>
				<Modal show={showv} onHide={handleCloseV} size="lg">
					<Modal.Header closeButton>
						<Modal.Title>Update Payment Status</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row mt-2">
							<div className="col-4 col-md-3 cardHeading">Amount:</div>
							<div className="col-8">Rs {amountVisited}</div>
						</div>
						<div className="row mt-2">
							<div className="col-4 col-md-3 cardHeading">Discount (%):</div>
							<input
								type="number"
								className="col-8 form-control"
								value={discountVisited}
								onChange={handleDiscountChangeVisited}
							/>
						</div>
						<div className="row mt-2">
							<div className="col-4 col-md-3 cardHeading">Discount Reason:</div>
							<input
								type="text"
								className="col-8 form-control"
								value={discountReasonVisited}
								onChange={(e) => setDiscountReasonVisited(e.target.value)}
							/>
						</div>
						<div className="row mt-2">
							<div className="col-4 col-md-3 cardHeading">
								Additional charges:
							</div>
							<input
								type="text"
								className="col-8 form-control"
								value={additionalChargesVisited}
								placeholder="ex: medicines, procedures, tests, etc"
								onChange={(e) => setAdditionalChargesVisited(e.target.value)}
							/>
						</div>
						<div className="row mt-2">
							<div className="col-4 col-md-3 cardHeading">Mode of payment:</div>
							<DropdownButton
								className="col-md-3 btn"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={modeOfPaymentVisited || "Choose"}
								onSelect={(eventKey) => setModeOfPaymentVisited(eventKey)}
							>
								<Dropdown.Item eventKey="Online">Online</Dropdown.Item>
								<Dropdown.Item eventKey="Cash">Cash</Dropdown.Item>
							</DropdownButton>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseV}>
							Close
						</Button>
						<Button variant="primary" onClick={handleSubmitVisited}>
							Mark as Paid
						</Button>
					</Modal.Footer>
				</Modal>
			</div>
		</div>
	);
};

export default PatientsVisitedScreen;
