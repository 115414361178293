/** @format */
import Message from "./Message";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./Loader";
import Meta from "./Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Tooltip from "react-bootstrap/Tooltip";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Table } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Breadcrumb from "react-bootstrap/Breadcrumb";
// import Message from "./Message";
import { getTimeslots } from "../redux/userApiCalls";
import { paymentStatusReset } from "../redux/paymentStatus";

import {
	//
	//changeToVisited,
	changeToOnHold, //
	changeSlot, //
} from "../redux/adminRedux/adminApiCalls";

// import Accordion from 'react-bootstrap/Accordion';

import {
	Accordion,
	AccordionBody,
	AccordionHeader,
	AccordionItem,
} from "reactstrap";
import { doctorBookingGetReset } from "../redux/adminRedux/Booking_Management/getBooking";
import { changeOnHoldReset } from "../redux/adminRedux/Booking_Management/changeOnHold";
import { changeSlotReset } from "../redux/adminRedux/Booking_Management/changeSlot";
import { allMessagesStart } from "../redux/allMessages";

import { TimeSlotGetReset } from "../redux/getTimeSlots";

import {
	getMyAppointments,
	paymentStatusUpdate,
} from "../redux/doctorRedux/doctorApiCalls";
import { useParams } from "react-router-dom";
//Date picker

const PatientsToBeVisitedScreen = () => {
	const dispatch = useDispatch();
	const history = useNavigate();
	const { clinicName } = useParams();
	console.log(clinicName);
	const { doctorInfo } = useSelector((state) => state.doctorLogin);
	const { receptionInfo } = useSelector((state) => state.receptionLogin);
	const doctorAppointmentDates =
		doctorInfo === undefined
			? receptionInfo?.user?.appointmentDates
			: doctorInfo?.user?.appointmentDates;

	// //Success and failure allMessages
	// const data1 = useSelector((state) => state.getDoctorBooking);
	// const { getBookingsall } = data1;
	// const { loading, error, success } = data1;

	const changeOnHoldData = useSelector((state) => state.changeOnHold);
	const changeOnHoldError = changeOnHoldData.error;
	const changeOnHoldloading = changeOnHoldData.loading;
	const changeOnHoldsuccess = changeOnHoldData.success;

	const changeSlotData = useSelector((state) => state.changeSlot);
	const changeSlotError = changeSlotData.error;
	const changeSlotloading = changeSlotData.loading;
	const changeSlotsuccess = changeSlotData.success;

	const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
	const getTimeSlotssuccess = getTimeSlotsData.success;
	const getTimeSlotserror = getTimeSlotsData.error;
	const getTimeSlotsloading = getTimeSlotsData.loading;

	const paymentStatusData = useSelector((state) => state.paymentStatus);
	const paymentStatusDatasuccess = paymentStatusData.success;
	const paymentStatusDataerror = paymentStatusData.error;
	const paymentStatusDataloading = paymentStatusData.loading;
	//Displaying the visited patients and patients to be visited part

	// Accordian Collapse
	const [open, setOpen] = useState([]);
	const toggle = (id) => {
		if (open.includes(id)) {
			setOpen(open.filter((key) => key !== id));
		} else {
			setOpen([...open, id]);
		}
	};

	//Modal Open
	const [lgShow, setLgShow] = useState(false);
	//Date Format
	var days = [];

	const formattedDates = doctorAppointmentDates?.map((entry) => {
		const dateString = entry.date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ("0" + dateString.slice(6, 8)).slice(-2);

		// Create the formatted date string

		days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
	});
	console.log(days);

	const data1 = useSelector((state) => state.DoctorGetMyAppointments);
	console.log(data1);
	const { getMyBookings, loading, error, success } = data1;
	console.log(getMyBookings?.data?.data);
	const [dateOptionNumeric, setDateOptionNumeric] = useState(
		doctorAppointmentDates?.[0]?.date
	);
	console.log(dateOptionNumeric + "line137");
	const [dateIdx, setdateIdx] = useState(0);
	console.log(dateIdx);
	//Date dropdown
	const [dateOption, setDateOption] = useState(() => {
		if (doctorAppointmentDates.length > 0) {
			const dateString = doctorAppointmentDates[0].date.toString();
			const year = dateString.slice(0, 4);
			const month = dateString.slice(4, 6);
			const day = ("0" + dateString.slice(6, 8)).slice(-2);
			return `${day}-${month}-${year}`;
		} else {
			return "";
		}
	});
	const handleDateSelect = (eventKey) => {
		// slotArray = [];
		const dateString = doctorAppointmentDates[eventKey].date.toString();

		// Extract year, month, and day
		const year = dateString.slice(0, 4);
		const month = dateString.slice(4, 6);
		const day = ("0" + dateString.slice(6, 8)).slice(-2);
		setDateOption(`${day}-${month}-${year}`);
		setDateOptionNumeric(doctorAppointmentDates[eventKey].date);
		setdateIdx(eventKey);
		// slotArrayVisited = [];
	};

	useEffect(() => {
		dispatch(
			getMyAppointments(
				doctorInfo === undefined
					? receptionInfo?.user?.doctorId
					: doctorInfo?.user?._id, //doc id
				dateOptionNumeric,
				clinicName
			)
		);
	}, []);

	//Search button function
	const submitHandler = async () => {
		console.log(dateOptionNumeric);
		dispatch(
			getMyAppointments(
				doctorInfo === undefined
					? receptionInfo?.user?.doctorId
					: doctorInfo?.user?._id, //doc id
				dateOptionNumeric,
				clinicName
			)
		);
	};

	const handleChangeToOnHold = (id, timeSlotIdx) => {
		console.log(timeSlotIdx);
		dispatch(changeToOnHold(id, dateIdx, timeSlotIdx - 1, clinicName));
	};
	const [selectedPatientFromOnHold, setSelectedPatientFromOnHold] =
		useState("");
	const openModel = (_id) => {
		setChangeSlotOption("");
		console.log(dateOptionNumeric);
		setSelectedPatientFromOnHold(_id);
		setLgShow(true);
		dispatch(
			getTimeslots(
				doctorInfo === undefined
					? receptionInfo?.user?.doctorId
					: doctorInfo?.user?._id,
				dateIdx,
				clinicName
			)
		); //doc id, dateIdx));
	};
	const [changeSlotOption, setChangeSlotOption] = useState("");
	const handleChangeToSomeSlot = () => {
		if (changeSlotOption === "") {
			alert("Please select a vaild slot");
		} else {
			dispatch(
				changeSlot(
					selectedPatientFromOnHold,
					changeSlotOption?.split("@@")[0],
					dateIdx,
					changeSlotOption?.split("@@")[1],
					clinicName
				)
			);
			setLgShow(false);
		}
	};

	useEffect(() => {
		setLgShow(false);
		dispatch(
			getMyAppointments(
				doctorInfo === undefined
					? receptionInfo?.user?.doctorId
					: doctorInfo?.user?._id,
				dateOptionNumeric,
				clinicName
			)
		); //doc id, dateOptionNumeric));
	}, [
		history,
		dispatch,
		changeOnHoldloading,
		changeSlotloading,
		paymentStatusDataloading,
	]);

	const viewAppointmentHandler = (id, name, date, hasPaid) => {
		history(
			`/clinic/${clinicName}/doctor/patientstobevisited/userappointment`,
			{
				state: {
					id: id,
					name: name,
					docname:
						doctorInfo === undefined
							? receptionInfo?.user?.doctorName
							: doctorInfo?.user?.name,
					date: date,
					hasPaid: hasPaid,
				},
			}
		);
	};
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [bookingId_payemnt, setbookingId_payemnt] = useState("");
	const handleUpdatePaymentStatus = (bookingId) => {
		setShow(true);
		setbookingId_payemnt(bookingId);
	};

	const [discount, setDiscount] = useState("");
	const [discountReason, setDiscountReason] = useState("");
	const [additionalCharges, setAdditionalCharges] = useState(0);
	const [modeOfPayment, setModeOfPayment] = useState("Choose");
	const [modeOfPaymentVisited, setModeOfPaymentVisited] = useState("");
	const initialAmount =
		doctorInfo === undefined
			? doctorInfo?.user?.opConsultationFee
			: doctorInfo?.user?.opConsultationFee;
	const [amount, setAmount] = useState(initialAmount);

	// Function to handle change of discount percentage
	const handleDiscountChange = (event) => {
		if (event?.target?.value > 100) {
			alert("Discount cannot be greater than 100%");
		} else if (event?.target?.value < 0) {
			alert("Discount cannot be less than 0%");
		} else {
			const discountPercentage = parseFloat(event.target.value) || 0;
			const discountedAmount =
				initialAmount - (initialAmount * discountPercentage) / 100;
			setDiscount(event.target.value);
			setAmount(discountedAmount);
		}
	};

	// Storing Block Slots
	const [blockedSlots, setBlockedSlots] = useState([]);
	useEffect(() => {
		let slotsblocked = [];
		for (
			let i = 0;
			i < doctorAppointmentDates[dateIdx]?.timeSlots?.length;
			i++
		) {
			if (doctorAppointmentDates[dateIdx]?.timeSlots[i]?.isBlocked) {
				console.log(doctorAppointmentDates[dateIdx]?.timeSlots[i]?.timeSlot);
				slotsblocked.push(
					doctorAppointmentDates[dateIdx]?.timeSlots[i]?.timeSlot
				);
			}
		}
		setBlockedSlots(slotsblocked);
	}, [dateIdx]);
	console.log(blockedSlots);

	// Function to handle form submission
	const handleSubmit = () => {
		if (modeOfPayment === "Choose") {
			alert("Choose Mode of payment.");
		} else {
			debugger;
			// Log all input values
			console.log("Amount:", amount);
			console.log("Discount:", discount);
			console.log("Discount Reason:", discountReason);
			console.log("Mode of Payment:", modeOfPayment);
			console.log("" + (amount + parseInt(additionalCharges)));
			dispatch(
				paymentStatusUpdate(
					"" + (parseInt(amount) + parseInt(additionalCharges)),
					discountReason,
					amount,
					bookingId_payemnt,
					modeOfPayment,
					clinicName
				)
			);
			// Additional logic for handling form submission, e.g., sending data to the server
			// ...
			setAmount(initialAmount);
			setDiscount(0);
			setModeOfPayment("");
			setDiscountReason("");
			setAdditionalCharges(0);
			// Close the modal
			handleClose();
		}
	};

	/////////////////////////////////Alert handling////////////////////////////////////////////////////
	const [timerRunning, setTimerRunning] = useState(false);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	//doctor bookings get
	const [showPopup, setShowPopup] = React.useState(false);
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log("error");
		dispatch(doctorBookingGetReset());
	};

	const [showPopup2, setShowPopup2] = React.useState(false);

	const toggleShowInfoPopup2 = (e) => {
		setShowPopup2(!showPopup2);
		e.preventDefault();
		console.log("error");
		dispatch(changeOnHoldReset());
	};

	useEffect(() => {
		if (changeOnHoldsuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(changeOnHoldReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [changeOnHoldsuccess, dispatch]);

	const [showPopup3, setShowPopup3] = React.useState(false);

	const toggleShowInfoPopup3 = (e) => {
		setShowPopup3(!showPopup3);
		e.preventDefault();
		console.log("error");
		dispatch(changeSlotReset());
	};

	useEffect(() => {
		if (changeSlotsuccess && !timerRunning) {
			setTimerRunning(true);

			const timer = setTimeout(() => {
				// Clear the alert and stop the timer after 3 seconds
				dispatch(changeSlotReset());

				setTimerRunning(false);
			}, 3000);

			// Clean up the timer if the component unmounts or the alert changes
		}
	}, [changeSlotsuccess, dispatch]);

	const [showPopup6, setShowPopup6] = React.useState(false);
	const toggleShowInfoPopup6 = (e) => {
		setShowPopup6(!showPopup6);
		e.preventDefault();
		console.log("error");
		dispatch(TimeSlotGetReset());
	};
	const [showPopup7, setShowPopup7] = React.useState(false);
	const toggleShowInfoPopup7 = (e) => {
		setShowPopup7(!showPopup7);
		e.preventDefault();
		console.log("error");
		dispatch(paymentStatusReset());
	};
	return (
		<div className="pageSize">
			<Meta />
			{doctorInfo ? (
				<Breadcrumb>
					<Breadcrumb.Item
						href={`/clinic/${clinicName}/doctor/patientstobevisited`}
					>
						Patients to be visited
					</Breadcrumb.Item>
					<Breadcrumb.Item
						href={`/clinic/${clinicName}/doctor/patientsvisited`}
					>
						Visited patients
					</Breadcrumb.Item>
				</Breadcrumb>
			) : (
				<Breadcrumb>
					<Breadcrumb.Item
						href={`/clinic/${clinicName}/reception/patientstobevisited`}
					>
						Patients to be visited
					</Breadcrumb.Item>
					<Breadcrumb.Item
						href={`/clinic/${clinicName}/reception/patientsvisited`}
					>
						Visited patients
					</Breadcrumb.Item>
				</Breadcrumb>
			)}

			<div>
				{error && (
					<Message variant="danger">
						<button
							type="button"
							className="close"
							data-dismiss="alert"
							aria-label="Close"
							onClick={toggleShowInfoPopup}
						>
							<span aria-hidden="true">&times;</span>
						</button>
						{error}
					</Message>
				)}
				{loading && <Loader />}

				{changeOnHoldError && (
					<Message variant="danger">
						<button
							type="button"
							className="close"
							data-dismiss="alert"
							aria-label="Close"
							onClick={toggleShowInfoPopup2}
						>
							<span aria-hidden="true">&times;</span>
						</button>
						{changeOnHoldError}
					</Message>
				)}
				{changeOnHoldloading && <Loader />}

				{changeSlotError && (
					<Message variant="danger">
						<button
							type="button"
							className="close"
							data-dismiss="alert"
							aria-label="Close"
							onClick={toggleShowInfoPopup3}
						>
							<span aria-hidden="true">&times;</span>
						</button>
						{changeSlotError}
					</Message>
				)}
				{changeSlotloading && <Loader />}

				{getTimeSlotserror && (
					<Message variant="danger">
						<button
							type="button"
							className="close"
							data-dismiss="alert"
							aria-label="Close"
							onClick={toggleShowInfoPopup6}
						>
							<span aria-hidden="true">&times;</span>
						</button>
						{getTimeSlotserror}
					</Message>
				)}
				{getTimeSlotsloading && <Loader />}
				{paymentStatusDataerror && (
					<Message variant="danger">
						<button
							type="button"
							className="close"
							data-dismiss="alert"
							aria-label="Close"
							onClick={toggleShowInfoPopup7}
						>
							<span aria-hidden="true">&times;</span>
						</button>
						{paymentStatusDataerror}
					</Message>
				)}
				{paymentStatusDataloading && <Loader />}
				<h1 className="header-center">Patients to be visited </h1>
				<Container className="header-center mt-4 mb-5">
					<Row className="justify-content-center">
						<Col md="auto" className="text-center mt-1">
							<h6>
								Select the filters{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</h6>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<DropdownButton
								key="down-centered"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={dateOption}
								onSelect={handleDateSelect}
							>
								<div className="dropScroll">
									{days?.map((day, index) => (
										<Dropdown.Item key={index} eventKey={index}>
											{day?.currDay}
										</Dropdown.Item>
									))}
								</div>
							</DropdownButton>
						</Col>
						<Col md="auto" className="text-center mt-1">
							<Button
								id="searchButton"
								className="btn-primary"
								onClick={submitHandler}
							>
								Search
							</Button>
						</Col>
					</Row>
				</Container>

				<Container>
					{getMyBookings?.data?.data?.map(
						(slot1, index) =>
							slot1[2]?.length !== 0 &&
							slot1[0] !== "onHold" && (
								<Accordion
									open={open}
									toggle={toggle}
									className={slot1[1] ? "mt-2 accordion-blocked" : "mt-2"}
								>
									<AccordionItem>
										<AccordionHeader targetId={index}>
											{slot1[0]}
										</AccordionHeader>
										<AccordionBody accordionId={index}>
											<Table striped bordered hover className="custom-table">
												<thead>
													<tr>
														<th></th>
														<th scope="col">Patient No</th>
														<th scope="col">Patient Name</th>
														<th scope="col">Patient Mobile Number</th>
														{slot1[1] ? <></> : <th scope="col">Status</th>}
														<th scope="col">Payment</th>
														{doctorInfo && <th scope="col">History</th>}
													</tr>
												</thead>
												{slot1[2]?.map(
													(
														{
															_id,
															name,
															slot, //onhold
															slotCount, // onhold:1
															userPhone,
															patientNo,
															onHold,
															user,
															scheduledDate,
															hasPaid,
														},
														sno
													) => (
														<tbody>
															<tr>
																<td></td>
																<td>{sno + 1}</td>
																<td>{name}</td>
																<td>{userPhone}</td>
																{slot1[1] ? (
																	<> </>
																) : (
																	<td>
																		{onHold === 0 ? (
																			<OverlayTrigger
																				delay={{ hide: 450, show: 300 }}
																				overlay={(props) => (
																					<Tooltip {...props}>
																						Change to On Hold
																					</Tooltip>
																				)}
																				placement="bottom"
																			>
																				<button
																					className="btn btn-warning"
																					onClick={() =>
																						handleChangeToOnHold(_id, index)
																					}
																				>
																					<i className="fa fa-pause"></i>
																				</button>
																			</OverlayTrigger>
																		) : (
																			<div></div>
																		)}
																	</td>
																)}
																<td>
																	{hasPaid === false ? (
																		<button
																			className="btn btn-success"
																			onClick={() =>
																				handleUpdatePaymentStatus(_id)
																			}
																		>
																			<i className="	fa fa-credit-card"></i>
																		</button>
																	) : (
																		<div>
																			<button className="btn btn-success">
																				<i className="fa fa-check"></i>
																			</button>
																		</div>
																	)}
																</td>
																{doctorInfo && (
																	<td>
																		<button
																			className="btn btn-success"
																			onClick={() =>
																				viewAppointmentHandler(
																					user,
																					name,
																					scheduledDate,
																					hasPaid
																				)
																			}
																		>
																			View Prescriptions
																		</button>
																	</td>
																)}
															</tr>
														</tbody>
													)
												)}
											</Table>
										</AccordionBody>
									</AccordionItem>
								</Accordion>
							)
					)}
				</Container>
				<Modal show={show} onHide={handleClose} size="lg">
					<Modal.Header closeButton>
						<Modal.Title>Update Payment Status</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="row mt-2">
							<div className="col-4 col-md-3 cardHeading">Amount:</div>
							<div className="col-8">Rs {amount}</div>
						</div>
						<div className="row mt-2">
							<div className="col-4 col-md-3 cardHeading">Discount (%):</div>
							<input
								type="number"
								className="col-8 form-control"
								value={discount}
								onChange={handleDiscountChange}
							/>
						</div>
						<div className="row mt-2">
							<div className="col-4 col-md-3 cardHeading">Discount Reason:</div>
							<input
								type="text"
								className="col-8 form-control"
								value={discountReason}
								onChange={(e) => setDiscountReason(e.target.value)}
							/>
						</div>
						<div className="row mt-2">
							<div className="col-4 col-md-3 cardHeading">
								Additional charges:
							</div>
							<input
								type="text"
								className="col-8 form-control"
								value={additionalCharges}
								placeholder="ex: medicines, procedures, tests, etc"
								onChange={(e) => setAdditionalCharges(e.target.value)}
							/>
						</div>
						<div className="row mt-2">
							<div className="col-4 col-md-3 cardHeading">
								Mode of payment:{" "}
								<span className="text-danger">
									<b>*</b>
								</span>
							</div>
							<DropdownButton
								className="col-md-3 btn"
								id={`dropdown-button-drop-down-centered`}
								drop="down-centered"
								variant="success"
								title={modeOfPayment || "Choose"}
								onSelect={(eventKey) => setModeOfPayment(eventKey)}
							>
								<Dropdown.Item eventKey="Online">Online</Dropdown.Item>
								<Dropdown.Item eventKey="Cash">Cash</Dropdown.Item>
							</DropdownButton>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						<Button variant="primary" onClick={handleSubmit}>
							Mark as Paid
						</Button>
					</Modal.Footer>
				</Modal>
				<Container>
					{
						<Accordion open={open} toggle={toggle} className="mt-2">
							<AccordionItem>
								<AccordionHeader targetId="1">Patients On Hold</AccordionHeader>

								<AccordionBody accordionId="1">
									<Table striped bordered hover className="custom-table">
										<thead>
											<tr>
												<th></th>
												<th scope="col">Patient No</th>
												<th scope="col">Patient Name</th>
												<th scope="col">Patient Mobile Number</th>
												<th scope="col">Status</th>
											</tr>
										</thead>
										<tbody>
											{getMyBookings?.data?.data[0][1]?.map(
												(
													{ _id, name, slot, slotCount, visited, userPhone },
													sno
												) => (
													<tr>
														<td></td>
														<td>{sno + 1}</td>
														<td>{name}</td>
														<td>{userPhone}</td>
														<td>
															{"   "}{" "}
															<OverlayTrigger
																delay={{ hide: 450, show: 300 }}
																overlay={(props) => (
																	<Tooltip {...props}>
																		Schedule Appointment
																	</Tooltip>
																)}
																placement="bottom"
															>
																<Button
																	className="btn btn-primary"
																	onClick={() => openModel(_id)}
																>
																	<i className="fa fa-arrow-up"></i>
																</Button>
															</OverlayTrigger>
															<Modal
																size="xl"
																show={lgShow}
																onHide={() => setLgShow(false)}
																aria-labelledby="example-modal-sizes-title-xl"
															>
																<Modal.Header closeButton>
																	<Modal.Title id="example-modal-sizes-title-xl">
																		Select Slot
																	</Modal.Title>
																</Modal.Header>
																<Modal.Body>
																	<div className="row offset-md-2">
																		{getTimeSlotsData?.allTimeSlots?.map(
																			(timeslots, index) =>
																				timeslots.isEmpty &&
																				!timeslots.isBlocked ? (
																					<button
																						className={
																							changeSlotOption?.split(
																								"@@"
																							)[0] === timeslots.timeSlot
																								? "btn btn-success col-md-3 col-10 m-1"
																								: "btn btn-outline-success col-md-3 col-10 m-1"
																						}
																						onClick={() =>
																							setChangeSlotOption(
																								timeslots.timeSlot +
																									"@@" +
																									index
																							)
																						}
																					>
																						<b>{`${
																							timeslots.timeSlot
																						} avl slots: ${
																							timeslots.maxAppointmentsperhour -
																							timeslots.curCount
																						} `}</b>
																					</button>
																				) : (
																					<Button
																						className="btn btn-danger col-md-3 m-1"
																						disabled
																					>
																						<b>
																							{timeslots.timeSlot} Not avaliable
																						</b>
																					</Button>
																				)
																		)}
																	</div>
																</Modal.Body>
																<Modal.Footer>
																	<Button
																		onClick={() => handleChangeToSomeSlot()}
																		className="btn btn-primary"
																	>
																		Submit
																	</Button>
																</Modal.Footer>
															</Modal>
														</td>
													</tr>
												)
											)}
										</tbody>
									</Table>
								</AccordionBody>
							</AccordionItem>
						</Accordion>
					}
				</Container>
			</div>
		</div>
	);
};

export default PatientsToBeVisitedScreen;
