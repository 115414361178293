/** @format */

import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Card, Button } from 'react-bootstrap';

export default function App() {
	return (
		<Container className='mb-5 mt-5' id='products'>
			<h2 className='pt-5'>PRODUCTS</h2>
			<div className='row mt-3'>
				<div className='col-md-6 d-flex'>
					<Card className='flex-fill'>
						<Card.Body>
							<Card.Title className='header-center mb-3'>
								Clinic Management Software
							</Card.Title>
							<Card.Text>
								Elevate your clinic's efficiency with our Clinic Management
								Software. You'll gain access to comprehensive appointment
								scheduling, patient management, and reporting insights.
							</Card.Text>
							<Card.Text>
								Have questions or need a tailored solution?{' '}
								<a href='#contactUs' style={{ fontWeight: 'bold' }}>
									Contact Us
								</a>{' '}
								and let's discuss how we can meet the unique needs of your
								clinic.
							</Card.Text>
						</Card.Body>
						<Card.Footer className='text-muted header-center fw-bold'>
							Special Launch Offer{' '}
							<div className='text-danger'>
								RS<del>1499</del> <ins> 999</ins>
							</div>
						</Card.Footer>
					</Card>
				</div>
				<div className='col-md-6 d-flex'>
					<Card className='flex-fill'>
						<Card.Body>
							<Card.Title className='header-center mb-3'>
								Multi-Speciality Management Software
							</Card.Title>
							<Card.Text>
								Transform your multi-speciality practice with our robust
								software solution. This plan offers advanced features designed
								for complex healthcare environments, including multi-department
								scheduling and analytics.
							</Card.Text>
							<Card.Text>
								For custom pricing or to explore more features, please{' '}
								<a href='#contactUs' style={{ fontWeight: 'bold' }}>
									Contact Us.
								</a>{' '}
								Our team is ready to assist you with personalized options that
								fit your practice.
							</Card.Text>
						</Card.Body>
						<Card.Footer className='text-muted header-center fw-bold'>
							Special Launch Offer{' '}
							<div className='text-danger'>Limited Time</div>
						</Card.Footer>
					</Card>
				</div>
			</div>
		</Container>
	);
}
