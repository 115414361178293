/** @format */

import { React, useState } from "react";
import { forgotPassword } from "../redux/doctorRedux/doctorApiCalls";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
const VerifyEmail = () => {
	const dispatch = useDispatch();
	const [mailId, setMailId] = useState("");
	const [username, setusername] = useState("");
	const { clinicName } = useParams();
	const sendMail = (e) => {
		e.preventDefault();
		console.log("sent mail 7");

		dispatch(forgotPassword(username, mailId, clinicName));
	};
	return (
		<>
			<div className="verifymail pageSize">
				<div className="col-6 col-md-6 offset-md-3 verifyForm container card mt-2 mb-2">
					<h2 className="verifyHeader header-center">Verify Email</h2>
					<form
						className="form-group"
						novalidate
						id="user-form"
						onSubmit={sendMail}
					>
						<div className="form-group row">
							<label for="username" className="col-md-3 col-form-label">
								<b>
									Username <span className="text-danger">*</span>
								</b>
							</label>
							<div className="col-md-8">
								<input
									className="form-control"
									id="getEmail"
									name="email"
									placeholder="Enter Username"
									required
									onChange={(e) => setusername(e.target.value)}
								/>
							</div>
						</div>
						<div className="form-group row">
							<label for="username" className="col-md-3 col-form-label">
								<b>
									Email <span className="text-danger">*</span>
								</b>
							</label>
							<div className="col-md-8">
								<input
									className="form-control"
									id="getEmail"
									name="email"
									placeholder="Enter Email"
									required
									onChange={(e) => setMailId(e.target.value)}
								/>
							</div>
						</div>
						<button type="submit" className="offset-md-5 btn btn-primary">
							verify
						</button>
					</form>
				</div>
			</div>
		</>
	);
};

export default VerifyEmail;
