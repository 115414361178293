import { createSlice } from "@reduxjs/toolkit";

const getAllReceptionReducer = createSlice({
  name: "getAllReception",
  initialState: {},
  reducers: {
    getAllReceptionRequest: (state) => {
      state.loading = true;
    },
    getAllReceptionSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.getAllReception = action.payload;
      state.error = false;
      state.success = true;
    },
    getAllReceptionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllReceptionReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  getAllReceptionRequest,
  getAllReceptionSuccess,
  getAllReceptionFailure,
  getAllReceptionReset,
} = getAllReceptionReducer.actions;
export default getAllReceptionReducer.reducer;
