/** @format */

import { createSlice } from '@reduxjs/toolkit';

const editUserReducer = createSlice({
	name: 'editUser',
	initialState: {},
	reducers: {
		editUserRequest: (state) => {
			state.loading = true;
		},
		editUserSuccess: (state, action) => {
			console.log('gotit');
			state.loading = false;
			state.error = false;
			state.success = true;
		},
		editUserFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		editUserReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	editUserRequest,
	editUserSuccess,
	editUserFailure,
	editUserReset,
} = editUserReducer.actions;
export default editUserReducer.reducer;
