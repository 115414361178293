import { createSlice } from "@reduxjs/toolkit";

const deleteTemplateReducer = createSlice({
	name: "deleteTemplate",
	initialState: {},
	reducers: {
		deleteTemplateRequest: (state) => {
			state.loading = true;
		},
		deleteTemplateSuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.deleteTemplate = action.payload;
			state.error = false;
			state.success = true;
		},
		deleteTemplateFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		deleteTemplateReset: (state) => {
			return { error: false, success: false };
		},
	},
});

export const {
	deleteTemplateRequest,
	deleteTemplateSuccess,
	deleteTemplateFailure,
	deleteTemplateReset,
} = deleteTemplateReducer.actions;
export default deleteTemplateReducer.reducer;
