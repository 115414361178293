import axios from "axios";
import { SERVER_URL } from "../../App";
import {
	loginStart,
	loginSuccess,
	loginFailure,
} from "./MyProfile_Management/receptionLogin";

import { persistor } from "../store";
import {
	verifyFailure,
	verifyReset,
	verifySuccess,
	verifyStart,
	logout,
} from "./MyProfile_Management/VerifyCode";

import {
	resendOtpStart,
	resendOtpReset,
	resendOtpFailure,
	resendOtpSuccess,
} from "./MyProfile_Management/resendOtp";

import {
	forgotPasswordFailure,
	forgotPasswordStart,
	forgotPasswordSuccess,
} from "./MyProfile_Management/forgotPassword";

import {
	resetPasswordFailure,
	resetPasswordStart,
	resetPasswordSuccess,
} from "./MyProfile_Management/resetPassword";

import {
	UpdatePasswordFailure,
	UpdatePasswordSuccess,
	UpdatePasswordStart,
} from "./MyProfile_Management/updatePassword";

import {
	getPatientsInBlockedSlotStart,
	getPatientsInBlockedSlotFailure,
	getPatientsInBlockedSlotSuccess,
} from "./Appointment_Management/getPatientsInBlockedSlot";

import {
	rescheduleAppointmentForPatientinBlockedSlotFailure,
	rescheduleAppointmentForPatientinBlockedSlotStart,
	rescheduleAppointmentForPatientinBlockedSlotSuccess,
} from "./Appointment_Management/rescheduleAppointmentForPatientinBlockedSlot";

import {
	allMessagesStart,
	allMessagesSuccess,
	allMessagesFailure,
	allMessagesReset,
} from "../../redux/allMessages";

import {
	editReceptionRequest,
	editReceptionSuccess,
	editReceptionFailure,
} from "./MyProfile_Management/editReception";

export const receptionLogin =
	(username, password, isMobile, clinicname) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(loginStart());

			const config = {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					clinicname: clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/reception/login`,
				{ username, password, isMobile },
				config
			);
			console.log(data);
			//localStorage.setItem('token-user', data.token);
			// localStorage.setItem("name", data.user.name);
			//localStorage.setItem('receptionInfo', JSON.stringify(data));

			// console.log(data.user);
			dispatch(loginSuccess());
			dispatch(
				allMessagesSuccess(
					"Credentials verified, Please verify the otp sent to your registered email"
				)
			);
		} catch (error) {
			dispatch(allMessagesFailure("Incorrect mobile number or password"));
			dispatch(
				loginFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const resendOtpReception =
	(username, clinicname) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(resendOtpStart());

			const config = {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					clinicname: clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/reception/resendOtp`,
				{ username },
				config
			);
			console.log(data);
			//localStorage.setItem('token-user', data.token);
			// localStorage.setItem("name", data.user.name);
			//localStorage.setItem('receptionInfo', JSON.stringify(data));

			// console.log(data.user);
			dispatch(resendOtpSuccess());
			dispatch(allMessagesSuccess("OTP sent successfully"));
		} catch (error) {
			dispatch(
				resendOtpFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const verifyCodeReception =
	(mobileNo, otp, clinicname) => async (dispatch) => {
		try {
			console.log("11223344");
			dispatch(verifyStart());

			const config = {
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "true",
					clinicname: clinicname,
				},
			};
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/reception/otpVerification`,
				{ mobilenumber: mobileNo, otp },
				config
			);
			console.log(data);
			localStorage.setItem("token-user", data.token);
			// localStorage.setItem("name", data.user.name);
			localStorage.setItem("receptionInfo", JSON.stringify(data));
			localStorage.setItem(
				"username",
				"d6d269952320c4fb5e50f278c94a098c".toString()
			);
			// console.log(data.user);
			dispatch(verifySuccess(data.data));
			dispatch(loginSuccess(data.data));
			dispatch(allMessagesSuccess(" OTP verified successfully"));
		} catch (error) {
			dispatch(
				verifyFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const logout4 = () => (dispatch) => {
	dispatch(logout());
	// dispatch(userUpdateProfileReset());
	// dispatch(bookingListMyReset());
	// dispatch(tourReviewCreateReset());
	localStorage.removeItem("userInfo");
	localStorage.removeItem("adminInfo");
	localStorage.removeItem("username");
	localStorage.removeItem("doctorInfo");
	localStorage.removeItem("receptionInfo");
	localStorage.removeItem("token-user");
	localStorage.removeItem("date");
	localStorage.removeItem("persist:root");
	persistor.pause();
	persistor.flush().then(() => {
		return persistor.purge();
	});
};

export const updatePassword =
	(password, clinicname) => async (dispatch, getState) => {
		try {
			dispatch(UpdatePasswordStart());
			console.log("getting3221");

			console.log(password);
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/reception/updatePassword`,
				{ password },
				config
			);
			console.log(data);
			dispatch(UpdatePasswordSuccess(data));
			dispatch(
				allMessagesSuccess("Password updated Successfully. Please login again ")
			);
			dispatch(logout());
			localStorage.removeItem("receptionInfo");
			localStorage.removeItem("doctorInfo");
			localStorage.removeItem("token-user");
			localStorage.removeItem("persist:root");
			persistor.pause();
			persistor.flush().then(() => {
				return persistor.purge();
			});
			window.location.href = "/";
		} catch (error) {
			const message =
				error.response && error.response.data.data.message
					? error.response.data.data.message
					: error.message;
			if (message === "Not authorized, token failed") {
				localStorage.removeItem("persist:root");
				dispatch(logout());
			}
			dispatch(UpdatePasswordFailure(message));
		}
	};

export const resetPasswordforReception =
	(password, token, clinicname) => async (dispatch) => {
		try {
			dispatch(resetPasswordStart());
			const config = {
				headers: {
					"Content-Type": "application/json",
					clinicname: clinicname,
				},
			};

			const data = await axios.post(
				`${SERVER_URL}/api/v1/reception/resetPassword`,
				{
					password: password,
					token: token,
				},
				config
			);
			dispatch(resetPasswordSuccess());
			dispatch(allMessagesSuccess("Password reset Successfully "));
		} catch (error) {
			dispatch(
				resetPasswordFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const getPatientsInBlockedSlot =
	(date, clinicname) => async (dispatch) => {
		try {
			console.log(date);
			dispatch(getPatientsInBlockedSlotStart());
			// console.log(date)
			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};
			const { data } = await axios.get(
				`${SERVER_URL}/api/v1/reception/getPatientsInBlockedSlot/${date}`,
				config
			);
			console.log(data);
			dispatch(getPatientsInBlockedSlotSuccess(data));
		} catch (error) {
			dispatch(
				getPatientsInBlockedSlotFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const rescheduleAppointmentForPatientinBlockedSlot =
	(
		userId,
		bookingId,
		date,
		timeslot,
		slotCount,
		prevDate,
		prevTimeSlot,
		dayIdx,
		username,
		prevDateIdx,
		clinicname
	) =>
	async (dispatch) => {
		try {
			const token = localStorage.getItem("token-user");
			dispatch(rescheduleAppointmentForPatientinBlockedSlotStart());
			console.log(
				userId,
				bookingId,
				date,
				timeslot,
				slotCount,
				prevDate,
				prevTimeSlot,
				dayIdx,
				prevDateIdx,
				clinicname
			);
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};

			const data = await axios.post(
				`${SERVER_URL}/api/v1/reception/rescheduleAppointmentForPatientinBlockedSlot`, //rescheduleAppointmentForPatientinBlockedSlot,
				{
					userId,
					bookingId,
					date,
					timeslot,
					slotCount,
					prevDate,
					prevTimeSlot,
					dayIdx,
					username,
					prevDateIdx,
				},
				config
			);
			dispatch(rescheduleAppointmentForPatientinBlockedSlotSuccess(data));
			dispatch(allMessagesSuccess("Appointment Rescheduled Successfully "));
		} catch (error) {
			dispatch(
				rescheduleAppointmentForPatientinBlockedSlotFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};

export const editReception =
	(name, gender, dob, address, mobilenumber, email, id, clinicname) =>
	async (dispatch) => {
		try {
			dispatch(editReceptionRequest());

			const token = localStorage.getItem("token-user");
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
					clinicname: clinicname,
				},
			};

			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/reception/editReception`,
				{
					name,
					gender,
					dob,
					address,
					mobilenumber,
					email,
					id,
				},
				config
			);
			dispatch(editReceptionSuccess(data));
			localStorage.setItem("receptionInfo", JSON.stringify(data));
			console.log(data);
			// console.log(data.user);

			dispatch(loginSuccess(data));
			dispatch(allMessagesSuccess("Successfully changed the fields"));
		} catch (error) {
			dispatch(
				editReceptionFailure(
					error.response && error.response.data.data.message
						? error.response.data.data.message
						: error.message
				)
			);
		}
	};
