/** @format */

import { createSlice } from "@reduxjs/toolkit";

const rescheduleAppointmentForPatientinBlockedSlot = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    rescheduleAppointmentForPatientinBlockedSlotStart: (state) => {
      state.loading = true;
    },
    rescheduleAppointmentForPatientinBlockedSlotSuccess: (state, action) => {
      state.loading = false;
      state.userInfo = action.payload;
      state.error = false;
      state.success = true;
    },
    rescheduleAppointmentForPatientinBlockedSlotFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    rescheduleAppointmentForPatientinBlockedSlotReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  rescheduleAppointmentForPatientinBlockedSlotStart,
  rescheduleAppointmentForPatientinBlockedSlotSuccess,
  rescheduleAppointmentForPatientinBlockedSlotFailure,
  rescheduleAppointmentForPatientinBlockedSlotReset,
} = rescheduleAppointmentForPatientinBlockedSlot.actions;
export default rescheduleAppointmentForPatientinBlockedSlot.reducer;
