/** @format */

import { createSlice } from "@reduxjs/toolkit";
const receptionItemsFromStorage = JSON.parse(
  localStorage.getItem("persist:root")
)
  ? JSON.parse(localStorage.getItem("persist:root"))?.receptionLogin
  : {};

const reception = createSlice({
  name: "reception",
  initialState: receptionItemsFromStorage,
  reducers: {
    resetPasswordStart: (state) => {
      state.loading = true;
    },
    resetPasswordSuccess: (state, action) => {
      state.loading = false;
      //state.receptionInfo = action.payload;
      state.error = false;
      state.success = true;
    },
    resetPasswordFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetPasswordReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
    logout: (state) => {
      state.receptionInfo = {};
    },
  },
});

export const {
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
  logout,
  resetPasswordReset,
} = reception.actions;
export default reception.reducer;
