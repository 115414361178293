import { createSlice } from "@reduxjs/toolkit";
const userItemsFromStorage = JSON.parse(localStorage.getItem("persist:root"))
	? JSON.parse(localStorage.getItem("persist:root"))?.doctorLogin
	: {};

const UpdateTemplateSlice = createSlice({
	name: "user",
	initialState: {},
	reducers: {
		UpdateTemplateStart: (state) => {
			state.loading = true;
		},
		UpdateTemplateSuccess: (state, action) => {
			state.loading = false;
			state.updateTemplate = action.payload;
			state.error = null;
			state.success = true;
		},
		UpdateTemplateFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		UpdateTemplateReset: (state) => {
			return { error:false,success:false };
		},
	},
});

export const {
	UpdateTemplateStart,
	UpdateTemplateSuccess,
	UpdateTemplateFailure,
	UpdateTemplateReset,
} = UpdateTemplateSlice.actions;
export default UpdateTemplateSlice.reducer;
