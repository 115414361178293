import { createSlice } from "@reduxjs/toolkit";

const getPrescriptionIdsofCategoryReducer = createSlice({
  name: "getPrescriptionIdsofCategory",
  initialState: {},
  reducers: {
    getPrescriptionIdsofCategoryRequest: (state) => {
      state.loading = true;
    },
    getPrescriptionIdsofCategorySuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.getPrescriptionIdsofCategory = action.payload;
      state.error = false;
      state.success = true;
    },
    getPrescriptionIdsofCategoryFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getPrescriptionIdsofCategoryReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  getPrescriptionIdsofCategoryRequest,
  getPrescriptionIdsofCategorySuccess,
  getPrescriptionIdsofCategoryFailure,
  getPrescriptionIdsofCategoryReset,
} = getPrescriptionIdsofCategoryReducer.actions;
export default getPrescriptionIdsofCategoryReducer.reducer;
