import { createSlice } from "@reduxjs/toolkit";

const getUserDataByCategoryReducer = createSlice({
	name: "getUserDataByCategory",
	initialState: {},
	reducers: {
		getUserDataByCategoryRequest: (state) => {
			state.loading = true;
		},
		getUserDataByCategorySuccess: (state, action) => {
			console.log("gotit");
			state.loading = false;
			state.userData = action.payload;
			state.error=false;
			state.success=true
		},
		getUserDataByCategoryFailure: (state, action) => {
			state.loading = false;
			state.error = action.payload;
		},
		getUserDataByCategoryReset: (state) => {
			return { error:false,success:false };
		},
	
	},
});

export const {
	getUserDataByCategoryRequest,
	getUserDataByCategorySuccess,
	getUserDataByCategoryFailure,
	getUserDataByCategoryReset,
	
} = getUserDataByCategoryReducer.actions;
export default getUserDataByCategoryReducer.reducer;
