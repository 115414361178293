import { createSlice } from "@reduxjs/toolkit";

const getMyIssueCategoryVitalsReducer = createSlice({
  name: "IssueCategoryVitals",
  initialState: {},
  reducers: {
    getMyIssueCategoryVitalsRequest: (state) => {
      state.loading = true;
    },
    getMyIssueCategoryVitalsSuccess: (state, action) => {
      console.log("gotit");
      state.loading = false;
      state.allCategoryVitals = action.payload;
      state.error = false;
      state.success = true;
    },
    getMyIssueCategoryVitalsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getMyIssueCategoryVitalsReset: (state) => {
      return { error: false, success: false };
    },
  },
});

export const {
  getMyIssueCategoryVitalsRequest,
  getMyIssueCategoryVitalsSuccess,
  getMyIssueCategoryVitalsFailure,
  getMyIssueCategoryVitalsReset,
} = getMyIssueCategoryVitalsReducer.actions;
export default getMyIssueCategoryVitalsReducer.reducer;
