/** @format */

import { createSlice } from "@reduxjs/toolkit";

const resendOtp = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    resendOtpStart: (state) => {
      state.loading = true;
    },
    resendOtpSuccess: (state, action) => {
      state.loading = false;

      state.error = false;
      state.success = true;
    },
    resendOtpFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resendOtpDoctorReset: (state, action) => {
      state.error = false;
      state.success = false;
    },
  },
});

export const {
  resendOtpStart,
  resendOtpSuccess,
  resendOtpFailure,
  resendOtpReset,
} = resendOtp.actions;
export default resendOtp.reducer;
