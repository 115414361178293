/** @format */

import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

export default function Benefits() {
	return (
		<div class='bg-color'>
			<Container className='mb-5 mt-5'>
				<h2 className='pt-5'>Why Us?</h2>
				<div className='row pt-3'>
					<div className='col-md-4 col-12'>
						<ul style={{ fontSize: '125%' }}>
							<li>Streamlined Hospital Management</li>
							<li>Efficient Appointment Scheduling</li>
							<li>Effective Prescription Tracking</li>
							<li>Seamless Revenue Management</li>
						</ul>
					</div>
					<div className='col-md-3 col-12'>
						<ul style={{ fontSize: '125%' }}>
							<li>Reduced Errors</li>
							<li>Enhanced Patient Care</li>
							<li>Cost-Effective Solution</li>
							<li>24/7 Support</li>
						</ul>
					</div>
					<div className='col-md-5 mb-5'>
						<img alt='image' src='/hospMana.jpg' className='img-fluid img-xl' />
					</div>
				</div>
			</Container>
		</div>
	);
}
