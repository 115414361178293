/** @format */

import Message from "../../../Components/Message";
import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Pagination from "react-bootstrap/Pagination";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import {
  createLeave,
  deleteLeave,
  getLeave,
  blockSlot,
  unBlockSlot,
} from "../../../redux/doctorRedux/doctorApiCalls";
import { getTimeslots } from "../../../redux/userApiCalls";
import {
  allMessagesStart,
  allMessagesSuccess,
  allMessagesFailure,
  allMessagesReset,
} from "../../../redux/allMessages";

import { createLeaveReset } from "../../../redux/doctorRedux/Leave_Management/createLeave";
import { deleteLeavesReset } from "../../../redux/doctorRedux/Leave_Management/deleteLeave";
import { getLeavesReset } from "../../../redux/doctorRedux/Leave_Management/getLeaves";
import { blockSlotReset } from "../../../redux/doctorRedux/Slot_Management/blockSlots";
import { unBlockSlotReset } from "../../../redux/doctorRedux/Slot_Management/unBlockSlots";
import { TimeSlotGetReset } from "../../../redux/getTimeSlots";
import { useParams } from "react-router-dom";

const DoctorUnblockSlotsScreen = () => {
  const { clinicName } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const doctorLogin = useSelector((state) => state.doctorLogin);
  const { doctorInfo } = doctorLogin;
  console.log(doctorInfo);
  // success and failure Message
  const getTimeSlotsData = useSelector((state) => state.getTimeSlots);
  const getTimeSlotssuccess = getTimeSlotsData.success;
  const getTimeSlotserror = getTimeSlotsData.error;
  const getTimeSlotsloading = getTimeSlotsData.loading;

  const unBlockSlotData = useSelector((state) => state.unBlockSlots);
  const unBlockSlotsuccess = unBlockSlotData.success;
  const unBlockSloterror = unBlockSlotData.error;
  const unBlockSlotloading = unBlockSlotData.loading;

  //Unblock Slots
  const days = [];
  const { allTimeSlots } = useSelector((state) => state.getTimeSlots);

  const doctorAppointmentDates = doctorInfo?.user?.appointmentDates;
  const formattedDates = doctorAppointmentDates.map((entry) => {
    const dateString = entry.date.toString();

    // Extract year, month, and day
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = ("0" + dateString.slice(6, 8)).slice(-2);

    // Create the formatted date string

    days.push({ currDay: `${day}-${month}-${year}`, key: entry.date });
  });
  const [unBlockdateOption, setUnBlockdateOption] = useState("Choose Date");
  const [unBlockdateSelected, setUnBlockDateSelected] = useState(false);
  const [timeSlots, settimeSlots] = useState([]);
  const [dateIndex, setDateIndex] = useState("0");
  const handleunBlockDateSelect = (eventKey) => {
    console.log(eventKey);
    eventKey = eventKey.split("@");
    setUnBlockdateOption(eventKey[1]);
    setDateIndex(eventKey[0]);
    setUnBlockDateSelected(true);
    setSelectedUnBlockCheckboxes([]);
    setSelectedUnBlockCheckboxesIds([]);
    var intDate = parseInt(eventKey[0]);
    settimeSlots(doctorAppointmentDates[intDate].timeSlots);
    // dispatch(getTimeslots(doctorInfo?.user?._id, eventKey[0]), clinicName);
  };

  // Initialize state to hold the values of checked checkboxes
  const [selectedUnBlockCheckboxes, setSelectedUnBlockCheckboxes] = useState(
    []
  );
  const [selectedUnBlockCheckboxesIds, setSelectedUnBlockCheckboxesIds] =
    useState([]);

  // Create a handler function to update the selectedCheckboxes array
  const handleUnblockCheckboxChange = (value, checked) => {
    // const { value, checked } = event.target;

    if (checked) {
      // If the checkbox is checked, add its value to the array
      setSelectedUnBlockCheckboxes([
        ...selectedUnBlockCheckboxes,
        value?.split("@@")[0],
      ]);
      setSelectedUnBlockCheckboxesIds([
        ...selectedUnBlockCheckboxesIds,
        value.split("@@")[1],
      ]);
    } else {
      // If the checkbox is unchecked, remove its value from the array
      setSelectedUnBlockCheckboxes(
        selectedUnBlockCheckboxes.filter(
          (item) => item !== value.split("@@")[0]
        )
      );
      setSelectedUnBlockCheckboxesIds(
        selectedUnBlockCheckboxesIds.filter(
          (item) => item !== value.split("@@")[1]
        )
      );
    }
  };

  const handleUnBlockSlot = () => {
    if (selectedUnBlockCheckboxes.length == 0) {
      alert("No slots selected! please select the slots to be unblocked");
    } else {
      console.log(selectedUnBlockCheckboxes);
      console.log(selectedUnBlockCheckboxesIds);
      dispatch(
        unBlockSlot(
          doctorInfo?.user?._id,
          selectedUnBlockCheckboxes,
          doctorAppointmentDates[dateIndex].date,
          dateIndex,
          selectedUnBlockCheckboxesIds,
          clinicName
        )
      );
      setUnBlockDateSelected(false);
      setUnBlockdateOption("Choose Date");
      setSelectedUnBlockCheckboxes([]);
      setSelectedUnBlockCheckboxesIds([]);
    }
  };

  //ALerts//////////////////////////////////////////////////////////////////

  const [timerRunning, setTimerRunning] = useState(false);
  useEffect(() => {
    dispatch(allMessagesStart());
  }, []);
  const alertmsg = useSelector((state) => state.allMessages);

  const [showPopup4, setShowPopup4] = React.useState(false);
  const toggleShowInfoPopup4 = (e) => {
    setShowPopup4(!showPopup4);
    e.preventDefault();
    console.log("error");
    dispatch(unBlockSlotReset());
  };

  useEffect(() => {
    if (unBlockSlotsuccess && !timerRunning) {
      setTimerRunning(true);

      const timer = setTimeout(() => {
        // Clear the alert and stop the timer after 3 seconds
        dispatch(unBlockSlotReset());

        setTimerRunning(false);
      }, 3000);

      // Clean up the timer if the component unmounts or the alert changes
    }
  }, [unBlockSlotsuccess, dispatch]);

  const [showPopup5, setShowPopup5] = React.useState(false);
  const toggleShowInfoPopup5 = (e) => {
    setShowPopup5(!showPopup5);
    e.preventDefault();
    console.log("error");
    dispatch(getLeavesReset());
  };

  const [showPopup6, setShowPopup6] = React.useState(false);
  const toggleShowInfoPopup6 = (e) => {
    setShowPopup6(!showPopup6);
    e.preventDefault();
    console.log("error");
    dispatch(TimeSlotGetReset());
  };

  return (
    <div className="pageSize">
      <Meta />
      {getTimeSlotserror && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup6}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {getTimeSlotserror}
        </Message>
      )}
      {unBlockSloterror && (
        <Message variant="danger">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={toggleShowInfoPopup4}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {unBlockSloterror}
        </Message>
      )}
      {(getTimeSlotsloading || unBlockSlotloading) && <Loader />}

      <div className="pageSize">
        <Meta />
        {getTimeSlotserror && (
          <Message variant="danger">
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={toggleShowInfoPopup6}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            {getTimeSlotserror}
          </Message>
        )}
        {unBlockSloterror && (
          <Message variant="danger">
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
              onClick={toggleShowInfoPopup4}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            {unBlockSloterror}
          </Message>
        )}
        {(getTimeSlotsloading || unBlockSlotloading) && <Loader />}

        <h1 className="header-center">Unblock Slots</h1>
        <Container className="header-center mt-5">
          <Row>
            <Col md="auto" className="text-center">
              <label className="form-label" htmlFor="name">
                <b>Select Date: </b>
                <span className="text-danger">
                  <b>*</b>
                </span>
              </label>
            </Col>
            <Col md="auto" className="text-center">
              <DropdownButton
                key="down-centered"
                id={`dropdown-button-drop-down-centered`}
                drop="down-centered"
                variant="success"
                title={unBlockdateOption}
                onSelect={handleunBlockDateSelect}
              >
                {" "}
                <div className="dropScroll">
                  {days?.map((day, index) => (
                    <Dropdown.Item eventKey={index + "@" + day.currDay}>
                      {day.currDay}
                    </Dropdown.Item>
                  ))}
                </div>
              </DropdownButton>
            </Col>
          </Row>
        </Container>
        <Container className="header-center mt-5">
          <Row>
            <Col md="auto" className="text-center">
              <label className="form-label" htmlFor="name">
                {unBlockdateSelected ? (
                  <div>
                    <b>Choose Slot: </b>
                    <span className="text-danger">
                      <b>*</b>
                    </span>
                  </div>
                ) : (
                  <b> </b>
                )}
              </label>
            </Col>
          </Row>
          <Row>
            <Col md="auto">
              {unBlockdateSelected &&
                timeSlots?.map((option, index) =>
                  option?.maxAppointmentsperhour !== 0 ? (
                    option?.isBlocked ? (
                      selectedUnBlockCheckboxes.includes(
                        option.timeSlot?.split("@@")[0]
                      ) ? (
                        <button
                          className="btn btn-danger col-md-3 m-1 col-10"
                          id={option.timeSlot}
                          onClick={() =>
                            handleUnblockCheckboxChange(
                              option.timeSlot + "@@" + index,
                              false
                            )
                          }
                        >
                          {option.timeSlot} Slot is blocked
                        </button>
                      ) : (
                        <button
                          className="btn btn-outline-danger col-md-3 m-1 col-10"
                          id={option.timeSlot}
                          onClick={() =>
                            handleUnblockCheckboxChange(
                              option.timeSlot + "@@" + index,
                              true
                            )
                          }
                        >
                          {option.timeSlot} Slot is blocked
                        </button>
                      )
                    ) : (
                      <Button
                        className="btn btn-success col-md-3 m-1 col-10"
                        disabled
                      >
                        {option.timeSlot} Slot is unblocked
                      </Button>
                    )
                  ) : (
                    <Button
                      className="btn btn-danger col-md-3 m-1 col-10"
                      disabled
                    >
                      {option.timeSlot} Break Time
                    </Button>
                  )
                )}
            </Col>
          </Row>
          {unBlockdateSelected && (
            <Row className="justify-content-center mt-3">
              <Col md="auto" className="text-center">
                <button className="btn btn-success" onClick={handleUnBlockSlot}>
                  Unblock
                </button>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </div>
  );
};

export default DoctorUnblockSlotsScreen;
