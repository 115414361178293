/** @format */

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
	return (
		<div>
			<div className='container'>
				{/* <div className='row'>
					<div className='col-12 col-sm-4 offset-md-4 align-self-center'>
						<div className='text-center'>
							<a
								className='btn btn-social-icon btn-google'
								href='http://google.com/+'>
								<i className='icon fab fa-google-plus-square fa-lg'></i>
							</a>
							<a
								className='btn btn-social-icon btn-facebook'
								href='http://www.facebook.com/profile.php?id='>
								<i className='icon fab fa-facebook-square fa-lg'></i>
							</a>
							<a
								className='btn btn-social-icon btn-linkedin'
								href='http://www.linkedin.com/in/'>
								<i className='icon fab fa-linkedin fa-lg'></i>
							</a>
							<a
								className='btn btn-social-icon btn-twitter'
								href='http://twitter.com/'>
								<i className='icon fab fa-twitter fa-lg'></i>
							</a>
							<a
								className='btn btn-social-icon btn-google'
								href='http://youtube.com/'>
								<i className='icon fab fa-youtube fa-lg'></i>
							</a>
							<a className='btn btn-social-icon' href='mailto:'>
								<i className='icon fas fa-envelope fa-lg'></i>
							</a>
						</div>
					</div>
				</div> */}
				<div className='row justify-content-center'>
					<div className='col-auto'>
						<p>© Copyright 2024 HextGen</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
