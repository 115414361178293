import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRevenueForYear } from "../../redux/doctorRedux/doctorApiCalls";

import "./featuredInfo.css";

const FeaturedInfo = ({ statistics }) => {
	console.log(statistics);

	return (
		<div className="featured">
			<div className="featuredItem">
				<span className="featuredTitle">Visited Patients Count</span>
				<div className="featuredMoneyContainer">
					<span className="featuredMoney">{statistics[0]}</span>
					<span className="featuredMoneyRate">
						{Math.abs(statistics[0] - statistics[1])}
						{statistics[0] - statistics[1] >= 0 ? (
							<i class="fas fa-arrow-up" style={{ color: "green" }}></i>
						) : (
							<i className="fa fa-arrow-down" style={{ color: "red" }}></i>
						)}
					</span>
				</div>
				<span className="featuredSub">Compared to Yesterday</span>
			</div>
		</div>
	);
};

export default FeaturedInfo;
