/** @format */

import React, { useRef, useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import Meta from "../../../Components/Meta";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { changeBookingPeriod } from "../../../redux/doctorRedux/doctorApiCalls";
import { allMessagesStart } from "../../../redux/allMessages";
import Message from "../../../Components/Message";
import { editDoctorReset } from "../../../redux/doctorRedux/MyProfile_Management/editDoctor";
import { useParams } from "react-router-dom";

const EditBookingPeriodScreen = () => {
	const { clinicName } = useParams();
	const dispatch = useDispatch();
	const { doctorInfo, error, loading } = useSelector(
		(state) => state.doctorLogin
	);

	const [bookingPeriod, setBookingPeriod] = useState(
		doctorInfo?.user?.bookingPeriod
	);
	useEffect(() => {
		dispatch(allMessagesStart());
	}, []);

	const [showPopup, setShowPopup] = React.useState(false);
	const toggleShowInfoPopup = (e) => {
		setShowPopup(!showPopup);
		e.preventDefault();
		console.log("error");
		dispatch(editDoctorReset());
	};

	const submitHandler = () => {
		if (bookingPeriod > 90) {
			alert("Booking Period cannot exceed 90 days");
		} else {
			console.log(
				bookingPeriod,
				doctorInfo?.user?.bookingPeriod,
				doctorInfo?.user?.days,
				doctorInfo?.user?.appointmentDates.length,
				doctorInfo?.user?._id,
				doctorInfo?.user?.appointmentDates[
					doctorInfo?.user?.appointmentDates.length - 1
				].date
			);
			dispatch(
				changeBookingPeriod(
					bookingPeriod,
					doctorInfo?.user?.bookingPeriod,
					doctorInfo?.user?.days,
					doctorInfo?.user?.appointmentDates.length,
					doctorInfo?.user?._id,
					doctorInfo?.user?.appointmentDates[
						doctorInfo?.user?.appointmentDates.length - 1
					].date,
					clinicName
				)
			);
		}
	};

	return (
		<div className="pageSize">
			<Meta />
			{error && (
				<Message variant="danger">
					<button
						type="button"
						className="close"
						data-dismiss="alert"
						aria-label="Close"
						onClick={toggleShowInfoPopup}
					>
						<span aria-hidden="true">&times;</span>
					</button>
					{error}
				</Message>
			)}
			{loading && <Loader />}

			<h1 className="header-center">Edit Booking Period</h1>
			<Container className="header-center mt-5">
				<Row className="justify-content-center">
					<Col md="auto" className="text-center">
						<label htmlFor="name">
							<b>Booking Period </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
					</Col>
					<Col md="auto" className="text-center">
						<input
							className="form-control"
							id="speciality"
							type="number"
							required="required"
							value={bookingPeriod}
							onChange={(e) => setBookingPeriod(e.target.value)}
						/>
					</Col>
					<Col md="auto" className="text-center">
						<label htmlFor="name">
							<b>days </b>
						</label>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col md="auto" className="text-center">
						<label className="text-danger" htmlFor="name">
							<b>Booking Period cannot exceed 90 days. </b>
							<span className="text-danger">
								<b>*</b>
							</span>
						</label>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col md="auto" className="text-center">
						<button className="btn btn-success" onClick={submitHandler}>
							Submit
						</button>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default EditBookingPeriodScreen;
